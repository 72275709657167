import React from "react";
import { Div, Image } from "../StyledComponents";
import { VitalsDiv1 } from "./Styles";
import Programicon from "../../Assets/images/svg/image/Programicon.png";
import { useSelector } from "react-redux";

const Program = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const SingPatDetails = useSelector((state) => state.Dashboard.SinglePatient);

  // ----------------------------State and Variables ---------------------------------------------------///
  return (
    <Div
      border=" 1px solid rgba(46, 46, 46, 0.25)"
      background=" #FFFFFF"
      width="100%"
      height="148px"
      borderRadius="8px"
      position="relative"
      marginTop="24px"
      fontFamily="'Open Sans', sans-serif"
      fontWeight="600"
      fontSize="18px"
      color=" #2E2E2E"
      lineHeight="normal"
      fontStyle="normal"
      padding="10px 20px 21px 16px"
    >
      <Div>Program</Div>
      <Image
        height="48px"
        width="48px"
        margin="8px 10px 3px 0px"
        src={Programicon}
        alt="healthicons image"
      ></Image>
      <VitalsDiv1 fontWeight="400" color="#2C7BE5" className="rpmprogram">
        {SingPatDetails.Program}
      </VitalsDiv1>
    </Div>
  );
};

export default Program;
