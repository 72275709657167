import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const pid =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

export const postInsuranceApi = async (Credential) =>
  await axiosInst.post(`/rpmportal/insertpatientInusrance`, Credential);

export const getInsuranceApi = async (Credential) =>
  await axiosInst.post(`/rpmportal/getpatientInusrance`, Credential);
