import styled, { keyframes } from "styled-components";
import { BiRefresh } from "react-icons/bi";
import { ReactComponent as Heart } from "../../Assets/images/svg/Vector.svg";
import { ReactComponent as Blood } from "../../Assets/images/svg/Blood.svg";
import { ReactComponent as Respiratory } from "../../Assets/images/svg/respiratory.svg";
import { ReactComponent as Gluco } from "../../Assets/images/svg/Glucometer.svg";
import { ReactComponent as Oxygen } from "../../Assets/images/svg/oxygen.svg";
import { ReactComponent as Weight } from "../../Assets/images/svg/weight.svg";
import { Div, Text } from "../StyledComponents";

export const SpanTag = styled.span`
  gap: ${(p) => p.gap && p.gap};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  position: ${(p) => p.position && p.position};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  background-position: ${(p) => p.backgroundPosition && p.backgroundPosition};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  margin: ${(p) => p.margin && p.margin};
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const Refresh = styled(BiRefresh)`
  color: rgba(44, 123, 229, 1);
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: -3px;
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const HeartRate = styled(Heart)`
  height: 22px;
  width: 22px;
`;
export const BloodPressure = styled(Blood)`
  height: 24px;
  width: 24px;
`;
export const RespiratoryRate = styled(Respiratory)`
  height: 24px;
  width: 24px;
  margin-bottom: 18px;
`;
export const Glucometer = styled(Gluco)`
  height: 24px;
  width: 24px;
`;
export const Oxygenmeter = styled(Oxygen)`
  height: 26px;
  width: 26px;
`;
export const Weightmeter = styled(Weight)`
  height: 22px;
  width: 22px;
`;

export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: 18px;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 82px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 80px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 80px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 84px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(117px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(123px);
                          width: 76px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(123px);
                          width: 76px;
                        }
                         }
               `;
    }
    if (props.line === 3) {
      return `
              &::after {
                        content: "";
                        width: 98px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(236px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(236px);
                          width: 95px;
                        }
                         }
               `;
    }
    if (props.line === 4) {
      return `
              &::after {
                        content: "";
                        width: 96px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 16px;
                        transform : translateX(370px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(370px);
                          width: 95px;
                        }
                        
                        @media (max-width: 750px) {
                          transform : translateX(371px);
                          width: 95px;
                        }
                         }
               `;
    }
  }}
`;

export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: relative;
  margin-right: 10px;
  margin-top: 3px;
`;

export const DivTab = styled(Div)`
  width: calc(100vw - 265px);

  &.Vitals_Resp_alt {
    @media (min-width: 0px) and (max-width: 650px) {
      position: absolute;
    }
    @media (max-width: 820px) {
      width: 100%;
    }
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Spinner = styled.div`
  position: relative;
  border: 4px solid #f3f3f3;
  border-left: 6px solid #3498db;
  border-bottom: 6px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spinAnimation} 1s linear infinite;
  font-weight: bold;
`;
export const SpinnerLetter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: blue;
  pointer-events: none;
`;

export const SelectInput = styled.div`
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.pl && p.pl};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: relative;
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
  &:empty:before {
    content: ${(p) => p.placeholder && p.placeholder};
    color: rgba(46, 46, 46, 0.5);
    font-weight: 400;
    font-size: 14px;
  }
`;

export const DropDownDiv = styled.div`
  margin: ${(p) => p.margin && p.margin};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  display: grid;
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: absolute;
  z-index: 1000;
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  left: ${(p) => p.left && p.left};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const DropdownOptions = styled.label`
  display: ${(p) => p.display && p.display};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &:hover {
    background-color: #2c7be5 !important;
    color: #ffffff !important;
  }
  cursor: pointer;
`;

export const VitalText = styled(Text)`
  @media (max-width: 820px) {
    font-size: 16px !important;
  }
`;

export const VitalTextHead = styled(Text)`
  @media (max-width: 820px) {
    font-size: 12px !important;
  }
`;
export const DivVital = styled(Div)`
  @media (max-width: 820px) {
    width: 16.5%;
  }
`;
