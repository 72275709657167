import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetSignUp } from "../../../StateManagement/Reducers/PreLoginState";
import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import {
  LinK,
  Text,
  Button,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Div,
} from "../../StyledComponents";
import { Input } from "../Styles";
import { Title, SubTitle, FormGroupInline } from "./Styles";
import {
  Encrypt_Value,
  Decrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";

const SignUpRight = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [fnErr, setfnErr] = useState(false);
  const [lnErr, setLnErr] = useState(false);
  const [p1Err, setP1Err] = useState(false);
  const [p2Err, setP2Err] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgP2, setErrMsgP2] = useState("");

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [email] = useState(state ? state.type.email : "");
  const [Password, setPassword] = useState("");
  const [Password1, setPassword1] = useState("");

  const backurl =
    localStorage.getItem("File_URL") &&
    Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  const File_URL = backurl + "/documenting/";

  const Signup = useSelector((state) => state.PreLogin.signup);
  const text = useSelector((state) => state.PreLogin.logo);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const EmailValidation = (e) => {
    setPassword(e.target.value);
    setP2Err(false);
    setErrMsg("");
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setP1Err(false);
    } else {
      setP1Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const ConformEmail = (e) => {
    setPassword1(e.target.value);
    setErrMsgP2("");
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setP2Err(false);
      setErrMsg("");
    } else {
      setP2Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const handleMode = (a) => {
    if (a === "2") {
      dispatch(setMode("old"));
      let mode = "old";
      localStorage.setItem("Mode", Encrypt_Value(mode, "rpmportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    // if (p1Err || p2Err) return;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    var err = 0;
    if (!strongRegex.test(Password)) {
      setP2Err(true);
      err = 2;
    }
    if (!strongRegex.test(Password1)) {
      setP2Err(true);
      err = 2;
    }
    if (firstName === "") {
      setfnErr(true);
      err = 2;
    }
    if (lastName === "") {
      setLnErr(true);
      err = 2;
    }
    if (email === "") {
      return false;
    }
    if (Password === "") {
      setErrMsg("Password Cannot be Empty");
      setP1Err(true);
      return false;
    }
    if (Password1 === "") {
      setErrMsgP2("Confirm Password Cannot be Empty");
      setP2Err(true);
      return false;
    }
    if (Password !== Password1) {
      setErrMsgP2("PassWord Mismatch");
      setP2Err(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    } else {
      dispatch(
        GetSignUp({
          fname: firstName,
          lname: lastName,
          email: email,
          new_password: Password,
          con_password: Password1,
        })
      );
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (Signup === "Signup_success") {
      setErr(false);
      Navigate("/Login");
    } else {
      if (Signup.length !== 0) {
        setErr(true);
        setErrMsg("signup-failed");
      }
    }
  }, [Signup]);
  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <Div>
      <Div width="500px" margin="auto" resWidth="420px">
        {text.length !== 0 && (
          <Div marginTop="72px" marginBottom="65px" textAlign="center">
            <Image
              loading="lazy"
              src={File_URL + text}
              height="41"
              width="173px"
              objectFit="contain"
            />
          </Div>
        )}

        <Title marginTop="10%">Create new account</Title>
        <SubTitle>Welcome back! Nice to see you again</SubTitle>

        <Form>
          <FormGroupInline>
            <FormGroup marginBottom="24px" display="grid">
              <FormLabel
                marginBottom="8px"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                First Name
              </FormLabel>
              <Input
                placeholder="Enter Name"
                value={firstName}
                type="text"
                color="#7D8592"
                onChange={(e) => {
                  if (isNaN(e.target.value) || e.target.value === "") {
                    setfnErr(false);
                    setFirstname(e.target.value);
                  }
                }}
              />
              {fnErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  First Name cannot be Empty
                </FormLabel>
              )}
              {lnErr && <>&nbsp;</>}
            </FormGroup>
            <FormGroup marginBottom="24px" display="grid">
              <FormLabel
                marginBottom="8px"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                Last Name
              </FormLabel>
              <Input
                placeholder="Last Name"
                name="lastname"
                value={lastName}
                color="#7D8592"
                type="text"
                autoComplete="off"
                onChange={(e) => {
                  if (isNaN(e.target.value) || e.target.value === "") {
                    setLastname(e.target.value);
                    setLnErr(false);
                  }
                }}
              />
              {lnErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  Last Name cannot be Empty
                </FormLabel>
              )}
              {fnErr && <>&nbsp;</>}
            </FormGroup>
          </FormGroupInline>

          <FormGroup
            marginBottom="24px"
            display="grid"
            width="100%"
            position="relative"
          >
            <FormLabel
              marginBottom="8px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Email address
            </FormLabel>
            <Input
              placeholder="Enter email"
              disabled
              value={email}
              fontSize="20px"
              fontWeight="500"
              lineHeight="30px"
              color="#667085"
              textAlign="left"
              padding="0px 10px 0px 40px"
            />
            <FaRegUser
              style={{
                position: "absolute",
                left: 15,
                top: "45px",
                cursor: "pointer",
                color: "#7d8592",
                fontSize: 20,
              }}
            />
          </FormGroup>
          <FormGroup
            marginBottom="32px"
            display="grid"
            width="100%"
            position="relative"
          >
            <FormLabel
              marginBottom="8px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Password
            </FormLabel>
            <Input
              placeholder="Enter password"
              type={PasswordShow ? "password" : "text"}
              value={Password}
              color="#7D8592"
              autoComplete="off"
              onChange={(e) => EmailValidation(e)}
            />
            {PasswordShow ? (
              <FaRegEye
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            )}
            {p1Err && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsg}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup
            marginBottom="32px"
            display="grid"
            width="100%"
            position="relative"
          >
            <FormLabel
              marginBottom="8px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Confirm Password
            </FormLabel>
            <Input
              placeholder="Re-Enter password"
              type={PasswordShow1 ? "password" : "text"}
              value={Password1}
              color="#7D8592"
              onChange={(e) => ConformEmail(e)}
            />
            {PasswordShow1 ? (
              <FaRegEye
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
              />
            )}
            {p2Err && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsgP2}
              </FormLabel>
            )}
          </FormGroup>

          <FormGroup marginBottom="32px" display="grid" width="100%">
            <Button
              background="#2C7BE5"
              padding="13px 140px 13px 140px"
              borderRadius="8px"
              border="none"
              color="#FFF"
              fontWeight="600"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="1.1px"
              onClick={(e) => handleCreate(e)}
              cursor="pointer"
              className="save_active"
              hoverBackColor="#005FB2"
            >
              Create Account
            </Button>
          </FormGroup>
          {err && (
            <FormLabel
              marginBottom="0px"
              textAlign="left"
              fontSize="14px"
              color="red"
              fontWeight="400"
              lineHeight="24px"
              marginTop="5px"
              fontFamily="inter"
            >
              {errMsg}
            </FormLabel>
          )}
          <Text
            marginTop="32px"
            color="#667085"
            fontSize="18px"
            lineHeight="26px"
            textAlign="center"
          >
            Do you have an account?
            <LinK
              to="/InstantSignIn"
              fontSize="18px"
              lineheight="26px"
              fontFamily="'manrope'"
              color="#1F28EB"
              onClick={() => handleMode("2")}
            >
              Log in
            </LinK>
          </Text>
        </Form>
      </Div>
    </Div>
  );
};

export default SignUpRight;
