import { createSlice } from "@reduxjs/toolkit";

export const InsuranceSlice = createSlice({
  name: "Insurance",
  initialState: {
    insuranceDetails: [],
    DeviceRes: "",
    Loading: true,
  },
  reducers: {
    postSingleInsDetail: (data) => {
      return data;
    },
    SetSingleInsDetail: (state, action) => {
      state.insuranceDetails = action.payload;
      state.Loading = false;
    },
    GetSingleInsDetail: (state) => {
      state.Loading = true;
    },
    DeviceResponse: (state, action) => {
      state.DeviceRes = action.payload;
    },

    userImageUpload: (data) => {
      return data;
    },
  },
});

export const {
  postSingleInsDetail,
  SetSingleInsDetail,
  GetSingleInsDetail,
  DeviceResponse,
  userImageUpload,
} = InsuranceSlice.actions;

export default InsuranceSlice.reducer;
