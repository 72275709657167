import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";

import { getCredentials } from "../../../StateManagement/Reducers/LoginState";

import { setMode } from "../../../StateManagement/Reducers/PreLoginState";

import {
  Button,
  LinK,
  FormLabel,
  Form,
  FormGroup,
  Image,
  Div,
  Text,
} from "../../StyledComponents";
import {
  Label,
  // Div,
  Input,
} from "./styles";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axiosInst from "../../../Config";

const LoginRight = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [PasswordShow, setPasswordShow] = useState(true);
  const [UserName, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [misMatch, setMissMatch] = useState(false);
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgPass, setErrMsgPass] = useState("");
  const [disabledsave, setdisabledsave] = useState(false);
  const backurl =
    localStorage.getItem("File_URL") &&
    Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  const File_URL = backurl + "/documenting/";

  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const text = useSelector((state) => state.PreLogin.logo);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const UserNameValidation = (e) => {
    setUsername(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };

  const PasswordValidation = (e) => {
    setPassword(e.target.value);
    setMissMatch(false);
  };
  const handleMode = (a) => {
    if (a === "1") {
      dispatch(setMode("new"));
      let mode = "new";
      localStorage.setItem("Mode", Encrypt_Value(mode, "rpmportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  const Onsubmit = (e) => {
    setdisabledsave(true);
    e.preventDefault();
    if (UserName !== "" && Password !== "") {
      dispatch(getCredentials({ UserName: UserName, Password: Password }));
    } else {
      setdisabledsave(false);
      if (UserName === "") {
        setErrMsg("Please enter the email address");
        setMailErr(true);
      }
      if (Password === "") {
        console.log("password");
        setErrMsgPass("Please enter the password");
        setMissMatch(true);
      }
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (LoginResponse.status === "Username is Incorrect") {
      setMailErr(true);
      setErrMsg("Enter the correct email address");
      setdisabledsave(false);
    } else if (LoginResponse.status === "Password is Incorrect") {
      setMissMatch(true);
      setErrMsgPass("Enter the correct password");
      setdisabledsave(false);
    } else if (LoginResponse.status === "patient unenrolled") {
      setMissMatch(true);
      setErrMsgPass("Access Restricted Contact Service Provider");
      setdisabledsave(false);
    }
    if (LoginResponse.status === "LOGIN_SUCCESS") {
      console.log(LoginResponse);
      if (LoginResponse.AllowPortal === "YES") {
        localStorage.setItem(
          "PID",
          Encrypt_Value(LoginResponse.Pid, "rpmportal")
        );
        let token_enc = LoginResponse.access_token;
        localStorage.setItem("accesstoken", token_enc);
        axiosInst.defaults.headers.common[
          "Authorization"
        ] = `${LoginResponse.access_token}`;
        localStorage.setItem(
          "refreshtoken",
          Encrypt_Value(LoginResponse.refresh_token, "rpmportal")
        );

        localStorage.setItem(
          "token_exp",
          Encrypt_Value(LoginResponse.token_exp, "rpmportal")
        );
        localStorage.setItem(
          "idle",
          Encrypt_Value(LoginResponse.idle, "rpmportal")
        );
        setTimeout(() => {
          Navigate("/dashboard");
        }, 1000);
        setdisabledsave(false);
        window.location.reload();
      } else {
        Navigate("/AccessDenied");
        setdisabledsave(false);
      }
    }
  }, [LoginResponse]);
  useEffect(() => {
    localStorage.removeItem("Mode");
  }, []);
  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <Div width="100%">
      {text.length !== 0 && (
        <Div textAlign="center" marginTop="72px" marginBottom="65px">
          <Image
            loading="lazy"
            src={File_URL + text}
            height="41px"
            width="173px"
            objectFit="contain"
            transition=" translate1s"
          />
        </Div>
      )}
      <Div width="400px" marginLeft="auto" marginRight="auto" marginTop="10%">
        <Div textAlign="left" display="grid">
          <Label
            size="36px"
            weight={700}
            textAlign="left"
            marginBottom="14px"
            lineHeight="44px"
          >
            Login
          </Label>
          <Text
            marginBottom="33px"
            color="#667085"
            fontSize="20px"
            lineHeight="27px"
          >
            Leading the way in managing your health.
          </Text>
        </Div>
        <Form onSubmit={Onsubmit}>
          <FormGroup marginBottom="42px" display="grid">
            <FormLabel
              marginBottom="6px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Email address
            </FormLabel>
            <Input
              placeholder="Enter email"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
              onChange={(e) => UserNameValidation(e)}
              id="log_mail-inp_adr"
              value={UserName}
            />
            {mailErr && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
                marginLeft="3px"
              >
                {errMsg}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup marginBottom="20px" display="grid" position="relative">
            <FormLabel
              marginBottom="6px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Password
            </FormLabel>
            <Input
              type={PasswordShow ? "password" : "text"}
              placeholder="Enter password"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
              value={Password}
              onChange={(e) => PasswordValidation(e)}
              id="log_psw-inp_adr"
            />
            {PasswordShow ? (
              <FaRegEye
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            )}
            {misMatch && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
                marginLeft="3px"
              >
                {errMsgPass}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup
            textAlign="right"
            justifyContent="flex-end"
            marginBottom="32px"
            display="flex"
          >
            <LinK
              to="/ForgetPassword"
              fontSize="14px"
              lineheight="18px"
              fontFamily="'Urbanist'"
              color="rgba(31, 40, 235, 1)"
            >
              Forgot Password?
            </LinK>
          </FormGroup>
          <FormGroup marginBottom="42px" display="grid">
            <Button
              background="#2C7BE5"
              padding="13px 140px 13px 140px"
              borderRadius="8px"
              border="none"
              color="#FFF"
              fontWeight="600"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="1.1px"
              id="log_inp-btn_sbt"
              cursor="pointer"
              className="save_active"
              hoverBackColor="#005FB2"
              disabled={disabledsave}
            >
              Log in
            </Button>
            <Text
              marginTop="32px"
              color="#667085"
              fontSize="18px"
              lineHeight="26px"
              textAlign="center"
              marginBottom="20px"
            >
              Don't have an account?
              <LinK
                to="/InstantSignIn"
                fontSize="18px"
                lineheight="26px"
                fontFamily="'manrope'"
                color="rgba(31, 40, 235, 1)"
                onClick={() => handleMode("1")}
              >
                &nbsp; Sign up
              </LinK>
            </Text>
          </FormGroup>
        </Form>
      </Div>
    </Div>
  );
};

export default LoginRight;
