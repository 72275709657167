import { call, put } from "redux-saga/effects";
import { getInsuranceApi, postInsuranceApi } from "../Apis/InsuranceApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  DeviceResponse,
  SetSingleInsDetail,
} from "../../StateManagement/Reducers/InsuranceState";
import { Image_Api } from "../Apis/SettingsApi";
import { GetSinglePatientDetail_Api } from "../Apis/DashBoardApi";
import { SetSinglePatDetail } from "../../StateManagement/Reducers/DashboardState";

const pid =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

export function* postInsurancedata({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "rpmportal");
  try {
    const res = yield call(postInsuranceApi, ecrypted);
    if (res.status === 200) {
      const res1 = yield call(getInsuranceApi, ecrypted);
      yield put(DeviceResponse("Details added sucessfully"));
      if (res1.status === 200) {
        let Decrypted = Decrypt_Value(res1.data, "rpmportal");
        yield put(SetSingleInsDetail(Decrypted));
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(DeviceResponse("Unable to add Details"));
  }
}

export function* GetInsurancedata({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "rpmportal");
  try {
    const res = yield call(getInsuranceApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "rpmportal");
      yield put(SetSingleInsDetail(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetSingleInsDetail([]));
  }
}

export function* UserImageUploadApi({ payload }) {
  try {
    const res = yield call(Image_Api, [pid, payload]);
    if (res.status === 200) {
      try {
        const res2 = yield call(GetSinglePatientDetail_Api);
        if (res.status === 200) {
          const Data = Decrypt_Value(res2.data, "vozorpm");
          yield put(SetSinglePatDetail(Data));
        }
      } catch (e) {
        console.log(e.message);
        yield put(SetSinglePatDetail([]));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
