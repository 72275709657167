import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const pid =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

export const GetSinglePatientDetail_Api = async () =>
  await axiosInst.get(`/vozorpm/getsinglepatient/details?pid=` + pid);

export const GetVitals_Api = async () =>
  await axiosInst.get("/vozorpm/patientdemographics/vitals?pid=" + pid);
export const DeviceListApi = async () =>
  await axiosInst.get("/vozorpm/Devicelist?pid=" + pid);

export const CardTeamdetails_Api = async () =>
  await axiosInst.get("/vozorpm/Dashboard/Mycardteamdetails?pid=" + pid);
