import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getsavesignature } from "../../../StateManagement/Reducers/DocumentsState";

import {
  ModalTitle,
  Div,
  Text,
  Button,
  Input,
  ModalContainer,
  Modal,
} from "../../StyledComponents";

import { ModalDialog } from "../Styles";

import { BsXLg } from "react-icons/bs";

const CreateSignature = ({ show, close, success, ids }) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [ModalAnimat] = useState(true);
  const [disabledsave, setdisabledsave] = useState(false);

  const SignInsert = useSelector((state) => state.Document.SignInsert);
  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const CloseSignature = () => {
    setName("");
    close();
  };

  const SaveSignature = () => {
    if (name === "") {
      alert("Add Your Signature");
    } else {
      dispatch(getsavesignature({ ids: ids, sign: name }));
      setdisabledsave(true);
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //
  useEffect(() => {
    if (SignInsert === "success") {
      CloseSignature();
    }
  }, [SignInsert]);
  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal show={show}>
        <ModalContainer
          justifyContent="center"
          backgroundColor="rgba(102,112,133,0.4)"
          display="flex"
          top="0"
          left="0"
          width="100%"
          height="100%"
          outline="0"
        >
          <ModalDialog
            padding="24px"
            animation={ModalAnimat}
            width="398px"
            background="#ffffff"
            position="relative"
            boxShadow="0px 6px 58px rgba(121, 145, 173, 0.195504)"
            borderRadius="12px"
          >
            <Div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0px 0px 8px 0px"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="20px"
                fontWeight="600"
                color="#0a1629"
              >
                Create Your Signature
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#407BFF",
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={close}
              />
            </Div>
            <Div>
              <Text
                fontWeight="400"
                fontSize="14px"
                color="#7d8592"
                marginBottom="16px"
                marginTop="0px"
                textAlign="left"
              >
                Updatient your Information here will be applied to all future
                Signatures.
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#7d8592"
                marginBottom="4px"
                marginTop="0px"
                textAlign="left"
              >
                Your Name
              </Text>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                borderRadius="6px"
                margin="0 0 16px 0"
                border="1px solid #d8e0f0"
                padding="10px"
                outline="none"
                width="350px"
                height="48px"
              />
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#7d8592"
                mb="0px"
                mt="0px"
                textAlign="left"
              >
                Preview
              </Text>
              <Div
                flexCenterHorizontal
                flexDirection="row"
                marginTop="4px"
                borderRadius="4px"
                background="rgba(64, 123, 255, 0.1)"
                height="87px"
                padding="20px"
              >
                <Input
                  as="textarea"
                  bordeRadius="4px"
                  background="rgba(64, 123, 255, 0)"
                  border="none"
                  outline="none"
                  resize="none"
                  textAlign="center"
                  width="100%"
                  fontFamily='"Shadows Into Light", cursive'
                  fontSize="1.8em"
                  value={name}
                  disabled={true}
                />
              </Div>
              <Div
                display="flex"
                flexDirection="row"
                justifyContent="end"
                margin="16px 0px 0px 0px"
              >
                <Button
                  padding="8px 25px"
                  onClick={CloseSignature}
                  id="sig_cnl-btn"
                  color="#2C7BE5"
                  border="1px solid #2C7BE5"
                  backgroundColor="#fff"
                  borderRadius="4px"
                  cursor="pointer"
                  margin="0 16px 0 0"
                  fontWeight="600"
                  className="close_active"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                >
                  Cancel
                </Button>
                <Button
                  padding="8px 25px"
                  backgroundColor="#2C7BE5"
                  color="#fff"
                  border="none"
                  borderRadius="4px"
                  cursor="pointer"
                  fontWeight="600"
                  disabled={disabledsave}
                  onClick={SaveSignature}
                  hoverBackColor="#005FB2"
                  className="save_active"
                >
                  Save
                </Button>
              </Div>
            </Div>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CreateSignature;
