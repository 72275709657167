import axiosInst from "../../Config";

export const LoginEmailCheck_Api = async (data) =>
  await axiosInst.post(`/rpmportal/sendverificationemail`, data);

export const LoginEmailCheckNew_Api = async (data) =>
  await axiosInst.post(`/rpmportal/sendverificationemailfornewpatient`, data);

export const PasswordResetEmailCheck_Api = async (data) =>
  await axiosInst.post(`/rpmportal/sendverificationemailforreset`, data);

export const LoginPasswordSet_Api = async (data) =>
  await axiosInst.post(`/rpmportal/setpatientpassword`, data);

export const SignUp_Api = async (data) =>
  await axiosInst.post(`/rpmportal/signup`, data);

export const Extension_Api = async () =>
  await axiosInst.get("/rpmportal/getlogoext");
