import styled, { keyframes } from "styled-components";

const slide = keyframes`
0% {
  right: -25%;
  transition: opacity 3s linear, right 0.7s ease-out;
}
100% {
  right: 0%;
}
`;

const slideIpad = keyframes`
  0% {
    right: -12%;
  }
`;

export const CarouselContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  right: 0;
  justify-content: center;
  place-items: center;
  animation: ${slide} 2s ease-out;

  @media (max-width: 820px) {
    animation: ${slideIpad} 2s ease-out;
  }
`;
