import styled from "styled-components";

export const ModalDialog = styled.div`
  overflow: hidden;
  padding: ${(p) => (p.padding ? p.padding : "32px")};
  position: relative;
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  width: 414px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;

export const DragDrop = styled.div`
  background: #fafafa;
  border: ${(p) => (p.border ? p.border : "1px dashed #e5e5e5")};
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
  padding: ${(p) => p.padding && p.padding};
  position: ${(p) => p.position && p.position};
`;

export const FileInput = styled.input`
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  width: ${(p) => p.width && p.width};
`;
