import {
  getDocumentslist,
  getInsertDocumentslist,
  setInsertEmptyResp,
  getUpdateDocumentslist,
  setUpdateDocEmptyResp,
  getPreviewDocument,
  getDeleteTempDoc,
  getPreviewDownload,
  getsavesignature,
  getDeleteDocumentslist,
} from "../../StateManagement/Reducers/DocumentsState";
import { takeLatest } from "redux-saga/effects";
import {
  DocumentListApi,
  InsertDocumentsApi,
  setInsertEmptyWorker,
  UpdateDocumentApi,
  setUpdateDocEmptyWorker,
  PreViewDocumentApi,
  DeleteTempDocumentApi,
  DownloadDocumentApi,
  SignDocumentApi,
  DeleteDocumentApi,
} from "./DocumentsSagaWorkers";

export function* DocumentLists() {
  yield takeLatest(getDocumentslist.type, DocumentListApi);
}
export function* InsertDocuments() {
  yield takeLatest(getInsertDocumentslist.type, InsertDocumentsApi);
}

export function* EmptyInsertRespSaga() {
  yield takeLatest(setInsertEmptyResp.type, setInsertEmptyWorker);
}

export function* UpdateDocument() {
  yield takeLatest(getUpdateDocumentslist.type, UpdateDocumentApi);
}

export function* EmptyUpdateRespSaga() {
  yield takeLatest(setUpdateDocEmptyResp.type, setUpdateDocEmptyWorker);
}

export function* DownloadDocument() {
  yield takeLatest(getPreviewDownload.type, DownloadDocumentApi);
}

export function* PreViewDocument() {
  yield takeLatest(getPreviewDocument.type, PreViewDocumentApi);
}

export function* DeleteTempDocument() {
  yield takeLatest(getDeleteTempDoc.type, DeleteTempDocumentApi);
}

export function* SignDocument() {
  yield takeLatest(getsavesignature.type, SignDocumentApi);
}

export function* DeleteDocument() {
  yield takeLatest(getDeleteDocumentslist.type, DeleteDocumentApi);
}
