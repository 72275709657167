import { Encrypt_Value } from "../MiddleWare/EncryptDecrypt";

export const GetFileUrl = () => {
  let backurl = "";
  let firstDot = window.location.hostname.split(".");

  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "mumbairpm",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  let DBhost;
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  let url = backurl + "/sites/" + DBhost;

  localStorage.setItem("File_URL", Encrypt_Value(url, "rpmportal"));
  return url;
};
