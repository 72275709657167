import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MainContainerLeft = styled.div`
  width: 250px;
  height: 100%;
  background: #fafafa;
  position: fixed;
  top: 0;
  z-index: 1001;
  @media screen and (min-width: 800px) and (max-width: 820px) {
    width: 200px;
  }
`;

export const MainContainerRight = styled.div`
  margin-left: 250px;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 800px) and (max-width: 820px) {
    margin-left: 200px;
  }
`;
