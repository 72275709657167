import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getInsertDocumentslist } from "../../../StateManagement/Reducers/DocumentsState";

import {
  ModalTitle,
  Div,
  Text,
  Image,
  Button,
  ModalContainer,
  Modal,
  Input,
} from "../../StyledComponents";

import { ModalDialog } from "../Styles";

import { BsXLg } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import UploadFile from "../../../Assets/images/uploadDoc.png";
import pdfIcon from "../../../Assets//images/EllipsePdf.png";

const UploadDocument = ({ show, close }) => {
  // ------------------------------ State And Variables Start------------------------ //

  const dispatch = useDispatch();

  const [files, setFiles] = useState(null);
  const hiddenInput = useRef(null);
  const [fileIs, setFileIs] = useState(false);
  const [ModalAnimat] = useState(true);
  const [uploadIs, setUploadIs] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);
  const [fileErr, setFileErr] = useState(false);
  const InsertMsg = useSelector((state) => state.Document.DocumentInsert);

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const HandleClick = () => {
    hiddenInput.current.click();
  };

  const HandleChange = (event) => {
    const fileUploaded = event.target.files;
    setFiles([]);
    setFileIs(true);
    for (let i = 0; i < fileUploaded.length; i++) {
      if (fileUploaded[i].name.length <= 150) {
        if (fileUploaded[i].size < 10e6) {
          if (
            fileUploaded[i].type === "image/jpeg" ||
            fileUploaded[i].type === "image/png" ||
            fileUploaded[i].type === "application/pdf"
          ) {
            let temp = {
              FileName: fileUploaded[i]["name"],
              File: fileUploaded[i],
            };
            setFiles((item) => [...item, temp]);
            setFileErr(false);
          } else {
            setFileErr(true);
          }
        } else {
          alert("select the file less than 10 MB");
          event.target.value = "";
          return false;
        }
      } else {
        alert(
          "File name is too long, upload file name with less than 150 characters"
        );
        event.target.value = "";
        return false;
      }
    }
  };
  const ClearFile = (value) => {
    setFiles((prevState) => prevState.filter((prevItem) => prevItem !== value));
  };

  const HandleClose = () => {
    close();
    setFiles(null);
  };

  const UploadDocument = () => {
    if (files && !fileErr) {
      setbtnDisable(true);
      const documentsData = new FormData();
      for (var x = 0; x < files.length; x++) {
        documentsData.append("fileupload[]", files[x].File);
      }
      dispatch(getInsertDocumentslist(documentsData));
      setUploadIs(true);
      // setdocumenthead("All Documents");
    }
  };

  const handleContainerClick = (e) => {
    // e.stopPropagation();
  };

  // ------------------------------Functions End---------------------------- //

  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    if (InsertMsg === "file added") {
      HandleClose();
      setbtnDisable(false);
    }
  }, [InsertMsg]);

  // ------------------------------UseEffect End-------------------------- //

  return (
    <Modal show={show} width="100%" height="100%">
      <ModalContainer
        justifyContent="center"
        backgroundColor="rgba(102,112,133,0.4)"
        display="flex"
        top="0"
        left="0"
        width="100%"
        height="100%"
        outline="0"
        id="modal-outer"
        onClick={(e) => {
          if (e.target.id.includes("modal-outer")) {
            HandleClose();
          }
        }}
      >
        <ModalDialog
          animation={ModalAnimat}
          onClick={handleContainerClick}
          padding="32px"
          width="414px"
          background="#ffffff"
          position="relative"
          boxShadow="0px 6px 58px rgba(121, 145, 173, 0.195504)"
          borderRadius="12px"
        >
          <Div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ModalTitle
              lineHight="27px"
              fontSize="20px"
              fontWeight="600"
              color="#0a1629"
            >
              Upload Documents
            </ModalTitle>

            <BsXLg
              style={{
                color: "#407BFF",
                cursor: "pointer",
                height: "14px",
                width: "14px",
              }}
              onClick={HandleClose}
            />
          </Div>
          <Div
            background="#fafafa"
            border={fileErr ? "2px dashed red" : "2px dashed #e5e5e5"}
            borderRadius="4px"
            marginTop="20px"
            textAlign="center"
          >
            <Input
              type="file"
              ref={hiddenInput}
              onChange={HandleChange}
              style={{
                paddingBottom: "49%",
                paddingLeft: "23%",
                position: "absolute",
                marginLeft: "-42%",
                opacity: 0,
                cursor: "pointer",
              }}
              multiple={true}
              accept=".png, .pdf, .jpg, .jpeg"
            />
            <Text
              fontSize="16px"
              lineHeight=" 22px"
              color="#263446"
              marginBottom="20px"
              marginTop="20px"
            >
              Drag &amp; Drop
            </Text>
            <Image
              loading="lazy"
              width="45px"
              margin="0px auto 25px auto"
              src={UploadFile}
            />
            <Text fontSize="12px" color="#7d8592" marginBottom="20px">
              JBG,PNG or PDF Smaller than 10mb
            </Text>
            <Button
              height="40px"
              background="#ffffff"
              border="1px solid #2C7BE5"
              borderRadius="6px"
              cursor="pointer"
              fontFamily="'Work Sans',sans-serif"
              fontWeight="500"
              fontSize="16px"
              lineHeight=" 16px"
              color="#2C7BE5"
              padding="12px 20px"
              marginLeft="auto"
              marginRight="auto"
              marginBottom="12px"
              onClick={HandleClick}
              className="close_active"
              hoverBackColor="rgba(244, 246, 249, 0.75) !important"
            >
              Choose File
            </Button>
          </Div>

          {files && (
            <>
              <Div
                display="flex"
                justifyContent="space-between"
                margin="20px 0px 8px 0px"
              >
                {fileErr && (
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="19px"
                    color="red"
                    mt="0px"
                    marginBottom="0px"
                    textAlign="start"
                  >
                    Please Select Valid File Format
                  </Text>
                )}
                {fileIs ? (
                  !fileErr && (
                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="19px"
                      color="#464646"
                      mt="0px"
                      marginBottom="0px"
                      textAlign="start"
                    >
                      {files.length} of {files.length} Uploaded
                    </Text>
                  )
                ) : (
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="19px"
                    mt="0px"
                    marginBottom="0px"
                    color="#464646"
                    textAlign="start"
                  >
                    Done
                  </Text>
                )}

                {fileIs && !fileErr && (
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="19px"
                    color="#464646"
                    cursor="pointer"
                    mt="0px"
                    marginBottom="0px"
                    textAlign="end"
                    onClick={() => setFiles(null)}
                  >
                    Cancel
                  </Text>
                )}
              </Div>
              {files.map((val, ind) => (
                <Div
                  key={ind}
                  flexCenterBetween
                  width="100%"
                  background="rgba(193, 214, 255, 0.3)"
                  boxshadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                  borderRadius="8px"
                  flexDirection="row"
                  marginBottom="10px"
                >
                  <Div
                    height="100%"
                    width="80%"
                    boxshadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                    border-radius=" 8px"
                    textalign="center"
                    display="flex"
                  >
                    <Image
                      loading="lazy"
                      width="32px"
                      height="32px"
                      margin="8px 12px"
                      src={pdfIcon}
                    />
                    <Div
                      flexCenterVertical
                      fontFamily='"Inter", sans-serif'
                      fontWeight="500"
                      fontSize="14px"
                      color={!files ? `#fafafa` : `#407BFF`}
                      textAlign="center"
                      file={files ? true : false}
                      padding="10px 0 10px 10px"
                      overflowWrap="anywhere"
                    >
                      {val.FileName}
                    </Div>
                  </Div>
                  <AiOutlineCloseCircle
                    style={{
                      marginTop: "5px",
                      cursor: "pointer",
                      marginRight: "10px",
                      height: "20px",
                      width: "20px",
                    }}
                    onClick={() => ClearFile(val)}
                  />
                </Div>
              ))}
              <Div flexCenterVertical={true} justifyContent="center">
                <Button
                  background="#2C7BE5"
                  borderRadius="8px"
                  border="#2C7BE5"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="16px"
                  color="#fff"
                  padding="12px 20px"
                  marginTop="20px"
                  cursor="pointer"
                  onClick={UploadDocument}
                  disabled={btnDisable}
                  hoverBackColor="#005FB2"
                  className="save_active"
                >
                  Upload File
                </Button>
              </Div>
            </>
          )}
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default UploadDocument;
