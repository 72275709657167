import React, { useState, useEffect } from "react";
import { PaginationAnch, PagePrevNext } from "./Styles.js";
import { Div } from "../../StyledComponents/";

const Pagination = ({ pages, setCurrentPage, view }) => {
  const [currentButton, setCurrentButton] = useState(1);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  const numberOfPages = [];
  for (let i = 1; i <= pages; i++) {
    numberOfPages.push(i);
  }

  useEffect(() => {
    if (view === 1) setCurrentButton(1);
  }, [view]);

  useEffect(() => {
    let tempNumberOfPages = [...arrOfCurrButtons];

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numberOfPages.length < 6) {
      tempNumberOfPages = numberOfPages;
    } else if (currentButton >= 1 && currentButton <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
    } else if (currentButton === 4) {
      const sliced = numberOfPages.slice(0, 5);
      tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    } else if (currentButton > 4 && currentButton < numberOfPages.length - 2) {
      const sliced1 = numberOfPages.slice(currentButton - 2, currentButton);
      const sliced2 = numberOfPages.slice(currentButton, currentButton + 1);
      tempNumberOfPages = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numberOfPages.length,
      ];
    } else if (currentButton > numberOfPages.length - 3) {
      const sliced = numberOfPages.slice(numberOfPages.length - 4);
      tempNumberOfPages = [1, dotsLeft, ...sliced];
    } else if (currentButton === dotsInitial) {
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentButton === dotsRight) {
      setCurrentButton(arrOfCurrButtons[3] + 2);
    } else if (currentButton === dotsLeft) {
      setCurrentButton(arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfPages);
    setCurrentPage(currentButton);
  }, [currentButton, view]);
  //-----------------------------UseEffect End------------------------------------------------------------------ //

  return (
    <Div
      flexCenterAll
      fontWeight="500"
      fontSize="15px"
      maxWidth="650px"
      position="absolute"
      right="24px"
      marginTop="18px"
    >
      <PagePrevNext
        active={`${currentButton === 1 ? "disabled" : "active"}`}
        onClick={() =>
          setCurrentButton((prev) => (prev <= 1 ? prev : prev - 1))
        }
      >
        Prev
      </PagePrevNext>

      {arrOfCurrButtons.map((item, index) => {
        return (
          <PaginationAnch
            key={index}
            active={`${currentButton === item ? "active" : "disabled"}`}
            onClick={() => setCurrentButton(item)}
          >
            {item}
          </PaginationAnch>
        );
      })}

      <PagePrevNext
        active={`${
          currentButton === numberOfPages.length ? "disabled" : "active"
        }`}
        onClick={() =>
          setCurrentButton((prev) =>
            prev >= numberOfPages.length ? prev : prev + 1
          )
        }
      >
        Next
      </PagePrevNext>
    </Div>
  );
};

export default Pagination;
