import React, { useEffect, useRef } from "react";
import { BodyContainer, Div, Text } from "../StyledComponents";
import { ResponsiveText } from "./Styles";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Chart from "chart.js/auto";

const HealthScore = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const healthScore = useSelector((state) => state.Dashboard.Health.Score);
  const vitalsCount = useSelector((state) => state.Dashboard.Health.vitalCount);

  const chartRef = useRef(null);
  const remaining = 100 - healthScore;
  const colors = ["#FF6384", "transparent"];
  // ----------------------------State and Variables ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//
  const healthReports = {
    0: {
      default: `Based on your health score, all your vitals seem to be in abnormal condition. Please follow your Providers instructions on medication & other activities to get all your vitals back to normal. If severe contact your Provider for treatment.`,
      0: `Currently, as no devices have been selected, the health score is displayed as 0. However, once the devices are interconnected and begin providing vitals data, your scores will be calculated based on that information.`,
    },
    20: {
      default: `Based on your health score, you have only one vital in normal condition. Maintaining your vital is important, so please follow your treatment plan to improve all your vital to be in good condition.`,
    },
    25: {
      default: `Based on your health score, you have only one vital in normal condition. Maintaining your vital is important, so please follow your treatment plan to improve all your vital to be in good condition.`,
    },
    30: {
      default: `Based on your health score, you have only one vital in normal condition. Maintaining your vital is important, so please follow your treatment plan to improve all your vital to be in good condition.`,
    },
    40: {
      default: `Based on your health score, you have only two vitals in normal condition. Maintaining all your vitals to be in normal condition leads to good health. So please take effort in maintaining your vitals normal by following your Provider instructions.`,
    },
    50: {
      default: `Based on your health score, you have only two vitals in normal condition. Maintaining all your vitals to be in normal condition leads to good health. So please take effort in maintaining your vitals normal by following your Provider instructions.`,
      2: `Based on your health score, you have only one vital in normal condition. Maintaining your vital is important, so please follow your treatment plan to improve all your vital to be in good condition.`,
    },
    60: {
      default: `Based on your health score, you have only two vitals in abnormal conditions. Take some effort in balancing your vitals to be in good condition by following your Providers instructions of medications & other activities.`,
      3: `Based on your health score, you have only one vital in abnormal condition. Keep up the good work in maintaining the vitals in good condition and take some extra care to the abnormal vital to bring it back to normal condition.`,
    },
    75: {
      default: `Based on your health score, you have only one vital in abnormal condition. Keep up the good work in maintaining the vitals in good condition and take some extra care to the abnormal vital to bring it back to normal condition.`,
    },

    80: {
      default: `Based on your health score, you have only one vital in abnormal condition. Keep up the good work in maintaining the vitals in good condition and take some extra care to the abnormal vital to bring it back to normal condition.`,
    },
    100: {
      default: `Based on your health score, all your vitals seems to be in normal condition. Please keep maintaining it the same by following your Providers instructions regularly and enjoy a healthy life.`,
    },
    default: `Currently, as no devices have been selected, the health score is displayed as 0. However, once the devices are interconnected and begin providing vitals data, your scores will be calculated based on that information.`,
  };
  let healthReport =
    healthReports[healthScore]?.[vitalsCount] ||
    healthReports[healthScore]?.["default"] ||
    healthReports["default"];
  // -------------------------- Functions End-------------------------------------------------------------//
  // -------------------------- useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    // Dougnut chart from chart js for health score calculator
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const newChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [healthScore, remaining],
              backgroundColor: ["#2C7BE5", "#ECEBEA"],
              borderColor: colors,
              borderWidth: 0, // To hide the border
              hoverBackgroundColor: ["#2C7BE5", "#ECEBEA"],
              weight: 2,
            },
          ],
        },
        options: {
          cutout: 65, // thickness of doughnut
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        },
      });
      return () => {
        // Cleanup: Destroy the chart when the component unmounts
        newChart.destroy();
      };
    }
  }, [healthScore]);
  // -------------------------- useEffect End-------------------------------------------------------------//

  return (
    <>
      <Div
        border=" 1px solid rgba(46, 46, 46, 0.25)"
        background=" #FFFFFF"
        width="52%"
        height="320px"
        borderRadius="8px"
        position="relative"
      >
        <BodyContainer padding="20px 18px 14px 18px">
          <Div display="flex" marginBottom="16px">
            <ResponsiveText
              fontSize="18px"
              fontWeight="600"
              lineHeight="normal"
              className="healthScore"
            >
              Current Health Score
            </ResponsiveText>
            <Text
              marginLeft="auto"
              fontSize="14px"
              fontWeight="400"
              lineHeight="normal"
            >
              {moment().format("MMMM D, YYYY")}
            </Text>
          </Div>
          <Div
            position="relative"
            width="160px"
            height="160px"
            textAlign="center"
            margin="auto auto 18px auto"
          >
            <canvas ref={chartRef} />
            <Text
              position="absolute"
              top="39%"
              left={healthScore === 100 ? "11%" : "20%"}
              transform="translate(-50%, -50%)"
              fontSize="34px"
              fontWeight="600"
            >
              {healthScore}/100
            </Text>
          </Div>
          <ResponsiveText
            fontSize="14px"
            fontWeight="400"
            lineHeight="normal"
            textAlign="justify"
            className="healthReport"
          >
            {healthReport}
          </ResponsiveText>
        </BodyContainer>
      </Div>
    </>
  );
};

export default HealthScore;
