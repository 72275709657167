import styled from "styled-components";

// export const PasswordContainer = styled.div`
//   margin: auto;
//   width: 50%;
//   text-align: center;
// `;

// export const TitleImage = styled.img`
//   display: block;
//   text-align: center;
//   margin: auto;
//   margin-bottom: 32px;
// `;

// export const Title = styled.h1`
//   font-weight: 700;
//   font-size: 36px;
//   line-height: 44px;
//   color: #000000;
//   margin-bottom: 12px;
// `;

// export const Description = styled.p`
//   font-size: ${(p) => p.fontSize && p.fontSize};
//   line-height: ${(p) => p.lineHeight && p.lineHeight};
//   font-family: ${(p) => p.fontFamily && p.fontFamily};
//   align-items: center;
//   text-align: ${(p) => p.textAlign && p.textAlign};
//   color: #667085;
//   margin-bottom: 32px;
// `;

export const FormGroupInline = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1650px) {
    width: 60%;
  }
  @media (max-width: 1440px) {
    width: 75%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 70%;
    margin-left: auto !important;
  }
`;
