import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetLogo,
  GetPatientUrl,
} from "../../../StateManagement/Reducers/PreLoginState";
import PortalAccess from "../../../Assets/images/portal_access.png";

import { BodyContainer, Button, Image, Div } from "../../StyledComponents";
import Loading from "../../StyledComponents/Loading";
import { GetFileUrl } from "../../GetFileUrl";

const AccessDenied = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  let seturl = GetFileUrl();

  const File_URL = seturl + "/documenting/";
  const text = useSelector((state) => state.PreLogin.logo);
  const PatientUrl = useSelector((state) => state.PreLogin.PatientUrl);
  // ------------------------------ State and Variables End-----------------------------------------------------//
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    dispatch(GetLogo(), GetPatientUrl());
  }, [dispatch]);
  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <BodyContainer textAlign="center" Flex="1">
      <Div
        marginTop="40px"
        marginBottom="15px"
        textAlign="left"
        marginLeft="50px"
      >
        {text.length !== 0 ? (
          <Image
            loading="lazy"
            src={File_URL + text}
            height="41px"
            width="173px"
            objectFit="contain"
            transition=" translate1s"
          />
        ) : (
          <Loading />
        )}
      </Div>

      <Image
        src={PortalAccess}
        display="block"
        width="52%"
        height="62%"
        transition=" translate1s"
      />
      <Button
        background="#407BFF"
        display="block"
        height="50px"
        fontFamily="'Inter',sans-serif"
        fontSize="16px"
        fontWeight="500"
        lineHeight="26px"
        padding="12px 10%"
        borderRadius="8px"
        marginLeft="auto"
        marginRight="auto"
        marginTop="46px"
        cursor="unset"
      >
        You dont have access to {PatientUrl}. Please contact your provider for
        details
      </Button>
    </BodyContainer>
  );
};

export default AccessDenied;
