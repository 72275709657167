import React, { useState, useEffect } from "react";
import { Div, Image, Text } from "../StyledComponents";
import {
  Refresh,
  HeartRate,
  BloodPressure,
  RespiratoryRate,
  Glucometer,
  Oxygenmeter,
  Weightmeter,
  VitalText,
  VitalTextHead,
  DivVital,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { SpinnerWrapper, SpinnerLetter, Spinner } from "./styles";
import axiosInst from "../../Config";
import { SetVitals } from "../../StateManagement/Reducers/DashboardState";
import Logo from "../../Assets/images/Logo.png";

const Vitals = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------//
  const dispatch = useDispatch();

  const [dataLoaded, setDataLoaded] = useState(false);

  const patId = Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

  const vitalsData1 = useSelector((state) => state.Dashboard.vitalsData);

  const devicesList = useSelector((state) => state.Dashboard.DeviceList);

  const SingPatDetails = {
    enrollment_status: "2",
  };
  const parsedPulse = parseInt(vitalsData1.pulse);
  const pulse = !isNaN(parsedPulse) ? parsedPulse : null;

  const parsedBps1 = parseInt(vitalsData1.bps_1);
  const parsedBps2 = parseInt(vitalsData1.bps_2);

  const bps1 = !isNaN(parsedBps1) ? parsedBps1 : null;
  const bps2 = !isNaN(parsedBps2) ? parsedBps2 : null;

  const showBp = bps1 !== null && bps2 !== null;

  const parsedRespiratoryRate = parseInt(vitalsData1.respiration);

  const respiratoryRate = !isNaN(parsedRespiratoryRate)
    ? parsedRespiratoryRate
    : null;

  const parsedOxygenSaturation = parseFloat(vitalsData1.oxygen_saturation);
  const oxygenSaturation = !isNaN(parsedOxygenSaturation)
    ? parsedOxygenSaturation
    : null;
  const parsedWeight = parseFloat(vitalsData1.weight);
  const weight = !isNaN(parsedWeight) ? parsedWeight : null;

  const parsebloodGlucoseLevel = parseInt(vitalsData1.BloodGlucoseLevel);
  const bloodGlucoseLevel = !isNaN(parsebloodGlucoseLevel)
    ? parsebloodGlucoseLevel
    : null;
  // ----------------------------State and Variables ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//

  const refreshVitals = async () => {
    const deviceModelArray = devicesList.Device_model;

    const device_mod_obj = deviceModelArray.map(
      (details) => details.device_model
    );

    const wordsToCheckforSmartMeter = {
      words: [
        "iScale",
        "iScale plus",
        "iPlulse Ox",
        "iBlood Pressue",
        "iGlucose",
        "iBlood Pressure plus",
        "iBlood Pressure 2.0",
        "iBlood Pressure Classic",
      ],
      endpoint: "smart_meter_device/iglucose_data.php",
    };

    const wordsToCheckforBodyTrace = {
      words: ["Bodytrace Blood Pressure Monitor", "Bodytrace Weight Scale"],
      endpoint: "body_trace_api/get_data.php",
    };

    const wordsToCheckforTenovi = {
      words: [
        "Tenovi Blood Glucose Meter",
        "Tenovi Pulse Oximeter",
        "Tenovi Weigh Scale",
        "Tenovi Digital Blood Pressure Monitor",
      ],
      endpoint: "tenovi/get_tenovi_data.php",
    };

    if (
      wordsToCheckforSmartMeter.words.some((word) =>
        device_mod_obj.includes(word)
      )
    ) {
      axiosInst.get(
        `${process.env.REACT_APP_BACKEND_FILE_ACCESS}/interface/customized/custom_api_device_data/${wordsToCheckforSmartMeter.endpoint}?pid=${patId}`
      );
    }
    if (
      wordsToCheckforBodyTrace.words.some((word) =>
        device_mod_obj.includes(word)
      )
    ) {
      axiosInst.get(
        `${process.env.REACT_APP_BACKEND_FILE_ACCESS}/interface/customized/custom_api_device_data/${wordsToCheckforBodyTrace.endpoint}?pid=${patId}`
      );
    }
    if (
      wordsToCheckforTenovi.words.some((word) => device_mod_obj.includes(word))
    ) {
      axiosInst.get(
        `${process.env.REACT_APP_BACKEND_FILE_ACCESS}/interface/customized/custom_api_device_data/${wordsToCheckforTenovi.endpoint}?pid=${patId}`
      );
    }
  };

  const getVitals = async () => {
    dispatch(SetVitals());
  };

  const refresh = async () => {
    if (SingPatDetails.enrollment_status !== "4") {
      try {
        await refreshVitals();
        await getVitals();
      } catch (error) {
        console.error(error);
      }
    }
  };
  // -------------------------- Functions End-------------------------------------------------------------//
  // -------------------------- useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    dispatch(SetVitals());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);
  // -------------------------- useEffect End-------------------------------------------------------------//
  return !dataLoaded ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <Div>
      <Text
        fontFamily="'Open Sans', sans-serif"
        fontWeight="600"
        fontSize="18px"
        color=" #2E2E2E"
        lineHeight="25px"
        fontStyle="normal"
        marginBottom="8px"
        marginLeft="2px"
        padding="20px 0px 0px 0px"
      >
        Vitals
      </Text>
      <Div display="flex" alignItems="center" margin="0px 0px 8px 0px">
        <Text
          marginLeft="2px"
          fontFamily="'Open Sans', sans-serif"
          fontWeight="400"
          fontSize="14px"
        >
          {`Most recent vitals from : ${
            vitalsData1.currentTime !== undefined
              ? vitalsData1.currentTime
              : "-"
          }`}
        </Text>
        <Refresh
          cursor={
            SingPatDetails.enrollment_status !== "4" ? "pointer" : "not-allowed"
          }
          onClick={refresh}
        />
      </Div>

      <Div
        paddingTop="8px"
        display="flex"
        paddingBottom="20px"
        justifyContent="space-between"
      >
        <Div
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="130px"
          borderRadius="8px"
          padding="0px 15px"
          position="relative"
        >
          <Div display="flex" padding="16px 0px 20px 0px">
            <Div>
              <HeartRate />
            </Div>
            <VitalTextHead
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
            >
              Heart Rate
            </VitalTextHead>
          </Div>
          {pulse !== null && (
            <Div
              padding="15px 0px 0px 0px"
              display="flex"
              alignItems="baseline"
              position="absolute"
              bottom="18px"
            >
              <VitalText
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={pulse < 60 || pulse > 100 ? "#F24822" : "#2C7BE5"}
                lineHeight="33px"
                fontStyle="normal"
              >
                {pulse}
              </VitalText>

              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={pulse < 60 || pulse > 100 ? "#F24822" : "#2C7BE5"}
                fontStyle="normal"
                paddingLeft="4px"
              >
                BPM
              </Text>
            </Div>
          )}
        </Div>
        <Div
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="130px"
          borderRadius="8px"
          padding="0px 15px"
          position="relative"
        >
          <Div display="flex" padding="16px 0px 16px 0px">
            <Div>
              <BloodPressure />
            </Div>
            <VitalTextHead
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
              marginLeft="-5px"
            >
              Blood Pressure
              <span style={{ visibility: "hidden" }}> level </span>
            </VitalTextHead>
          </Div>
          {showBp && (
            <Div
              display="flex"
              alignItems="baseline"
              overflow="hidden"
              position="absolute"
              bottom="18px"
              className="bp_alt_for_ipad"
            >
              <VitalText
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={
                  bps1 >= 90 && bps1 <= 120 && bps2 >= 60 && bps2 <= 80
                    ? "#2C7BE5" // Normal
                    : "#F24822" // All other conditions
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {bps1}/{bps2}
              </VitalText>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  bps1 >= 90 && bps1 <= 120 && bps2 >= 60 && bps2 <= 80
                    ? "#2C7BE5" // Normal
                    : "#F24822" // All other conditions
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                mm/Hg
              </Text>
            </Div>
          )}
        </Div>
        <DivVital
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="130px"
          borderRadius="8px"
          padding="0px 12px"
          position="relative"
        >
          <Div display="flex" padding="16px 0px 16px 0px">
            <Div>
              <RespiratoryRate />
            </Div>
            <VitalTextHead
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
            >
              Respiratory Rate
              <span style={{ visibility: "hidden" }}> level </span>
            </VitalTextHead>
          </Div>
          {respiratoryRate !== null && (
            <Div
              display="flex"
              alignItems="baseline"
              position="absolute"
              bottom="18px"
            >
              <VitalText
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={
                  respiratoryRate >= 12 && respiratoryRate <= 20
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {respiratoryRate}
              </VitalText>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  respiratoryRate >= 12 && respiratoryRate <= 20
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                RPM
              </Text>
            </Div>
          )}
        </DivVital>
        <Div
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="130px"
          borderRadius="8px"
          padding="0px 15px"
          position="relative"
        >
          <Div
            display="flex"
            // alignItems="flex-start"
            padding="16px 0px 16px 0px"
          >
            <Div>
              <Glucometer />
            </Div>
            <VitalTextHead
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
            >
              Blood Glucose level
            </VitalTextHead>
          </Div>
          {bloodGlucoseLevel !== null && (
            <Div
              display="flex"
              alignItems="baseline"
              position="absolute"
              bottom="18px"
            >
              <VitalText
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={
                  bloodGlucoseLevel >= 120 && bloodGlucoseLevel <= 140
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {bloodGlucoseLevel}
              </VitalText>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  bloodGlucoseLevel >= 120 && bloodGlucoseLevel <= 140
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Elevated and High
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                mg/dL
              </Text>
            </Div>
          )}
        </Div>
        <DivVital
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="130px"
          borderRadius="8px"
          padding="0px 15px"
          position="relative"
        >
          <Div
            display="flex"
            // alignItems="flex-start"
            padding="16px 0px 0px 0px"
          >
            <Div>
              <Oxygenmeter />
            </Div>
            <VitalTextHead
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
            >
              Oxygen Saturation
              <span style={{ visibility: "hidden" }}>level</span>
            </VitalTextHead>
          </Div>
          {oxygenSaturation !== null && (
            <Div
              display="flex"
              alignItems="baseline"
              padding="30px 0px 0px 0px"
              position="absolute"
              bottom="18px"
            >
              <VitalText
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={
                  oxygenSaturation >= 95 && oxygenSaturation <= 100
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {oxygenSaturation}
              </VitalText>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  oxygenSaturation >= 95 && oxygenSaturation <= 100
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                %
              </Text>
            </Div>
          )}
        </DivVital>
        <Div
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="130px"
          borderRadius="8px"
          position="relative"
        >
          <Div padding="0px 15px">
            <Div display="flex" padding="16px 0px 20px 0px">
              <Div>
                <Weightmeter />
              </Div>
              <VitalTextHead
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color=" #2E2E2E"
                fontStyle="normal"
                paddingLeft="8px"
              >
                Weight
              </VitalTextHead>
            </Div>
            {weight !== null && (
              <Div
                padding="15px 0px 0px 0px"
                display="flex"
                alignItems="baseline"
                position="absolute"
                bottom="18px"
              >
                <VitalText
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="700"
                  fontSize="24px"
                  color="  #2C7BE5"
                  lineHeight="33px"
                  fontStyle="normal"
                >
                  {weight}
                </VitalText>
                <Text
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  fontSize="14px"
                  color=" #2C7BE5"
                  fontStyle="normal"
                  paddingLeft="4px"
                >
                  Kg
                </Text>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default Vitals;
