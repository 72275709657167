import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

export const DocumentList_Api = async (Doc_type) =>
  await axiosInst.post(`/rpmportal/getDocumentData?pid=${Pat_ID}`, Doc_type);

export const InsertDocuments_Api = async (File_data) =>
  await axiosInst.post(
    `/rpmportal/InsertDocumentData?pid=${Pat_ID}`,
    File_data
  );

export const UpdateDocument_Api = async (row_id) =>
  await axiosInst.put(`/rpmportal/UpdateDocumentData?pid=${Pat_ID}`, row_id);

export const PreViewDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/rpmportal/DownloadDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const DeleteTempDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/rpmportal/DeleteTempDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const DownloadDocument_Api = async (Doc_Data) =>
  await axiosInst.put(`/rpmportal/PreviewDownloadDoc?pid=${Pat_ID}`, Doc_Data);

export const SignDocument_Api = async (Doc_Data) =>
  await axiosInst.post(`/rpmportal/SaveSignatureData?pid=${Pat_ID}`, Doc_Data);

export const DeleteDocument_Api = async (Data) =>
  await axiosInst.put(`/rpmportal/DeleteDocumentData?pid=${Pat_ID}`, Data);
