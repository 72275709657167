import { call, put } from "redux-saga/effects";

import {
  SetPatGenderDetails,
  SetPatMaritalStatus,
  SetPatRaceDetails,
  SetPatLangDetails,
  setPatientUpdate,
  SetPhoneVerify,
  SetSSNVerify,
  SetMailVerify,
} from "../../StateManagement/Reducers/SettingState";
import { SetSinglePatDetail } from "../../StateManagement/Reducers/DashboardState";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  PatGenderDetails_Api,
  PatMaritalStatus_Api,
  PatLangDetails_Api,
  PatRaceDetails_Api,
  PatientEnrollemt_Api,
  PatientEnrollemtUpdate_Api,
  Image_Api,
  MailVerify_Api,
} from "../Apis/SettingsApi";
import { GetSinglePatientDetail_Api } from "../Apis/DashBoardApi";

export function* PatGenderDetailsApi() {
  try {
    const res = yield call(PatGenderDetails_Api);

    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozorpm");

      yield put(SetPatGenderDetails(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatMaritalStatusApi() {
  try {
    const res = yield call(PatMaritalStatus_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPatMaritalStatus(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatLangDetailsApi() {
  try {
    const res = yield call(PatLangDetails_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPatLangDetails(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatRaceDetailsApi() {
  try {
    const res = yield call(PatRaceDetails_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPatRaceDetails(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* patEnrollemtData({ payload }) {
  let data = payload[0];
  const credential = Encrypt_Value(data.article, "vozorpm");
  try {
    const res = yield call(PatientEnrollemt_Api, credential);
    if (res.status === 200) {
      let getId = res.data;

      let getpid = getId.length !== 0 ? getId : payload[2];

      if (payload[1] && payload[1] !== "" && payload[1] !== null) {
        yield call(Image_Api, [getpid, data.file]);
      }
      try {
        const res1 = yield call(GetSinglePatientDetail_Api, getpid);

        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          yield put(SetSinglePatDetail(Data));
          yield put(setPatientUpdate("1"));
        }
      } catch (e) {
        console.log(e.message);
        yield put(setPatientUpdate(""));
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(setPatientUpdate(""));
  }
}

export function* getSinglePatientList({ payload }) {
  try {
    const res = yield call(GetSinglePatientDetail_Api, payload);

    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      localStorage.setItem(
        "encounter",
        Encrypt_Value(Data.encounter ? Data.encounter : 0, "vozorpm")
      );
      yield put(SetSinglePatDetail(Data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetSinglePatDetail([]));
  }
}

export function* EnrollmentUpdate({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(PatientEnrollemtUpdate_Api, encryptedData);
    if (res.status === 200) {
      return "success";
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* setPatUpdateResEmpty() {
  yield put(setPatientUpdate(""));
}

export function* MailVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(MailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetMailVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PhoneVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPhoneVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* SSNVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSSNVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
