import axiosInst from "../../Config";

export const PatGenderDetails_Api = async () =>
  await axiosInst.get(`/rpmportal/list?list_name=sex`);

export const PatMaritalStatus_Api = async () =>
  await axiosInst.get(`/rpmportal/list?list_name=marital`);

export const PatLangDetails_Api = async () =>
  await axiosInst.get(`/rpmportal/list?list_name=language`);

export const PatRaceDetails_Api = async () =>
  await axiosInst.get(`/rpmportal/list?list_name=ethrace`);

export const PatientEnrollemt_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/patientenrollment`, credential);

export const PatientEnrollemtUpdate_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/patientenrollment/update`, credential);

export const Image_Api = async (data) =>
  await axiosInst.post(`/vozorpm/image/Upload?pid=${data[0]}`, data[1]);

export const MailVerify_Api = async (credential) =>
  await axiosInst.get(`/vozorpm/patmailcheck?articles=` + credential);
