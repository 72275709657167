import React from "react";
import WelcomeRight from "../WelcomeRight";
import LoginLeft from "../LoginLeft";
import { GetFileUrl } from "../../GetFileUrl";
import { Div } from "../../StyledComponents";
const Welcome = () => {
  let backurl = GetFileUrl();

  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF">
        <WelcomeRight />
      </Div>
    </Div>
  );
};

export default Welcome;
