import { useEffect, useState } from "react";
import moment from "moment";
import { Decrypt_Value, Encrypt_Value } from "../MiddleWare/EncryptDecrypt";
import axiosInst from "./../Config";

const useLogout = (startTime) => {
  // countdown start
  const [timer, setTimer] = useState(startTime);
  const currTimes = moment().add(3, "m").format("YYYY-MM-DD HH:mm A");
  // api start time

  // token exxp time
  const [token_exp_times, settoken_exp_times] = useState(
    moment(
      localStorage.getItem("token_exp")
        ? Decrypt_Value(localStorage.getItem("token_exp"), "rpmportal")
        : ""
    ).format("YYYY-MM-DD HH:mm A")
  );
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "rpmportal");
  const data = {
    pid: Pat_ID,
  };
  /////////////////////////////////////getting access token using refresh token starts here/////////////////////////////////////////

  const refreshToken = () => {
    var val = {
      refresh_token:
        localStorage.getItem("refreshtoken") &&
        Decrypt_Value(localStorage.getItem("refreshtoken"), "rpmportal"),
    };
    let Encrypt_details = Encrypt_Value(val, "vozorpm");
    axiosInst
      .post("/rpmportal/refresh", Encrypt_details)
      .then((response) => {
        let decrypt_val = Decrypt_Value(response.data, "vozorpm");
        // axiosInst.defaults.headers.common[
        //   "Authorization"
        // ] = `${decrypt_val.token}`;
        let token_enc = decrypt_val.token;
        localStorage.setItem("accesstoken", token_enc);
        localStorage.setItem(
          "token_exp",
          Encrypt_Value(decrypt_val.token_exp, "rpmportal")
        );
        localStorage.setItem(
          "refreshtoken",
          Encrypt_Value(decrypt_val.refresh_token, "rpmportal")
        );
        settoken_exp_times(
          moment(decrypt_val.token_exp).format("YYYY-MM-DD HH:mm A")
        );
      })
      .catch((e) => {
        console.log(e);
        axiosInst
          .post(`/rpmportal/updatePortalLogout`, data)
          .then((response) => {
            localStorage.clear();
            window.location.href = "/";
          })
          .catch((e) => {
            console.log(e);
            localStorage.clear();
            window.location.href = "/";
          });
      });
  };

  useEffect(() => {
    if (token_exp_times === currTimes) {
      refreshToken();
    }

    // run use effect after 1min
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 60000);

    const resetTimeout = () => {
      setTimer(startTime);
    };

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });

  return timer;
};

export default useLogout;
