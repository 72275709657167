import styled from "styled-components";
import { Link } from "react-router-dom";

export const Div = styled.div`
  visibility: ${({ visibility }) => visibility && visibility};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`}  
  ${(p) => p.fontSize && `font-size : ${p.fontSize};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  ${(p) => p.alignItems && `align-items : ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content : ${p.justifyContent};`}
  ${(p) => p.textShadow && `text-shadow : ${p.textShadow};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  background: ${(p) => p.background && p.background};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  box-sizing: ${(p) => p.boxSizing && p.boxSizing};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-right: ${(p) => p.borderRight && p.borderRight};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border-right: ${(p) => p.borderRight && p.borderRight};
  transform: ${(props) => (props.transform ? "translate(-50%, -50%)" : "")};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  float: ${(p) => p.float && p.float};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  border-top-left-radius: ${(p) =>
    p.borderTopLeftRadius && p.borderTopLeftRadius};
  border-top-right-radius: ${(p) =>
    p.borderTopRightRadius && p.borderTopRightRadius};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  min-width: ${(p) => p.minWidth && p.minWidth};
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  bottom: ${(p) => p.bottom && p.bottom};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  gap: ${(p) => p.gap && p.gap};
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterVertical) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
    justify-content : center;
    `;
    } else if (p.flexCenterBetween) {
      return `display : flex;
    align-items : start;
    justify-content : space-between;
    `;
    }
  }}
  width: ${(p) => {
    if (p.widthOffset) {
      return `calc(100% - ${p.widthOffset})`;
    } else {
      return p.width && p.width;
    }
  }};

  @media (max-width: 820px) {
    width: ${(p) => p.resWidth && p.resWidth};
  }
  @media screen and (min-width: 820px) {
    &.hg_alt_ipad {
      margin-right: 0.5px !important;
    }
    &.bp_alt_for_ipad {
      right: 6px;
    }
  }
`;

export const Text = styled.p`
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-top: ${(p) => p.svgmarginTop && p.svgmarginTop};
    margin-left: ${(p) => p.svgmarginLeft && p.svgmarginLeft};
  }
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  word-wrap: ${(p) => p.wordWrap && p.wordWrap};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  display: ${(p) => p.display && p.display};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  align-items: ${(p) => p.alignItems && p.alignItems};
`;

export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  color: ${(p) => p.color && p.color};
`;

export const PageSubTitle = styled.h4`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  color: ${(p) => p.color && p.color};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  margin: ${(p) => p.margin && p.margin};
`;

export const Image = styled.img`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  position: ${(p) => p.position && p.position};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  z-index: ${(p) => p.zIndex && p.zIndex};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  right: ${(p) => p.right && p.right};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
  transition: ${(props) => props.transition && props.transition};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
`;

export const Button = styled.button`
  cursor: ${(p) => p.cursor && p.cursor};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin: ${(p) => p.margin && p.margin};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-right: ${(p) => p.svgmarginRight && p.svgmarginRight};
  }
  ${(p) =>
    p.disabled &&
    `
      cursor: not-allowed;
      `}
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1) !important;
  }
  &.close_active:disabled {
    background-color: #fff !important;
    cursor: not-allowed;
  }
`;

export const LinK = styled(Link)`
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  text-decoration: none;
  line-height: ${(p) => p.lineheight && p.lineheight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  svg {
    font-size: ${(p) => p.fontSize && p.fontSize};
    margin-right: ${(p) => p.marginRight && p.marginRight};
  }
`;

export const BodyContainer = styled.div`
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  padding: ${(p) => p.padding && p.padding};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-align: ${(p) => p.textAlign && p.textAlign};
  flex: ${(p) => p.Flex && p.Flex};
  border: ${(p) => p.border && p.border};
`;

export const Form = styled.form`
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
`;

export const FormLabel = styled.label`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${({ padding }) => padding && padding};
  display: ${({ display }) => display && display};
  margin: ${({ margin }) => margin && margin};
`;

export const FormGroup = styled.div`
  margin: ${(p) => p.margin && p.margin};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
  grid-column: ${(p) => p.gridColumn && p.gridColumn};
`;
// Form

export const Input = styled.input`
  color: ${(p) => p.color && p.color};
  ${(p) => p.width && `width: ${p.width};`}
  ${(p) => p.display && `display: ${p.display};`}
  ${(p) => p.height && `height: ${p.height};`}
  ${(p) => p.margin && `margin: ${p.margin};`}
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  ${(p) => p.position && `position: ${p.position};`}  
  border-top-left-radius: ${(p) => p.btlr && p.btlr};
  border-top-right-radius: ${(p) => p.btrr && p.btrr};
  border-bottom-left-radius: ${(p) => p.bblr && p.bblr};
  border-bottom-right-radius: ${(p) => p.bbrr && p.bbrr};
  border-left-width: ${(p) => p.blw && p.blw};
  border-right-width: ${(p) => p.brw && p.brw};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  &:focus {
    outline: none;
  }
  ${(p) => p.resize && `resize: ${p.resize};`}
  font-family: ${(p) => p.fontFamily && p.fontFamily};
`;

export const Span = styled.span`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  top: ${(p) => p.top && p.top};
  position: ${(p) => p.position && p.position};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;

export const Anchor = styled.a`
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  color: ${(p) => p.color && p.color};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const RedStar = styled.span`
  color: #b00020;
  vertical-align: 1px;
  font-size: 20px;
  margin: ${(p) => p.margin && p.margin};
  position: absolute;
`;
export const ErrorMessage = styled.p`
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;

export const SelectInput = styled.div`
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.pl && p.pl};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: relative;
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
`;

//Drop Down//

export const DropDown = styled.h4`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;

export const DropDownDiv = styled.div`
  margin: ${(p) => p.margin && p.margin};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  display: grid;
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: absolute;
  z-index: 1000;
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  left: ${(p) => p.left && p.left};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const DropdownImage = styled.img`
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
`;

export const DropdownOptions = styled.label`
  display: ${(p) => p.display && p.display};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &:hover {
    background-color: #2c7be5 !important;
    color: #ffffff !important;
  }
  cursor: pointer;
`;

//Drop Down //

// Table Styled Component //

export const Table = styled.table`
  margin-top: 1px;
  width: 100%;
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  height: ${(p) => (p.height ? p.height : "100px")};
`;

export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(props) => props.background};
  display: ${(p) => (p.scroll ? "table" : "")};
  width: ${(p) => (p.scroll ? "100%" : "")};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  height: ${(p) => p.height && p.height};

  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  overflow-y: ${(p) => (p.scroll ? "scroll" : "")};
  display: ${(p) => (p.scroll ? "block" : "")};
  height: ${(p) => (p.scroll ? p.height : "")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  background-color: ${(props) => props.background};
`;

export const TableHeadCell = styled.th`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : " 600")};
  font-size: 14px;
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  color: ${(p) => (p.color ? p.color : "#464646")};
  padding: ${(p) => (p.padding ? p.padding : "20px 40px 20px 40px")};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  min-width: ${(p) => p.minWidth && p.minWidth};
  // wid:
`;

export const TableBodyCell = styled.td`
  font-size: 14px;
  align-items: ${(p) => p.alignItems && p.alignItems};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")} !important;
  color: ${(p) => (p.color ? p.color : " #000000")};
  padding: ${(p) => (p.padding ? p.padding : "16px 40px 16px 38px")};
  text-align: ${(p) => p.textAlign && p.textAlign};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  cursor: ${({ cursor }) => cursor && "pointer"};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  overflow: ${(p) => p.overflow && p.overflow};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  width: ${(p) => p.width && p.width};
`;
export const TableRow = styled.tr`
  cursor: ${({ cursor }) => cursor && "pointer"};
  text-align: left;
  background-color: ${(props) => props.background};
  &:last-child ${TableBodyCell} {
    border-bottom: ${(p) => (p.bbtom ? "1px solid #D8E0F0" : "none")};
  }
  display: ${(p) => (p.scroll ? "table" : p.display)};
  width: ${(p) => (p.scroll ? "100%" : p.width)};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
    //margin: 5px;
    // margin-right:4px;
  }
`;
// Table Styled Component //

// Modal //

export const Modal = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: 0;
  left: 0;
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: 1055;
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100vh")};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;

export const ModalContainer = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  z-index: 1055;
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "hidden")};
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  outline: 0;
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : " #ffffff"};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  cursor: auto;
  border: ${(p) => p.border && p.border};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  padding: ${(p) => p.padding && p.padding};
`;

export const ModalTitle = styled.h4`
  line-height: ${(p) => (p.lineHight ? p.lineHight : "27px")};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  color: ${(p) => (p.color ? p.color : "#232934")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-style: ${(p) => (p.fontStyle ? p.fontStyle : p.fontStyle)};
  margin: ${(p) => p.margin && p.margin};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

// Modal //
export const Audio = styled.audio`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  &.IpadResize {
    @media (min-width: 800px) and (max-width: 820px) {
      width: 180px;
    }
  }
`;
