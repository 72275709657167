import React, { useEffect } from "react";
import TopNav from "../TopNav";
import { useDispatch, useSelector } from "react-redux";
import {
  SetVitals,
  GetSinglePatDetail,
  getDeviceList,
} from "../../StateManagement/Reducers/DashboardState";
import { BodyContainer, Div, Image, Span } from "../StyledComponents";
import { VitalsDiv } from "./Styles";
import HealthScore from "./HealthScore";
import VitalsDashboard from "./VitalsDashboard";
import PatientStatus from "./PatientStatus";
import DeviceAssigned from "./DeviceAssigned";
import Careteam from "./Careteam";
import { Spinner, SpinnerWrapper } from "../Vitals/styles";
import Logo from "../../Assets/images/Logo.png";

const Dashboard = () => {
  const dispatch = useDispatch();
  const SingPatDetails = useSelector((state) => state.Dashboard.SinglePatient);
  const Loading = useSelector((state) => state.Dashboard.LoadingVitals);
  const File_URL =
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documenting/";
  useEffect(() => {
    dispatch(SetVitals());
    dispatch(getDeviceList());
    dispatch(GetSinglePatDetail());
  }, []);
  return (
    <>
      <TopNav title="Dashboard" />
      {Loading ? (
        <>
          <SpinnerWrapper>
            <Spinner></Spinner>
            <Image width="40px" height="40px" position="absolute" src={Logo} />
          </SpinnerWrapper>
        </>
      ) : (
        <BodyContainer
          paddingTop="13px"
          paddingRight="28px"
          paddingBottom="80px"
          paddingLeft="24px"
        >
          <Div
            font-weight="700"
            font-size="36px"
            display="flex"
            align-items="center"
            margin-bottom="4px"
          >
            {SingPatDetails.img_url === "" ||
            SingPatDetails.img_url === null ? (
              <Div
                position="relative"
                height="70px"
                width="70px"
                borderRadius="50%"
                marginRight="20px"
                padding="4px"
              >
                <Div
                  background="#A8AEBE"
                  height="100%"
                  width="100%"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                  fontSize="34px"
                >
                  {SingPatDetails.fname.charAt(0).toUpperCase()}
                </Div>
              </Div>
            ) : (
              <Div
                position="relative"
                height="70px"
                width="70px"
                borderRadius="50%"
                marginRight="20px"
                padding="4px"
              >
                <Image
                  loading="lazy"
                  height="100%"
                  width="100%"
                  borderRadius="50%"
                  src={
                    SingPatDetails.img_url !== undefined &&
                    File_URL + SingPatDetails.img_url
                  }
                />
              </Div>
            )}
            <Span
              fontFamily="Open Sans,sans-serif"
              fontSize="24px"
              fontWeight="300"
              lineHeight="normal"
              marginTop="20px"
            >
              Hi, <b>{SingPatDetails.patname}</b>
            </Span>
          </Div>

          <VitalsDiv>
            <VitalsDashboard />
            <Div display="flex">
              <HealthScore />
              <PatientStatus />
            </Div>
            <Div display="flex" paddingTop="24px" paddingBottom="20px">
              <DeviceAssigned />
              <Careteam />
            </Div>
          </VitalsDiv>
        </BodyContainer>
      )}
    </>
  );
};

export default Dashboard;
