import { takeLatest } from "redux-saga/effects";
import {
  GetPatGenderDetails,
  GetPatMaritalStatus,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPatEnrollment,
  GetPatEnrollmentUpdate,
  setEmptyPatUpdateResponse,
  GetSSNVerify,
  GetMailVerify,
  GetPhoneVerify,
} from "../../StateManagement/Reducers/SettingState";
import {
  PatGenderDetailsApi,
  PatMaritalStatusApi,
  PatLangDetailsApi,
  PatRaceDetailsApi,
  patEnrollemtData,
  EnrollmentUpdate,
  setPatUpdateResEmpty,
  SSNVerifyApi,
  MailVerifyApi,
  PhoneVerifyApi,
} from "./SettingsSagaWorkers";
import { getSinglePatientList } from "../DashBoard/DashboardSagaWorkers";

export function* PatGenderDetails() {
  yield takeLatest(GetPatGenderDetails.type, PatGenderDetailsApi);
}
export function* PatMaritalStatus() {
  yield takeLatest(GetPatMaritalStatus.type, PatMaritalStatusApi);
}
export function* PatLangDetails() {
  yield takeLatest(GetPatLangDetails.type, PatLangDetailsApi);
}
export function* PatRaceDetails() {
  yield takeLatest(GetPatRaceDetails.type, PatRaceDetailsApi);
}
export function* PatientEnrollemt() {
  yield takeLatest(GetPatEnrollment.type, patEnrollemtData);
}
// export function* SinglePatientDetails() {
//   yield takeLatest(GetSinglePatDetail.type, getSinglePatientList);
// }
export function* PatientEnrollemtUpdate() {
  yield takeLatest(GetPatEnrollmentUpdate.type, EnrollmentUpdate);
}

export function* EmptyingPatUpdateResponse() {
  yield takeLatest(setEmptyPatUpdateResponse.type, setPatUpdateResEmpty);
}

export function* SSNVerify() {
  yield takeLatest(GetSSNVerify.type, SSNVerifyApi);
}

export function* MailVerify() {
  yield takeLatest(GetMailVerify.type, MailVerifyApi);
}

export function* PhoneVerify() {
  yield takeLatest(GetPhoneVerify.type, PhoneVerifyApi);
}
