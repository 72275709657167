import { call } from "redux-saga/effects";

import { LoginUpdateApi } from "../Apis/TopNavbarApi";
export function* LoginUpdate({}) {
  try {
    const res = yield call(LoginUpdateApi);
  } catch (e) {
    console.log(e);
  }
}
