import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import { setReadingsData } from "../../StateManagement/Reducers/VitalsState";
import { ReadingChartApi } from "../Apis/VitalsApi";

export function* ReadingChartData({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozorpm");
  try {
    const res = yield call(ReadingChartApi, credential);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozorpm");
      yield put(setReadingsData(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setReadingsData({}));
  }
}
