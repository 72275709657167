import React, { useState, memo } from "react";
import { Div, FormLabel } from "../StyledComponents";
import { Tabs, Container1, TabPanel } from "./styles";
import Myprofile from "./Myprofile";
import Insurance from "./Insurance";
import TopNav from "../TopNav";
const Settings = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = useState("Personal");
  const [value1, setValue1] = useState(false);
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const handleTabs = (tabIndex, value) => {
    setValue(value);
    setActiveTab(tabIndex);
    setValue1(true);
  };

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div>
            <Myprofile value={value} value1={value1} />
          </Div>
        );
      case 2:
        return (
          <Div>
            <Insurance value={value} value1={value1} />
          </Div>
        );

      default:
        return null;
    }
  };
  // ------------------------------------------ Functions End--------------------------------------------------------- //
  return (
    <>
      <TopNav title="Edit Profile" />
      <Div background="#FFFFFF">
        <Container1>
          <TabPanel
            width="100%"
            background="#ffffff"
            borderRadius="8px 8px 0px 0"
            borderBottom="1px solid rgba(46, 46, 46, 0.25);"
            display="flex"
            justifyContent="flex-start"
            padding="10px 25px 0 0px"
          >
            <Tabs
              width="130px"
              fontWeight="600"
              fontFamily="'Open Sans'"
              lineHeight="2px"
              color="rgba(46, 46, 46, 0.5)"
              padding="0px 0px 0 24px"
              fontSize="16px"
              isActive={activeTab === 1}
              marginTop="8px"
              onClick={() => handleTabs(1, "Personal")}
              line={activeTab}
            >
              My Profile
            </Tabs>
            <Tabs
              width="98px"
              fontWeight="600"
              fontFamily="'Open Sans'"
              lineHeight="2px"
              fontSize="16px"
              color="rgba(46, 46, 46, 0.5)"
              padding="0px 0px 0 0px"
              marginTop="8px"
              marginLeft="-10px"
              isActive={activeTab === 2}
              onClick={() => handleTabs(2, "allergy")}
            >
              Insurance
            </Tabs>
          </TabPanel>
          {tabComponent()}
        </Container1>
      </Div>
    </>
  );
};

export default memo(Settings);
