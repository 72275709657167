import styled from "styled-components";
import { NavLink } from "react-router-dom";
import vozoicon from "../../Assets/images/Vozo_Icon.png";
import { MdDashboard } from "react-icons/md";
import { RiHeartPulseFill } from "react-icons/ri";
import { BsChatLeftTextFill } from "react-icons/bs";
import { BsFileEarmarkTextFill } from "react-icons/bs";

export const LogoContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
  display: flex;
`;

export const Vozoiconpic = styled.img.attrs({
  src: vozoicon,
  alt: "image not found",
})`
  margin-left: auto;
  margin-right: auto;
`;

export const Nav = styled.nav`
  margin-top: 21px;
  padding: 0px 12px 0px 12px;
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  display: flex;
  align-items: center !important;
  padding: 14px 0px 14px 15px !important;
  &.active {
    color: rgba(44, 123, 229, 1);
  }
`;

export const Navicon = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Hrline = styled.hr`
  border-top: 1px solid #eeeeef;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: none;
`;

export const Ul = styled.ul``;

export const Li = styled.li`
  list-style-type: none;
  ${({ isActive }) =>
    isActive &&
    `  color: rgba(44, 123, 229, 1);
background-color: rgba(44, 123, 229, 0.10);
height: 48px;
`}
`;

export const DashboardIcon = styled(MdDashboard)`
  font-size: 24px;
  color: ${({ isActive }) =>
    isActive
      ? `rgba(44, 123, 229, 1) !important`
      : `rgba(46, 46, 46, 0.5) !important`};
  margin-right: 16px;
`;
export const VitalsIcon = styled(RiHeartPulseFill)`
  font-size: 24px;
  color: ${({ isActive }) =>
    isActive
      ? `rgba(44, 123, 229, 1) !important`
      : `rgba(46, 46, 46, 0.5) !important`};
  margin-right: 16px;
`;
export const ChatIcon = styled(BsChatLeftTextFill)`
  font-size: 24px;
  color: ${({ isActive }) =>
    isActive
      ? `rgba(44, 123, 229, 1) !important`
      : `rgba(46, 46, 46, 0.5) !important`};
  margin-right: 16px;
`;
export const DocumentsIcon = styled(BsFileEarmarkTextFill)`
  font-size: 24px;
  color: ${({ isActive }) =>
    isActive
      ? `rgba(44, 123, 229, 1) !important`
      : `rgba(46, 46, 46, 0.5) !important`};
  margin-right: 16px;
`;
