import { createSlice } from "@reduxjs/toolkit";

export const SettingSlice = createSlice({
  name: "Setting",
  initialState: {
    Loading: false,
    PatGenderDetails: [],
    PatMariDetails: [],
    PatLangDetails: [],
    PatRaceDetails: [],
    PatEnrollment: [],
    PatientEnrollmentUpdate: "",
    PatientUpdateResponse: "",
    MailVerify: "",
    PhoneVerify: "",
    SSNVerify: "",
  },
  reducers: {
    GetPatGenderDetails: (state) => {
      return state;
    },
    SetPatGenderDetails: (state, action) => {
      state.PatGenderDetails = action.payload;
      state.Loading = false;
    },

    GetPatMaritalStatus: (state) => {
      return state;
    },
    SetPatMaritalStatus: (state, action) => {
      state.PatMariDetails = action.payload;
      state.Loading = false;
    },

    GetPatLangDetails: (state) => {
      state.Loading = true;
    },
    SetPatLangDetails: (state, action) => {
      state.PatLangDetails = action.payload;
    },

    GetPatRaceDetails: (state) => {
      return state;
    },
    SetPatRaceDetails: (state, action) => {
      state.PatRaceDetails = action.payload;
    },

    SetPatEnrollments: (state, action) => {
      state.PatEnrollment = action.payload;
    },
    GetPatEnrollment: (state) => {
      return state;
    },

    SetPatEnrollment: (state, action) => {
      state.PatientEnrollmentUpdate = action.payload;
    },
    GetPatEnrollmentUpdate: (state) => {
      return state;
    },

    setPatientUpdate: (state, action) => {
      state.PatientUpdateResponse = action.payload;
    },
    setEmptyPatUpdateResponse: (state) => {
      return state;
    },

    GetMailVerify: (state) => {
      state.Loading = true;
    },
    SetMailVerify: (state, action) => {
      state.MailVerify = action.payload;
      state.Loading = false;
    },
    GetPhoneVerify: (state) => {
      state.Loading = true;
    },
    SetPhoneVerify: (state, action) => {
      state.PhoneVerify = action.payload;
      state.Loading = false;
    },
    GetSSNVerify: (state) => {
      state.Loading = true;
    },
    SetSSNVerify: (state, action) => {
      state.SSNVerify = action.payload;
      state.Loading = false;
    },
  },
});

export const {
  GetPatGenderDetails,
  SetPatGenderDetails,
  GetPatMaritalStatus,
  SetPatMaritalStatus,
  GetPatLangDetails,
  SetPatLangDetails,
  GetPatRaceDetails,
  SetPatRaceDetails,
  SetPatEnrollment,
  GetPatEnrollment,
  SetPatEnrollmentUpdate,
  GetPatEnrollmentUpdate,
  setPatientUpdate,
  setEmptyPatUpdateResponse,
  GetSSNVerify,
  SetSSNVerify,
  GetMailVerify,
  SetMailVerify,
  MailVerify,
  GetPhoneVerify,
  SetPhoneVerify,
} = SettingSlice.actions;
export default SettingSlice.reducer;
