import styled from "styled-components";

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
`;

export const Tab = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;
  gap: 10px;
  width: ${(p) => (p.width ? p.width : "auto")};
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  font-family: "'Open Sans', sans-serif";

  &:not(:last-child) {
    border-right: none;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  & path {
    pointer-events: none;
  }
  ${({ active }) =>
    active ? "background : #2C7BE5;" : "background : #FFFFFF;"}
  border: 1px solid rgba(46, 46, 46, 0.25);
  ${({ active }) => (active ? "color : white;" : "color : #2E2E2E;")}
  ${({ alert }) =>
    alert
      ? `& svg{
      display : block;
      height : 20px;
      width : 20px;
      color : red;
    }`
      : `& svg{
      display : none;
    }`}
`;

export const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none;")}
  ${({ border }) =>
    border
      ? `border : ${border};`
      : "border : 1px solid rgba(46, 46, 46, 0.25);"}
  ${({ borderRadius }) =>
    borderRadius ? `border-radius : ${borderRadius};` : "border-radius : 8px"}
`;

export const SpanTag = styled.span`
  gap: ${(p) => p.gap && p.gap};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  position: ${(p) => p.position && p.position};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  background-position: ${(p) => p.backgroundPosition && p.backgroundPosition};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  margin: ${(p) => p.margin && p.margin};
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;
