import { all } from "redux-saga/effects";

import {
  LoginEmailCheck,
  LoginPassWordSet,
  Logo,
  PasswordResetEmailCheck,
  SignUp,
} from "./PreLogin/PreLoginWatchers";
import { LoginSaga } from "./Login/LoginSagaWatchers";
import {
  SinglePatientDetail,
  vitalsDemo,
  Devices,
  DashCardTeamDetails,
} from "./DashBoard/DashboardSagaWatchers";
import {
  ProviderListSaga,
  MessagerListSaga,
  MessageSendSaga,
  PreviewDownloadSagaM,
  UnreadCountUpdateSaga,
  StatusSaga,
} from "./Messenger/MessengerSagaWatchers";
import { ReadingChart } from "./Vitals/VitalsSagaWatchers";
import {
  PatientEnrollemt,
  PatGenderDetails,
  PatMaritalStatus,
  PatLangDetails,
  PatRaceDetails,
  PatientEnrollemtUpdate,
  EmptyingPatUpdateResponse,
  PhoneVerify,
  MailVerify,
  SSNVerify,
} from "./Settings/SettingsSagaWatchers";
import {
  GetInsuranceDetail,
  SingleInsuranceDetail,
  userImageUploadSaga,
} from "./Insurance/InsuranceSagaWatchers";
import {
  DocumentLists,
  InsertDocuments,
  EmptyInsertRespSaga,
  UpdateDocument,
  EmptyUpdateRespSaga,
  PreViewDocument,
  DeleteTempDocument,
  DownloadDocument,
  SignDocument,
  DeleteDocument,
} from "./Documents/DocumentsSagaWatchers";
import { LoginUpdateSaga } from "./TopNavbar/TopNavbarSagaWatchers";

export default function* rootSaga() {
  return yield all([
    // ---PreLogin----
    LoginEmailCheck(),
    LoginPassWordSet(),
    PasswordResetEmailCheck(),
    SignUp(),
    Logo(),
    //PreLogin
    // Login //
    LoginSaga(),
    LoginUpdateSaga(),
    // Login //
    //  Dashboard //
    SinglePatientDetail(),
    vitalsDemo(),
    Devices(),
    DashCardTeamDetails(),
    //  Dashboard //
    //Vitals
    ReadingChart(),
    //Vitals
    // Message //
    ProviderListSaga(),
    MessagerListSaga(),
    MessageSendSaga(),
    PreviewDownloadSagaM(),
    UnreadCountUpdateSaga(),
    StatusSaga(),
    // Message //

    // Settings //
    PatientEnrollemt(),
    PatGenderDetails(),
    PatMaritalStatus(),
    PatLangDetails(),
    PatRaceDetails(),
    PatientEnrollemtUpdate(),
    EmptyingPatUpdateResponse(),
    MailVerify(),
    PhoneVerify(),
    SSNVerify(),
    // Settings //
    // insurance//
    SingleInsuranceDetail(),
    GetInsuranceDetail(),
    userImageUploadSaga(),
    // insurance//

    //Documents//
    DocumentLists(),
    InsertDocuments(),
    EmptyInsertRespSaga(),
    UpdateDocument(),
    EmptyUpdateRespSaga(),
    PreViewDocument(),
    DeleteTempDocument(),
    DownloadDocument(),
    SignDocument(),
    DeleteDocument(),
    //Documents//
  ]);
}
