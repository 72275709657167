import React, { useEffect } from "react";
import {
  Button,
  Div,
  DropDownDiv,
  DropdownImage,
  DropdownOptions,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Input,
  RedStar,
  SelectInput,
  Span,
} from "../StyledComponents";
import { ErrorMessage, Hrline, NormalImage, Text } from "./styles";
import { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  postSingleInsDetail,
  GetSingleInsDetail,
  DeviceResponse,
  userImageUpload,
} from "../../StateManagement/Reducers/InsuranceState";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import Myprofileediticon from "../../Assets/images/myprofileediticon.png";
import UploadProfilePicture from "./UploadProfilePicture";
import DropdownDownicon from "../../Assets/images/dropdownDownicon.png";
import { useNavigate } from "react-router";
import { useRef } from "react";
import { Spinner, SpinnerWrapper } from "../Vitals/styles";
import Logo from "../../Assets/images/Logo.png";

const Insurance = () => {
  //--------------------------State and  Variables Start----------------------------------------------- //

  const [Insurance, setInsurance] = useState("Primary insurance");
  const [insuranceType, setInsuranceType] = useState("primary ");
  const [InsuranceName, setInsuranceName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [planId, setPlanId] = useState("");
  const [payerPhone, setPayerPhone] = useState("");
  const [payerFax, setPayerFax] = useState("");
  const [ShowOptions3, setShowOptions3] = useState(false);
  const [insuranceError, setInsuranceError] = useState(false);
  const [insuranceProviderError, setInsuranceProviderError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [previewimg1, setpreviewimg1] = useState("");
  const [isupload, setIsUpload] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [imageText, setImageText] = useState("add file");
  const [disabledsave, setdisabledsave] = useState(false);
  const dispatch = useDispatch();
  const insuredType = [
    "Primary insurance",
    "Secondary insurance",
    "Tertiary insurance",
  ];
  const navigate = useNavigate();
  const pid =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

  const File_URL =
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documenting/";

  const Loading = useSelector((state) => state.Insurance.Loading);
  const addDeviceResponse = useSelector((state) => state.Insurance.DeviceRes);
  const SingPatDetails = useSelector((state) => state.Dashboard.SinglePatient);
  const vitalsRef = useRef(null);

  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start--------------------------------------------------------- //
  const setInsuranceSelect = (r) => {
    setInsurance(r);
    setInsuranceError(false);
    setShowOptions3(false);
    const ins =
      r.split(" ")[0].charAt(0).toLowerCase() + r.split(" ")[0].slice(1);
    setInsuranceType(ins);
  };
  const insuranceDetails = useSelector(
    (state) => state.Insurance.insuranceDetails
  );

  const handlechange = (e) => {
    if (e.target.name === "insuranceName") {
      if (e.target.value.trim() === "") {
        setInsuranceProviderError(true);
      } else {
        setInsuranceProviderError(false);
      }
      setInsuranceName(e.target.value);
    } else if (e.target.name === "GroupId") {
      if (e.target.value.trim() === "") {
        setGroupError(true);
      } else {
        setGroupError(false);
      }
      setGroupId(e.target.value);
    } else if (e.target.name === "PlanId") {
      if (e.target.value.trim() === "") {
        setPlanError(true);
      } else {
        setPlanError(false);
      }
      setPlanId(e.target.value);
    } else if (e.target.name === "PayerPhone") {
      if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
        setPayerPhone(e.target.value);
      }
    } else if (e.target.name === "PayerFax") {
      if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
        setPayerFax(e.target.value);
      }
    }
  };

  const HandleClose = () => {
    navigate("/dashboard/" + pid);
  };

  const handleSave = (e) => {
    e.preventDefault();
    let err = 0;
    if (Insurance === "") {
      setInsuranceError(true);
      err = 2;
    }

    if (InsuranceName === "") {
      setInsuranceProviderError(true);
      err = 2;
    }
    if (groupId === "") {
      setGroupError(true);
      err = 2;
    }
    if (planId === "") {
      setPlanError(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    }
    setdisabledsave(true);
    dispatch(
      postSingleInsDetail({
        pid: pid,
        insuranceType: insuranceType,
        InsuranceName: InsuranceName,
        groupId: groupId,
        planId: planId,
        payerPhone: payerPhone,
        payerFax: payerFax,
      })
    );
    setInsuranceName("");
    setGroupId("");
    setPayerPhone("");
    setPayerFax("");
    setPlanId("");

    if (imageFile != "") {
      const filedata = new FormData();
      filedata.append("imgfile", imageFile);
      dispatch(userImageUpload(filedata));
      setImageFile("");
      setpreviewimg1("");
    }
  };
  //-----------------------------Function End--------------------------------------------------------- //

  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (vitalsRef.current && !vitalsRef.current.contains(event.target)) {
        if (ShowOptions3 === true) {
          setShowOptions3(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ShowOptions3]);

  useEffect(() => {
    dispatch(
      GetSingleInsDetail({
        pid: pid,
        insuranceType: insuranceType,
      })
    );
  }, [insuranceType]);

  useEffect(() => {
    if (insuranceDetails.length > 0) {
      setInsuranceName(insuranceDetails[0].provider);
      setGroupId(insuranceDetails[0].group_number);
      setPlanId(insuranceDetails[0].plan_name);
      setPayerPhone(insuranceDetails[0].payer_phone);
      setPayerFax(insuranceDetails[0].payer_fax);
      setInsuranceError(false);
      setGroupError(false);
      setPlanError(false);
      setInsuranceProviderError(false);
    } else {
      setInsuranceName("");
      setGroupId("");
      setPayerPhone("");
      setPayerFax("");
      setPlanId("");
    }
  }, [insuranceDetails]);

  useEffect(() => {
    if (addDeviceResponse !== "") {
      setdisabledsave(false);
      if (addDeviceResponse === "Details added sucessfully") {
        setModalAlerShow(true);
        setModalMsg("Details added sucessfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);
        dispatch(DeviceResponse(""));
      }
    }
    if (addDeviceResponse !== "") {
      setdisabledsave(false);
      if (addDeviceResponse === "Unable to add Details") {
        setModalAlerShow(true);
        setModalMsg("Unable to add Details");
        setStatus("retry");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);
        dispatch(DeviceResponse(""));
      }
    }
  }, [addDeviceResponse]);

  //-----------------------------UseEffect End---------------------------------------------- //

  return Loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div display="flex" alignItems="center" margin="25px 0px 0px 40px">
        <Div
          height="70px"
          width="70px"
          borderRadius="50%"
          display="flex"
          marginRight="17px"
          fontSize="60px"
          fontWeight="600"
        >
          {(SingPatDetails.img_url !== undefined &&
            SingPatDetails.img_url !== null &&
            SingPatDetails.img_url !== "") ||
          previewimg1 !== "" ? (
            <Image
              height="70px"
              width="70px"
              borderRadius="50%"
              src={
                previewimg1 === ""
                  ? File_URL + SingPatDetails.img_url
                  : previewimg1
              }
            />
          ) : (
            <Div
              height="70px"
              width="70px"
              borderRadius="50%"
              background="rgb(168, 174, 190)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              fontFamily="Open Sans, sans-serif"
              color="#FFFFFF"
              position="relative"
            >
              <Span top="18px" fontSize="40px">
                {SingPatDetails.patname.charAt(0).toUpperCase()}
              </Span>
            </Div>
          )}

          <Image
            marginTop="45px"
            position="absolute"
            height="17px"
            width="17px"
            cursor="pointer"
            marginLeft="52px"
            onClick={() => {
              setIsUpload(true);
            }}
            src={Myprofileediticon}
          ></Image>
        </Div>

        <Div display="grid">
          <FormLabel
            fontSize="24px"
            color="#2E2E2E"
            lineHeight="33px"
            fontWeight="600"
            marginTop="7px"
          >
            {SingPatDetails.patname}
          </FormLabel>
          <FormLabel
            fontSize="14px"
            color="#2E2E2E"
            fontWeight="400"
            marginTop="3px"
          >
            Set up your Patient Portal
          </FormLabel>
        </Div>
      </Div>

      <Form>
        <Div margin="0px 0px 0px 45px">
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Type <RedStar>*</RedStar>
            </FormLabel>

            <Div
              position="relative"
              onClick={(e) => {
                setShowOptions3(!ShowOptions3);
              }}
              ref={vitalsRef}
            >
              <SelectInput
                className="detailinput"
                placeholder=""
                borderRadius="4px"
                height="34px"
                background="#FFFFFF"
                fontSize="14px"
                name="insurancetype"
                fontFamily="'Open Sans',sans-serif"
                border={
                  !insuranceError
                    ? "1px solid rgba(46, 46, 46, 0.25)"
                    : "1px solid red"
                }
                padding="6px 12px 0px 5px"
                color="rgba(46, 46, 46, 1)"
                onClick={(e) => {
                  // e.stopPropagation();
                  setShowOptions3(!ShowOptions3);
                }}
              >
                {Insurance}
              </SelectInput>
              <DropdownImage
                height="8px"
                width="12px"
                position="absolute"
                top="42px"
                right="10px"
                marginTop="-28px"
                src={DropdownDownicon}
              ></DropdownImage>
              {ShowOptions3 && insuredType.length > 0 ? (
                <DropDownDiv
                  margin="0 0 0 0"
                  width="100%"
                  background="#FFFFFF"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="3.13433px"
                  maxHeight="146px"
                  overflowY="auto"
                  overflowX="hidden"
                  // marginTop="64px"
                >
                  {insuredType.map((r, i) => {
                    return (
                      <DropdownOptions
                        key={i}
                        value={r}
                        padding="7px 12px 7px 12px"
                        onClick={() => setInsuranceSelect(r)}
                        fontSize="14px"
                        color="rgba(46, 46, 46, 1)"
                      >
                        {r}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Div>
            {insuranceError && (
              <ErrorMessage>Please enter your insurance type .</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Provider Name <RedStar>*</RedStar>
            </FormLabel>
            <Input
              height="34px"
              backgroundColor="#ffffff"
              border={
                !insuranceProviderError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              borderRadius="4px"
              padding="0 0 0 12px"
              name="insuranceName"
              value={InsuranceName}
              onChange={handlechange}
            />
            {insuranceProviderError && (
              <ErrorMessage>Please enter Insurance Provider Name.</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup display="grid" width="322px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Group ID <RedStar>*</RedStar>
            </FormLabel>
            <Input
              name="GroupId"
              height="34px"
              backgroundColor="#ffffff"
              border={
                !groupError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              borderRadius="4px"
              padding="0 0 0 12px"
              value={groupId}
              onChange={handlechange}
            />
            {groupError && <ErrorMessage>Please enter Group Id.</ErrorMessage>}
          </FormGroup>

          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginTop="24px"
              marginBottom="8px"
              textAlign="left"
            >
              Plan ID <RedStar>*</RedStar>
            </FormLabel>
            <Input
              name="PlanId"
              height="34px"
              backgroundColor="#ffffff"
              border={
                !planError
                  ? "1px solid rgba(46, 46, 46, 0.25)"
                  : "1px solid red"
              }
              borderRadius="4px"
              padding="0 0 0 12px"
              value={planId}
              onChange={handlechange}
            />
            {planError && <ErrorMessage>Please enter plan Id.</ErrorMessage>}
          </FormGroup>

          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Payer Phone
            </FormLabel>
            <Input
              name="PayerPhone"
              height="34px"
              backgroundColor="#ffffff"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              value={payerPhone}
              onChange={handlechange}
            />
          </FormGroup>
          <FormGroup display="grid" width="322px" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
              marginTop="24px"
            >
              Insurance Payer Fax
            </FormLabel>
            <Input
              name="PayerFax"
              height="34px"
              backgroundColor="#ffffff"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              padding="0 0 0 12px"
              value={payerFax}
              onChange={handlechange}
            />
          </FormGroup>
        </Div>

        <Hrline ml="-40px" mb="23px" />

        <Div display="flex" marginLeft="45px" marginBottom="31px">
          <Button
            padding="8px 16px"
            height="34px"
            background="#fff"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            color="#2C7BE5"
            marginRight="20px"
            border="1px solid #2C7BE5"
            cursor="pointer"
            onClick={HandleClose}
            className="close_active"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
          >
            Cancel
          </Button>
          <Button
            padding="7px 32px 9px 32px"
            height="34px"
            background="#2C7BE5"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            color="#ffffff"
            border="none"
            cursor="pointer"
            onClick={handleSave}
            disabled={disabledsave}
            hoverBackColor="#005FB2"
            className="save_active"
          >
            Save
          </Button>
        </Div>
      </Form>
      <UploadProfilePicture
        show={isupload}
        close={() => setIsUpload(false)}
        setImageText={setImageText}
        setpreviewimg1={setpreviewimg1}
        setImageFile={setImageFile}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default Insurance;
