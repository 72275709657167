import React from "react";
import { useLocation } from "react-router-dom";

import {
  Vozoiconpic,
  Navicon,
  StyledLink,
  Hrline,
  LogoContainer,
  Nav,
  Ul,
  Li,
  DashboardIcon,
  VitalsIcon,
  ChatIcon,
  DocumentsIcon,
} from "./Styles";

const LeftNav = () => {
  const { pathname } = useLocation();
  return (
    <>
      <LogoContainer>
        <Vozoiconpic />
      </LogoContainer>
      <Hrline />
      <Nav>
        <Ul>
          <Li isActive={pathname === "/dashboard" || pathname === "/"}>
            {pathname === "/dashboard" ? (
              <StyledLink to="/dashboard" exact="true">
                <DashboardIcon isActive={pathname === "/dashboard"} />
                <Navicon>Dashboard</Navicon>
              </StyledLink>
            ) : (
              <StyledLink to="/" exact="true">
                <DashboardIcon isActive={pathname === "/"} />
                <Navicon>Dashboard</Navicon>
              </StyledLink>
            )}
          </Li>
          <Li isActive={pathname === "/vitals"}>
            <StyledLink to="/vitals" exact="true">
              <VitalsIcon isActive={pathname === "/vitals"} />
              <Navicon>Vitals</Navicon>
            </StyledLink>
          </Li>
          <Li isActive={pathname === "/messenger"}>
            <StyledLink to="/messenger" exact="true">
              <ChatIcon isActive={pathname === "/messenger"} />
              <Navicon>Chats</Navicon>
            </StyledLink>
          </Li>
          <Li isActive={pathname === "/documents"}>
            <StyledLink to="/documents" exact="true">
              <DocumentsIcon isActive={pathname === "/documents"} />
              <Navicon>Documents</Navicon>
            </StyledLink>
          </Li>
        </Ul>
      </Nav>
    </>
  );
};

export default LeftNav;
