import styled from "styled-components";
import { Div, Text } from "../../StyledComponents/index";
export const UserContainer = styled.div`
  margin-top: 14px;
  overflow-y: scroll;
  height: calc(100% - 123px);
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const UserContent = styled.div`
  display: flex;
  padding: 12px 12.36px 14px 20px;
  padding: ${({ active }) =>
    active ? "12px 12.36px 14px 16px" : "12px 12.36px 14px 20px"};
  height: 70px;
  border-left: ${({ active }) => active && "4px solid #407bff"};
  background: ${({ active }) => active && "rgba(64, 123, 255, 0.1)"};
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0px;
  &:hover {
    background: rgba(64, 123, 255, 0.1);
  }
`;

export const ChatSession = styled.div`
  width: 100%;
  background: #f4f4f4;
  ${({ empty }) =>
    empty &&
    `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `}
`;
export const ChatDiv = styled(Div)`
  &.providerwidth {
    width: 60%;
    @media screen and (max-width: 1060px) {
      width: 55%;
    }
    @media screen and (max-width: 940px) {
      width: 48%;
    }
    @media screen and (min-width: 800px) and (max-width: 820px) {
      width: 60%;
    }
  }
  &.datewidth {
    width: 30%;
    @media screen and (max-width: 1060px) {
      width: 40%;
    }
    @media screen and (max-width: 940px) {
      width: 50%;
    }
  }
`;
export const ChatText = styled(Text)`
  &.datetimemessage {
    font-size: 12px;
    @media screen and (max-width: 940px) {
      font-size: 11px;
    }
    @media screen and (max-width: 820px) {
      font-size: 10px;
    }
  }
`;

export const Div1 = styled(Div)`
  &.providerlistres {
    @media screen and (min-width: 800px) and (max-width: 820px) {
      padding: 0px 16px 0px 0px;
      width: 45%;
    }
  }
`;
