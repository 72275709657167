import React from "react";
import { Div, Button, Span, Image } from "../StyledComponents";
import { Arrow, TextVitals, VitalsDiv1 } from "./Styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Emp_data_pic from "../../Assets/images/No_Data_Img.png";
import meterimage from "../../Assets/images/svg/image/meterimg.png";
import pressureimage from "../../Assets/images/svg/image/pressureimage.png";
import pulseimage from "../../Assets/images/svg/image/pulseimage.png";
import heartimage from "../../Assets/images/svg/image/heartimage.png";
import weightimage from "../../Assets/images/svg/image/weightimage.png";

const DeviceAssigned = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const devicesList = useSelector((state) => state.Dashboard.DeviceList);
  // ----------------------------State and Variables ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//
  const navigate = useNavigate();
  const pageToVitals = () => {
    navigate(`/vitals`);
  };
  const bpDevices = devicesList.Device_model
    ? devicesList.Device_model.filter((device) => device.value.startsWith("BP"))
    : [];

  const hrDevices = devicesList.Device_model
    ? devicesList.Device_model.filter((device) => device.value.startsWith("HR"))
    : [];

  const CgDevices = devicesList.Device_model
    ? devicesList.Device_model.filter((device) => device.value.startsWith("CG"))
    : [];

  const WsDevices = devicesList.Device_model
    ? devicesList.Device_model.filter((device) => device.value.startsWith("WS"))
    : [];

  const PoDevices = devicesList.Device_model
    ? devicesList.Device_model.filter((device) => device.value.startsWith("PO"))
    : [];

  const DeviceContainer = ({ iconComponent, title, description }) => (
    <Div>
      <VitalsDiv1 display="flex" className="devicepadding">
        {iconComponent}

        <Div>
          <TextVitals
            fontFamily="'Open Sans', sans-serif"
            fontWeight="400"
            className="caretexttitle"
            color="#2E2E2E"
            fontStyle="normal"
            lineHeight="normal"
            marginLeft="12px"
          >
            {title}
          </TextVitals>
          <VitalsDiv1
            fontFamily="'Open Sans', sans-serif"
            fontWeight="400"
            className="devicetexttitle"
            color="rgba(46, 46, 46, 0.75);"
            fontStyle="normal"
            lineHeight="20px"
            paddingTop="6px"
            marginLeft="12px"
          >
            {description}
          </VitalsDiv1>
        </Div>
      </VitalsDiv1>
    </Div>
  );

  const showContainerVal = () => {
    const displayedContainers = [];

    if (bpDevices.length > 0 && displayedContainers.length < 3) {
      displayedContainers.push(
        <DeviceContainer
          key="bloodPressure"
          iconComponent={
            <Image
              src={pressureimage}
              style={{ height: "48px", width: "48px" }}
            ></Image>
          }
          title="Blood Pressure Monitor"
          description="Take the device reading after the consumption of the food"
        />
      );
    }

    if (hrDevices.length > 0 && displayedContainers.length < 3) {
      displayedContainers.push(
        <DeviceContainer
          key="heartRate"
          iconComponent={
            <Image
              src={heartimage}
              style={{ height: "48px", width: "48px" }}
            ></Image>
          }
          title="HeartRate Monitor"
          description="Take the device reading in heart."
        />
      );
    }

    if (CgDevices.length > 0 && displayedContainers.length < 3) {
      displayedContainers.push(
        <DeviceContainer
          iconComponent={
            <Image
              src={meterimage}
              style={{ height: "48px", width: "48px" }}
            ></Image>
          }
          title="Blood Glucose Monitor"
          description="Take the glucose level reading before, after meal & after 2-3 hrs of meal"
        />
      );
    }

    if (PoDevices.length > 0 && displayedContainers.length < 3) {
      displayedContainers.push(
        <DeviceContainer
          key="pulseOximeter"
          iconComponent={
            <Image
              src={pulseimage}
              style={{ height: "48px", width: "48px" }}
            ></Image>
          }
          title="Pulse Oximeter"
          description="Take the device reading while in rest or after a small workout."
        />
      );
    }
    if (WsDevices.length > 0 && displayedContainers.length < 3) {
      displayedContainers.push(
        <DeviceContainer
          key="weight"
          iconComponent={
            <Image
              src={weightimage}
              style={{ height: "48px", width: "48px" }}
            ></Image>
          }
          title="Weight"
          description="Take the device reading after certain date."
        />
      );
    }

    return displayedContainers;
  };

  // -------------------------- Functions End-------------------------------------------------------------//
  return (
    <Div
      border=" 1px solid rgba(46, 46, 46, 0.25)"
      background=" #FFFFFF"
      width="52%"
      height="320px"
      borderRadius="8px"
      position="relative"
    >
      <Div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="16px 16px 20px 20px"
        borderBottom=" 1px solid rgba(46, 46, 46, 0.25)"
        fontFamily="'Open Sans', sans-serif"
        fontWeight="600"
        fontSize="18px"
        color=" #2E2E2E"
        lineHeight="normal"
        fontStyle="normal"
      >
        Device Assigned
        <Button
          border="none"
          width="109px"
          height="33px"
          borderRadius="90px"
          background="#F1F5F8"
          fontFamily="'Open Sans', sans-serif"
          fontWeight="400"
          fontSize="14px"
          color=" #2C7BE5"
          lineHeight="normal"
          cursor="pointer"
          onClick={pageToVitals}
        >
          <Div display="flex" marginLeft="20px" marginTop="5px">
            <Span> View All</Span>

            <Arrow cursor="pointer"></Arrow>
          </Div>
        </Button>
      </Div>

      {WsDevices.length === 0 &&
      PoDevices.length === 0 &&
      CgDevices.length === 0 &&
      hrDevices.length === 0 &&
      bpDevices.length === 0 ? (
        <Div
          height="calc(100% - 70px)"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Image height="100px" width="100px" src={Emp_data_pic} />
          <Div
            fontSize="14px"
            color="#2E2E2E"
            fontFamily="'Open Sans',sans-serif"
            lineHeight="20px"
            fontWeight="400"
          >
            No devices has been added to show
          </Div>
        </Div>
      ) : (
        <VitalsDiv1 className="devicewholepadding">
          {showContainerVal()}
        </VitalsDiv1>
      )}
    </Div>
  );
};

export default DeviceAssigned;
