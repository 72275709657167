import { createSlice } from "@reduxjs/toolkit";

export const DocumentsSlice = createSlice({
  name: "Document",
  initialState: {
    Loading: false,
    LoadingDocs: false,
    Documentlist: [],
    DocumentInsert: "",
    DocUpdate: "",
    deletetempdoc: "",
    previewdownload: "",
    DownloadDocumentlist: "",
    DeleteDocumentlist: "",
    SignInsert: "",
  },
  reducers: {
    getDocumentslist: (state) => {
      state.Refresh = "";
    },
    setDocumentslist: (state, action) => {
      state.Documentlist = action.payload;
      state.LoadingDocs = false;
    },
    getInsertDocumentslist: (fileslist) => {
      return fileslist;
    },
    setInserDocumentlist: (state, action) => {
      state.DocumentInsert = action.payload;
    },

    getsavesignature: (signaturedata) => {
      return signaturedata;
    },

    setSavesignature: (state, action) => {
      state.SignInsert = action.payload;
      state.Loading = false;
    },
    setInsertEmptyResp: (state) => {
      return state;
    },

    getUpdateDocumentslist: (id) => {
      return id;
    },
    setUpdateDocumentslist: (state, action) => {
      state.DocUpdate = action.payload;
    },
    setUpdateDocEmptyResp: (state, action) => {
      return state;
    },

    getPreviewDownload: (filename) => {
      return filename;
    },
    setPreviewDownload: (state, action) => {
      state.previewdownload = action.payload;
      state.Loading = false;
    },

    getPreviewDocument: (ids) => {
      return ids;
    },

    setPreviewDocument: (state, action) => {
      state.DownloadDocumentlist = action.payload;
      state.Loading = false;
    },
    getDeleteTempDoc: (filename) => {
      return filename;
    },
    setDeleteTempDoc: (state, action) => {
      state.deletetempdoc = action.payload;
      state.previewdownload = "";
      state.DownloadDocumentlist = "";
      state.Loading = false;
    },

    getDeleteDocumentslist: (ids) => {
      return ids;
    },
    setDeleteDocumentslist: (state, action) => {
      state.DeleteDocumentlist = action.payload;
      state.Loading = false;
    },
    setDocsLoad: (state) => {
      state.LoadingDocs = true;
    },
  },
});

export const {
  getDocumentslist,
  setDocumentslist,
  getInsertDocumentslist,
  getsavesignature,
  setInserDocumentlist,
  setInsertEmptyResp,
  getUpdateDocumentslist,
  setUpdateDocumentslist,
  setUpdateDocEmptyResp,
  getPreviewDocument,
  setPreviewDocument,
  getDeleteTempDoc,
  setDeleteTempDoc,
  getPreviewDownload,
  setPreviewDownload,
  getDeleteDocumentslist,
  setDeleteDocumentslist,
  setSavesignature,
  setDocsLoad,
} = DocumentsSlice.actions;

export default DocumentsSlice.reducer;
