import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { Div } from "../StyledComponents";

export const FixedTop = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems && alignItems};
  justify-content: space-between;
  padding-right: 4px;
  height: ${({ height }) => height && height};
  transition: box-shadow 0.2s ease-in-out;
  padding: ${({ padding }) => padding && padding};
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  ${(p) =>
    p.DualColor &&
    `background: linear-gradient(to right, #fff 0px, #fff 37%, #f4f4f4 37%, #f4f4f4 100%);`}
  ${(p) =>
    p.scrolled &&
    ` 
      background : #FFF;
      box-shadow: 2px 4px 4px rgb(0 0 0 / 30%);
      `}
      min-width : ${(p) => p.minWidth && p.minWidth}
`;

export const MessageContainer = styled(Div)`
  border-radius: 50%;
  background: ${(p) => (p.DualColor ? "#fff" : "#fafafa")};
  width: 40px;
  height: 40px;
  margin-right: 21px;
  position: relative;
  padding-left: 8px;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin && margin};
  &:after {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ff4d4d;
  }
`;

export const ProfileBox = styled.div`
  background: ${(p) => (p.DualColor ? "#fff" : "#fafafa")};
  border-radius: 90px;
  padding: 0px 16px 0px 8px;
  display: flex;
  align-items: center;
  height: 40px;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #232934;
  position: relative;
  cursor: pointer;
  & img {
    margin-right: 8px;
  }
  & svg {
    margin-left: 12px;
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 46px;
  width: 98%;
  left: 1%;
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  animation: fadeIn 0.3s;
  display: ${(p) => (p.display === "true" ? "block" : "none")};
  overflow: hidden;

  & div:first-of-type svg {
    color: #667085;
  }
  & div svg {
    margin-right: 8px;
  }
  & div:nth-of-type(2) svg {
    color: #ff4d4d;
  }

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }
`;

export const DropdownList = styled.div`
  padding: ${({ padding }) => padding && padding};
  color: rgba(46, 46, 46, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  transition-duration: 0.3s;
  &:hover {
    background: #ececec;
  }
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  display: flex;
  align-items: center;
`;

export const MyProfileIcon = styled(CgProfile)`
  font-size: 24px;
  margin-left: 0px !important;
  color: #2e2e2e !important;
`;

export const LogoutIcon = styled(FiLogOut)`
  font-size: 24px;
  margin-left: 0px !important;
  color: #f24822 !important;
`;
export const RedNotify = styled.div`
  position: absolute;
  top: 5px;
  right: -1px;
  width: 8px;
  height: 8px;
  background: #ff4d4d;
  border-radius: 50%;
`;
