import React, { useState, useEffect } from "react";
import { Div, Span, Image } from "../StyledComponents";
import { TextVitals, VitalsDiv1 } from "./Styles";
import { BsChatLeftText } from "react-icons/bs";
import { IoMdCall, IoMdVideocam } from "react-icons/io";
import Emp_data_pic from "../../Assets/images/No_Data_Img.png";
import { useDispatch, useSelector } from "react-redux";
import { GetCareTeamDetails } from "../../StateManagement/Reducers/DashboardState";

const Careteam = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const careData = useSelector((state) => state.Dashboard.CareTeamDetails);
  const dispatch = useDispatch();

  let imageUrl =
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/images/";

  // ----------------------------State and Variables ---------------------------------------------------///

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    dispatch(GetCareTeamDetails());
  }, []);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  const CareContainer = ({ initial, title, description, iconComponent }) => (
    <Div>
      <VitalsDiv1
        display="flex"
        paddingBottom="24px"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Div display="flex" marginRight="10px">
          <Div
            height="48px"
            width="48px"
            borderRadius="50%"
            display="flex"
            marginRight="12px"
            fontSize="60px"
            fontWeight="600"
          >
            {iconComponent === null ? (
              <VitalsDiv1
                className="carenameimage"
                width="200px"
                borderRadius="50%"
                background="rgb(168, 174, 190)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                fontFamily="Open Sans, sans-serif"
                color="#FFFFFF"
                position="relative"
              >
                <Span position="absolute" top="8px" left="15px" fontSize="30px">
                  {initial && initial.charAt(3).toUpperCase()}
                </Span>
              </VitalsDiv1>
            ) : (
              <Image
                height="48px"
                width="48px"
                borderRadius="50%"
                src={imageUrl + iconComponent}
              ></Image>
            )}
          </Div>

          <VitalsDiv1>
            <TextVitals
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              color=" #2E2E2E"
              fontStyle="normal"
              lineHeight="normal"
              marginTop="2px"
              className="caretexttitle"
            >
              <div
                style={{
                  maxWidth: "177px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                }}
              >
                {title}
              </div>
            </TextVitals>
            <VitalsDiv1
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              className="devicetexttitle"
              color="rgba(46, 46, 46, 0.50);"
              fontStyle="normal"
              lineHeight="normal"
              paddingTop="6px"
            >
              {description}
            </VitalsDiv1>
          </VitalsDiv1>
        </Div>
        <VitalsDiv1 className="careimagediv" display="flex">
          <BsChatLeftText />
          <IoMdCall />
          <IoMdVideocam />
        </VitalsDiv1>
      </VitalsDiv1>
    </Div>
  );
  // -------------------------- Functions End-------------------------------------------------------------//
  return (
    <Div
      marginLeft="24px"
      border=" 1px solid rgba(46, 46, 46, 0.25)"
      background=" #FFFFFF"
      width="49%"
      height="320px"
      borderRadius="8px"
      position="relative"
    >
      <Div
        padding="20px 20px 24px 20px"
        borderBottom=" 1px solid rgba(46, 46, 46, 0.25)"
        fontFamily="'Open Sans', sans-serif"
        fontWeight="600"
        fontSize="18px"
        color=" #2E2E2E"
        lineHeight="normal"
        fontStyle="normal"
        height="69px"
      >
        My Care Team
      </Div>

      {careData.length === 0 ? (
        <Div
          height="calc(100% - 69px)"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Image height="100px" width="100px" src={Emp_data_pic} />
          <Div
            fontSize="14px"
            color="#2E2E2E"
            fontFamily="'Open Sans',sans-serif"
            lineHeight="20px"
            fontWeight="400"
          >
            No care team info has been shared to view
          </Div>
        </Div>
      ) : (
        <VitalsDiv1
          className="carewholepadding"
          style={{ overflowY: "auto", maxHeight: "249px" }}
        >
          {careData.map((careItem) => (
            <Div key={careItem.role_name}>
              <CareContainer
                key={careItem.role_name}
                iconComponent={careItem.Provider_Image}
                title={careItem.Provider_name}
                initial={careItem.Provider_name}
                description={careItem.role_name}
              />
            </Div>
          ))}
        </VitalsDiv1>
      )}
    </Div>
  );
};

export default Careteam;
