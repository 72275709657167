import { takeLatest } from "redux-saga/effects";

import {
  GetEmailRes,
  GetReEmailRes,
  GetPassRes,
  GetSignUp,
  GetLogo,
  GetPatientUrl,
} from "../../StateManagement/Reducers/PreLoginState";

import {
  LoginEmailCheckApi,
  LoginPasswordSetApi,
  LogoApi,
  PasswordResetEmailCheckApi,
  SignUpApi,
} from "./PreLoginWorkers";

export function* LoginEmailCheck() {
  yield takeLatest(GetEmailRes.type, LoginEmailCheckApi);
}

export function* LoginPassWordSet() {
  yield takeLatest(GetPassRes.type, LoginPasswordSetApi);
}

export function* PasswordResetEmailCheck() {
  yield takeLatest(GetReEmailRes.type, PasswordResetEmailCheckApi);
}

export function* SignUp() {
  yield takeLatest(GetSignUp.type, SignUpApi);
}

export function* Logo() {
  yield takeLatest(GetLogo.type, LogoApi);
  yield takeLatest(GetPatientUrl.type, LogoApi);
}
