import { call, put } from "redux-saga/effects";
import {
  SetGetMsgList,
  setMessageList,
  setPreviewDownload,
  SetStatus,
} from "../../StateManagement/Reducers/MessengerState";

import {
  MessageListApi,
  MessageSendApi,
  PreviewDownloadApi_1,
  ProviderListApi,
  UnreadCountUpdateApi,
  StatusApi,
} from "../Apis/MessengerApi";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

export function* ProviderList() {
  try {
    const res = yield call(ProviderListApi);
    if (res.status === 200) {
      let Decrypted_data = Decrypt_Value(res.data, "rpmportal");
      yield put(SetGetMsgList(Decrypted_data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetGetMsgList([]));
  }
}

export function* MessageList({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "rpmportal");
  try {
    const res = yield call(MessageListApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "rpmportal");
      yield put(setMessageList(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setMessageList([]));
  }
}

export function* MessageSend({ payload }) {
  const credential = payload;
  try {
    const res = yield call(MessageSendApi, credential);
    if (res.status === 200) {
      let dec = credential.get("message");
      let Decr = Decrypt_Value(dec, "rpmportal");
      const getData = {
        Pid: Decr.sender_id,
        Provider_Id: Decr.receive_id,
      };
      let ecrypted = Encrypt_Value(getData, "rpmportal");
      const resL = yield call(MessageListApi, ecrypted);
      if (resL.status === 200) {
        let Decrypted_data = Decrypt_Value(resL.data, "rpmportal");
        yield put(setMessageList(Decrypted_data));
        const resP = yield call(ProviderListApi);
        if (resP.status === 200) {
          let Decrypted_data = Decrypt_Value(resP.data, "rpmportal");
          yield put(SetGetMsgList(Decrypted_data));
        }
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PreviewDownloadApi({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozorpm");
  try {
    const res = yield call(PreviewDownloadApi_1, credential);
    console.log(res.data);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozorpm");
      yield put(setPreviewDownload(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* UnreadCountUpdate({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "rpmportal");
  try {
    const res = yield call(UnreadCountUpdateApi, credential);
    if (res.status === 200) {
      try {
        const res = yield call(ProviderListApi);
        if (res.status === 200) {
          let Decrypted_data = Decrypt_Value(res.data, "rpmportal");
          yield put(SetGetMsgList(Decrypted_data));
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* ProviderStatus({ payload }) {
  try {
    const res = yield call(StatusApi, payload);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "rpmportal");
      yield put(SetStatus(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetStatus(""));
  }
}
