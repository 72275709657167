import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  setDocumentslist,
  setInserDocumentlist,
  setUpdateDocumentslist,
  setPreviewDocument,
  setDeleteTempDoc,
  setPreviewDownload,
  setDeleteDocumentslist,
  setSavesignature,
} from "../../StateManagement/Reducers/DocumentsState";
import {
  DocumentList_Api,
  InsertDocuments_Api,
  UpdateDocument_Api,
  PreViewDocument_Api,
  DeleteTempDocument_Api,
  DownloadDocument_Api,
  SignDocument_Api,
  DeleteDocument_Api,
} from "../../MiddleWare/Apis/DocumentsApi";

export function* DocumentListApi({ payload }) {
  const data = payload;
  let Doc_type = Encrypt_Value(data, "rpmportal");
  try {
    const res = yield call(DocumentList_Api, Doc_type);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "rpmportal");
      yield put(setDocumentslist(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setDocumentslist([]));
  }
}

export function* InsertDocumentsApi({ payload }) {
  const File_data = payload;

  try {
    const res = yield call(InsertDocuments_Api, File_data);
    if (res.status === 200) {
      yield put(setInserDocumentlist(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setInserDocumentlist(""));
  }
}

export function* setInsertEmptyWorker() {
  yield put(setInserDocumentlist(""));
}

export function* setUpdateDocEmptyWorker() {
  yield put(setUpdateDocumentslist(""));
  yield put(setDeleteDocumentslist(""));
  yield put(setSavesignature(""));
}

export function* UpdateDocumentApi({ payload }) {
  const data = payload;
  let row_Id = Encrypt_Value(data, "rpmportal");
  try {
    const res = yield call(UpdateDocument_Api, row_Id);
    if (res.status === 200) {
      if (res.data === "success") {
        yield put(setUpdateDocumentslist(res.data));
      }
    }
  } catch (e) {
    console.log(e.message);
    setUpdateDocumentslist("");
  }
}

export function* PreViewDocumentApi({ payload }) {
  try {
    const res = yield call(PreViewDocument_Api, payload);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "rpmportal");
      yield put(setPreviewDocument(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeleteTempDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "rpmportal");

  try {
    const res = yield call(DeleteTempDocument_Api, Doc_Data);
    if (res.status === 200) {
      yield put(setDeleteTempDoc(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DownloadDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "rpmportal");
  try {
    const res = yield call(DownloadDocument_Api, Doc_Data);
    if (res.status === 200) {
      yield put(setPreviewDownload(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SignDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "rpmportal");

  try {
    const res = yield call(SignDocument_Api, Doc_Data);
    if (res.status === 200) {
      yield put(setSavesignature(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeleteDocumentApi({ payload }) {
  try {
    const res = yield call(DeleteDocument_Api, payload);
    if (res.status === 200) {
      yield put(setDeleteDocumentslist(res.data));
    }
  } catch (e) {
    setDeleteDocumentslist("");
  }
}
