import { takeLatest } from "redux-saga/effects";
import {
  GetMsgList,
  getMessagerList,
  getMessagerSend,
  getPreviewDownload,
  getUnreadCount,
  GetStatus,
} from "../../StateManagement/Reducers/MessengerState";

import {
  MessageList,
  MessageSend,
  PreviewDownloadApi,
  ProviderList,
  UnreadCountUpdate,
  ProviderStatus,
} from "./MessangerSagaWorkers";

export function* ProviderListSaga() {
  yield takeLatest(GetMsgList.type, ProviderList);
}

export function* MessagerListSaga() {
  yield takeLatest(getMessagerList.type, MessageList);
}

export function* MessageSendSaga() {
  yield takeLatest(getMessagerSend.type, MessageSend);
}

export function* PreviewDownloadSagaM() {
  yield takeLatest(getPreviewDownload.type, PreviewDownloadApi);
}

export function* UnreadCountUpdateSaga() {
  yield takeLatest(getUnreadCount.type, UnreadCountUpdate);
}

export function* StatusSaga() {
  yield takeLatest(GetStatus.type, ProviderStatus);
}
