import styled from "styled-components";

export const MessageContainer = styled.div`
  width: 100%;
  height: calc(100% - 164px);
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  border-size: box-sizing;
  border: ${({ drop, fileErr }) =>
    drop && fileErr
      ? "2px dotted red"
      : drop && !fileErr
      ? "2px dotted #8DA3F0"
      : ""};
  ::-webkit-scrollbar {
    display: none;
  }

  ${({ drop, fileErr }) =>
    drop && fileErr
      ? `
        animation: hideBorder 5s forwards;
        @keyframes hideBorder {
          to {
            border: none;
          }
        }
      `
      : ""}
`;

export const ChatBox = styled.div`
  align-self: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  margin-bottom: 8px;
  max-width: ${({ type }) =>
    type === "date" ? "210px" : type === "text" ? "100%" : "fit-content"};
  text-align: ${({ type }) => (type === "date" ? "center" : "left")};
  display: flex;
  flex-direction: row;
`;

export const ChatBoxMsg = styled.div`
  border: ${({ type }) =>
    type === "date" && "0.50px rgba(46, 46, 46, 0.25) solid"};
  background: ${({ user }) => (user ? "#407BFF" : "#ffffff")};
  max-width: ${({ user }) => user && "360px"};
  color: ${({ user }) => (user ? " #FFFFFF" : "#8A969F")};
  border-radius: ${({ type }) => (type === "date" ? "16px" : "8px")};
  max-width: ${({ providerwidth }) => providerwidth && "360px"};
  padding: ${({ type }) => (type === "date" ? "6px 20px" : "4px 8px")};
  margin-right: ${({ user }) => user && "12px"};
  // display: ${({ user }) => user && "flex"};

  position: relative;
  min-height: 20px;
  ${({ user }) =>
    user &&
    `
&::after {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-left-color: #407BFF;
  border-right: 0;
  right: -6px;
  top: 22px;
  margin-top: -19px;
}
`};
  ${({ user, type }) =>
    !user &&
    type !== "date" &&
    `
&::before {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-right-color: #ffffff;
  border-left: 0;
  left: 11px;
  top: 8px;
  margin-left: -19px;
}
`};
`;
