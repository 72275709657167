import React, { useState, useEffect } from "react";
import { Div } from "../Styles";
import { Audio } from "../../StyledComponents";

const AudioPreview = ({ audioBlob }) => {
  const [reblob, setReblob] = useState([]);

  useEffect(() => {
    setReblob(audioBlob);
  }, [audioBlob]);

  return (
    <Div>
      {reblob.length > 0 && (
        <Audio
          className="aud-top-nav2 IpadResize"
          controls
          preload="metadata"
          type="audio/mpeg"
          src={reblob && reblob[0]}
          width="37vw"
          height="39px"
          marginTop="4px"
        />
      )}
    </Div>
  );
};

export default AudioPreview;
