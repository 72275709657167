import styled from "styled-components";

export const Label = styled.label`
  font-size: ${(p) => p.size && p.size};
  font-weight: ${(p) => p.weight && p.weight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
`;

// export const Div = styled.div`
//   text-align: ${(p) => p.textAlign && p.textAlign};
//   display: ${(p) => (p.display ? p.display : "block")};
//   width: ${(p) => p.width && p.width};
//   margin-left: ${(p) => p.marginLeft && p.marginLeft};
//   margin-right: ${(p) => p.marginRight && p.marginRight};
//   justify-content: ${(p) => p.justifyContent && p.justifyContent};
//   align-items: center;
// `;

export const Input = styled.input`
  color: ${(p) => p.color && p.color};
  background: #ffffff;
  border: 1px solid #d8e0f0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 8px;
  height: 48px;
  padding: 0px 18px;
  font-size: 14px;
  line-height: 24px;
  &:focus {
    outline: none;
  }
`;
