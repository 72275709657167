import React from "react";
import { Div, Image } from "../StyledComponents";
import healthicon from "../../Assets/images/svg/image/healthicon.png";
import newhealth from "../../Assets/images/svg/image/newhealth.png";
import verifyhealth from "../../Assets/images/svg/image/verifyhealth.png";
import { useSelector } from "react-redux";
import Program from "./Program";

const PatientStatus = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const SingPatDetails = useSelector((state) => state.Dashboard.SinglePatient);

  // ----------------------------State and Variables ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//
  const status = (St) => {
    if (St === "0") {
      return "New";
    } else if (St === "1" || St === "2") {
      return "Verification Pending";
    } else if (St === "3") {
      return "Enrolled";
    } else {
      return "New";
    }
  };
  // -------------------------- Functions End-------------------------------------------------------------//
  return (
    <Div width="49%" marginLeft="24px">
      <Div
        border=" 1px solid rgba(46, 46, 46, 0.25)"
        background=" #FFFFFF"
        width="100%"
        height="148px"
        borderRadius="8px"
        fontFamily="'Open Sans', sans-serif"
        fontWeight="600"
        fontSize="18px"
        color="#2E2E2E"
        lineHeight="normal"
        fontStyle="normal"
        padding="13px 20px 21px 16px"
      >
        <Div>Patient Status</Div>
        <Image
          height="48px"
          width="48px"
          fill="rgba(255, 210, 51, 0.15)"
          margin="8px 10px 3px 0px"
          src={
            SingPatDetails.enrollment_status === "5"
              ? newhealth
              : SingPatDetails.enrollment_status === "3"
              ? healthicon
              : SingPatDetails.enrollment_status === "2"
              ? verifyhealth
              : SingPatDetails.enrollment_status === "1"
              ? verifyhealth
              : SingPatDetails.enrollment_status === "0"
              ? newhealth
              : "-"
          }
          alt="healthicons image"
        ></Image>
        <Div
          className="rpmprogram"
          fontWeight="400"
          color={
            SingPatDetails.enrollment_status === "3"
              ? "#198C55"
              : SingPatDetails.enrollment_status === "2" ||
                SingPatDetails.enrollment_status === "1"
              ? "#FFC700"
              : SingPatDetails.enrollment_status === "0" ||
                SingPatDetails.enrollment_status === "5"
              ? "#F24822"
              : "-"
          }
          marginLeft={
            SingPatDetails.enrollment_status === "0" ||
            SingPatDetails.enrollment_status === "5"
              ? "7px"
              : "0"
          }
        >
          {status(SingPatDetails.enrollment_status)}
        </Div>
      </Div>
      <Program />
    </Div>
  );
};

export default PatientStatus;
