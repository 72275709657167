import React from "react";
import { Div } from "../StyledComponents";
import VitalsData from "./Vitals";
import Devices from "./Devices";
import ReadingsData from "./ReadingsData";
import TopNav from "../TopNav";
import { DivTab } from "./styles";
const Vitals = () => {
  return (
    <DivTab className="Vitals_Resp_alt">
      <TopNav title="Vitals" />
      <Div
        borderRadius="8px"
        padding="0px 20px"
        border="1px solid rgba(46,46,46,0.25)"
        margin="20px"
        marginTop="0px"
      >
        <VitalsData />
      </Div>
      <Div
        padding="20px 20px"
        border="1px solid rgba(46,46,46,0.25)"
        margin="20px"
        borderRadius="8px"
      >
        <Devices />
      </Div>
      <Div
        border="1px solid rgba(46,46,46,0.25)"
        margin="20px"
        borderRadius="8px"
      >
        <ReadingsData />
      </Div>
    </DivTab>
  );
};

export default Vitals;
