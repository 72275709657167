import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import PreLoginReducer from "./Reducers/PreLoginState";
import LoginReducer from "./Reducers/LoginState";
import TopNavReducer from "./Reducers/TopNavbarState";
import DashboardReducer from "./Reducers/DashboardState";
import VitalsReducer from "./Reducers/VitalsState";
import InsuranceReducer from "./Reducers/InsuranceState";
import DocumentsReducer from "./Reducers/DocumentsState";
import MessengerReducer from "./Reducers/MessengerState";
import SettingReducer from "./Reducers/SettingState";

import RootSaga from "../MiddleWare";

const Saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    PreLogin: PreLoginReducer,
    Login: LoginReducer,
    TopNavbar: TopNavReducer,
    Dashboard: DashboardReducer,
    Vitals: VitalsReducer,
    Messenger: MessengerReducer,
    Document: DocumentsReducer,
    Setting: SettingReducer,
    Insurance: InsuranceReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(Saga),
});

Saga.run(RootSaga);

export default store;
