import styled from "styled-components";
import { ReactComponent as Heart } from "../../Assets/images/svg/Vector.svg";
import { ReactComponent as Blood } from "../../Assets/images/svg/Blood.svg";
import { ReactComponent as Respiratory } from "../../Assets/images/svg/respiratory.svg";
import { ReactComponent as Gluco } from "../../Assets/images/svg/Glucometer.svg";
import { ReactComponent as Oxygen } from "../../Assets/images/svg/oxygen.svg";
import { ReactComponent as Weight } from "../../Assets/images/svg/weight.svg";
import { BiRightArrowAlt } from "react-icons/bi";
import { Text, Div } from "../StyledComponents";

export const HeartRate = styled(Heart)`
  height: 22px;
  width: 22px;
`;
export const BloodPressure = styled(Blood)`
  height: 24px;
  width: 24px;
`;
export const RespiratoryRate = styled(Respiratory)`
  height: 24px;
  width: 24px;
  margin-bottom: 18px;
  @media screen and (max-width: 1071px) {
    height: 20px;
    width: 20px;
  }
`;
export const Glucometer = styled(Gluco)`
  height: 24px;
  width: 24px;
  @media screen and (max-width: 1071px) {
    height: 20px;
    width: 20px;
  }
`;
export const Oxygenmeter = styled(Oxygen)`
  height: 24px;
  width: 24px;
  @media screen and (max-width: 1071px) {
    height: 20px;
    width: 20px;
  }
`;
export const Weightmeter = styled(Weight)`
  height: 22px;
  width: 22px;
`;

export const RespiratoryRate1 = styled(Respiratory)`
  height: 48px;
  width: 48px;
  margin-bottom: 18px;
  &.fillcolor path {
    fill: #2c7be5;
  }
`;

export const Arrow = styled(BiRightArrowAlt)`
  color: rgba(44, 123, 229, 1);
  width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-top: -3px;
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const TextVitals = styled(Text)`
  font-size: ${(p) => p.fontSize && p.fontSize};

  &.fontvitals {
    @media screen and (max-width: 1600px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1040px) {
      font-size: 16px;
    }
    @media screen and (max-width: 930px) {
      font-size: 15px;
    }
  }
  &.fontvitalsmg {
    @media screen and (max-width: 1600px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }
    @media screen and (max-width: 1071px) {
      font-size: 10px;
    }
  }
  &.fontoxygen {
    @media screen and (max-width: 1600px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1071px) {
      font-size: 11px;
    }
  }
  &.caretexttitle {
    font-size: 16px;
    @media screen and (max-width: 1050px) {
      font-size: 14px;
      max-width: 139px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
    @media screen and (max-width: 700px) {
      font-size: 10px;
    }
  }
  &.fontglucose {
    font-size: 24px;
    @media screen and (max-width: 1200px) {
      font-size: 20px;
    }
  }
`;

export const VitalsDiv = styled(Div)`
  &.responsive-right {
    @media screen and (max-width: 1071px) {
      position: absolute;
    }
  }
`;
export const VitalsDiv1 = styled(Div)`
  &.fontbloodpressure {
    padding: 0px 15px;
    @media screen and (max-width: 1105px) {
      padding: 0px 10px;
    }
  }

  &.rpmprogram {
    font-size: 24px;
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
  }

  &.devicepadding {
    padding-bottom: 28px;
    @media screen and (max-width: 1272px) {
      padding-bottom: 20px;
    }
  }
  &.devicewholepadding {
    padding: 24px 20px 8px 20px;
    @media screen and (max-width: 1250px) {
      padding: 17px 15px 8px 15px;
    }
  }

  &.carewholepadding {
    padding: 24px 20px 8px 20px;
    @media screen and (max-width: 1250px) {
      padding: 17px 15px 8px 15px;
    }
  }

  &.careimagediv {
    gap: 24px;
    @media screen and (max-width: 1048px) {
      gap: 15px;
    }
    @media screen and (max-width: 975px) {
      gap: 12px;
    }
  }
  &.careimagediv svg {
    font-size: 24px;
    color: #2c7be5;
    @media screen and (max-width: 1075px) {
      font-size: 20px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 16px;
    }
  }
  &.devicetexttitle {
    font-size: 14px;
    @media screen and (max-width: 1050px) {
      font-size: 12px;
    }
    @media screen and (max-width: 700px) {
      font-size: 10px;
    }
  }
`;
export const ResponsiveText = styled.p`
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  &.healthReport {
    @media screen and (max-width: 1018px) {
      font-size: 12px;
    }
  }
  &.healthScore {
    @media screen and (max-width: 970px) {
      font-size: 16px;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
`;
