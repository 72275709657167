import { takeLatest } from "redux-saga/effects";
import {
  GetCareTeamDetails,
  GetSinglePatDetail,
  SetVitals,
  getDeviceList,
} from "../../StateManagement/Reducers/DashboardState";
import {
  getSinglePatientList,
  VitalsApi,
  getDevices,
  GetCardTeamDetailsworker,
} from "./DashboardSagaWorkers";

export function* vitalsDemo() {
  yield takeLatest(SetVitals.type, VitalsApi);
}
export function* Devices() {
  yield takeLatest(getDeviceList.type, getDevices);
}
export function* SinglePatientDetail() {
  yield takeLatest(GetSinglePatDetail.type, getSinglePatientList);
}

export function* DashCardTeamDetails() {
  yield takeLatest(GetCareTeamDetails.type, GetCardTeamDetailsworker);
}
