export const HealthScoreCalculator = (vitalsData) => {
  if (vitalsData === null || vitalsData === "") {
    return {
      score: 1,
      vitalCount: 1,
    };
  }
  if (vitalsData !== null || vitalsData !== "") {
    const vitals_values = [
      vitalsData.BloodGlucoseLevel,
      vitalsData.oxygen_saturation,
      vitalsData.pulse,
      vitalsData.respiration,
      vitalsData.bps_1,
    ];
    const vitalsCount = vitals_values.filter(
      (vital) => vital !== null && vital !== "" && vital !== undefined
    );
    // To find the count of normal Vital values
    const thresholds = {
      BloodGlucoseLevel: [120, 140],
      oxygen_saturation: [95, 100],
      pulse: [60, 100],
      respiration: [12, 20],
    };
    let vitalScores = Object.keys(thresholds).map((vital) => {
      const value = parseFloat(vitalsData[vital]);
      const [min, max] = thresholds[vital];
      return value >= min && value <= max ? 100 : 0;
    });
    const BP =
      vitalsData.bps_1 >= 90 &&
      vitalsData.bps_1 <= 120 &&
      vitalsData.bps_2 >= 60 &&
      vitalsData.bps_2 <= 80
        ? 100
        : 0;
    vitalScores = [...vitalScores, BP];

    const normalVitalCount = vitalScores.filter((score) => score === 100);
    // Calculate health score based on vitals reading count and normal vital reading count
    const vitalDiff = Math.abs(vitalsCount.length - normalVitalCount.length);

    const scoreCalculations = {
      5: (vitalDiff) => Math.max(20, 100 - vitalDiff * 20),
      4: (vitalDiff) => Math.max(25, 100 - vitalDiff * 25),
      3: (vitalDiff) => Math.max(30, 90 - vitalDiff * 30),
      2: (vitalDiff) => Math.max(50, 100 - vitalDiff * 50),
    };

    let score;
    if (vitalsCount.length === 0) {
      return {
        score: 0,
        vitalCount: vitalsCount.length,
      };
    } else if (vitalsCount.length === normalVitalCount.length) {
      return {
        score: 100,
        vitalCount: vitalsCount.length,
      };
    } else if (normalVitalCount.length === 0) {
      return {
        score: 0,
        vitalCount: vitalsCount.length,
      };
    } else {
      score = scoreCalculations[vitalsCount.length]
        ? scoreCalculations[vitalsCount.length](vitalDiff)
        : 0;
      return {
        score: score,
        vitalCount: vitalsCount.length,
      };
    }
  }
};
