import styled from "styled-components";

export const PaginationAnch = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === "active" ? "#2c7be5" : "#000")};
  height: 42px;
  width: 42px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background: ${(props) => (props.active === "active" ? "#fff" : "#fbfafc")};
  border-radius: 4px;
  margin-right: 16px;
  border: 1px solid
    ${(props) => (props.active === "disabled" ? "#ebeaed" : "#2c7be5")};
  font-family: "Manrope";
  font-weight: ${(props) => (props.active === "active" ? 600 : "")};
  font-size: 14px;
  line-height: 20px;
  &:hover:not(.active) {
    background-color: rgb(238, 238, 238);
  }
`;

export const PagePrevNext = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === "active" ? "#fff" : "#2e2c34")};
  display: ${(props) => (props.active === "disabled" ? "none" : "flex")};
  height: 42px;
  width: 80px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background: ${(props) => (props.active === "active" ? "#2c7be5" : "#2E2C34")};
  border-radius: 4px;
  margin-right: 16px;
  border: 1px solid
    ${(props) => (props.active === "disabled" ? "#ebeaed" : "#2C7BE5")};
  font-family: "Manrope";
  font-weight: ${(props) => (props.active === "active" ? 600 : "")};
  font-size: 14px;
  line-height: 20px;
  &:hover:not(.active) {
    background-color: #005fb2;
  }
`;
