import { createSlice } from "@reduxjs/toolkit";

export const preLoginSlice = createSlice({
  name: "PreLogin",
  initialState: {
    PortalEnable: {},
    EmailResp: {},
    mode: "",
    otp: {},
    passWordRes: {},
    signup: [],
    logo: [],
    ReEmailResp: {},
    patientUrl: [],
  },
  reducers: {
    GetPortalEnable: (state) => {
      state.Loading = true;
    },
    setPortalEnable: (state, action) => {
      state.PortalEnable = action.payload;
    },
    GetEmailRes: (state) => {
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
    },
    GetReEmailRes: (state) => {
      return state;
    },
    setReEmailRes: (state, action) => {
      state.ReEmailResp = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    GetPassRes: (state) => {
      return state;
    },
    setPassRes: (state, action) => {
      state.passWordRes = action.payload;
    },
    SetPassResEmpty: (state, action) => {
      state.passWordRes = "";
    },
    GetSignUp: (state) => {
      return state;
    },
    setSignUp: (state, action) => {
      state.signup = action.payload;
    },
    GetLogo: (state) => {
      return state;
    },
    SetLogo: (state, action) => {
      if (action.payload && action.payload !== null) {
        state.logo = action.payload;
      }
    },
    GetPatientUrl: (state) => {
      return state;
    },
    SetPatientUrl: (state, action) => {
      state.PatientUrl = action.payload;
    },
  },
});

export const {
  GetPortalEnable,
  setPortalEnable,
  GetEmailRes,
  setEmailRes,
  GetReEmailRes,
  setReEmailRes,
  setMode,
  setOtp,
  GetPassRes,
  setPassRes,
  GetSignUp,
  setSignUp,
  GetLogo,
  SetLogo,
  GetPatientUrl,
  SetPatientUrl,
  SetPassResEmpty,
} = preLoginSlice.actions;

export default preLoginSlice.reducer;
