import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  height: 100%;
}

#RPM_Patient_Portal {
  width: 100%;
  height: max-content;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
}


`;

export default GlobalStyle;
