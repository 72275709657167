import React from "react";

import SignUpRight from "../SignUpRight";
import LoginLeft from "../LoginLeft";
import { Div } from "../../StyledComponents";

const SignUp = () => {
  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF" overflowY="auto">
        <SignUpRight />
      </Div>
    </Div>
  );
};

export default SignUp;
