import axios from "axios";
import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";

let DBHost;
if (window.location.href.includes("localhost")) DBHost = "default";
else {
  let DBConnection = window.location.hostname.split(".");

  if (DBConnection[0] !== "mumbairpm") {
    DBHost = DBConnection[0];
  } else {
    DBHost = "default";
  }
}

const baseURL = process.env.REACT_APP_BACKEND_URL + DBHost + "/api";
let headers = {};
let access = localStorage.accesstoken ? localStorage.accesstoken : "";
if (access.length > 0) {
  headers.Authorization = `${access}`;
} else {
  headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
}

const axiosInst = axios.create({
  baseURL,
  headers,
});
axiosInst.interceptors.request.use(
  (config) => {
    if (localStorage.accesstoken) {
      config.headers.Authorization = localStorage.accesstoken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInst;
