import { Div } from "../StyledComponents";
import DocumentList from "./DocumentList";
const Document = () => {
  return (
    <Div>
      <Div background="#FFF">
        <DocumentList />
      </Div>
    </Div>
  );
};
export default Document;
