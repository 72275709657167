import { createSlice } from "@reduxjs/toolkit";

export const VitalsSlice = createSlice({
  name: "Vitals",
  initialState: {
    readingsData: {},
  },
  reducers: {
    getReadingsData: (data) => {
      return data;
    },
    setReadingsData: (state, action) => {
      state.readingsData = action.payload;
    },
  },
});

export const { getReadingsData, setReadingsData } = VitalsSlice.actions;

export default VitalsSlice.reducer;
