import styled from "styled-components";
import { Div } from "../StyledComponents";

export const DropdownContent = styled(Div)`
  animation: fadeIn 0.3s;

  & div:first-of-type svg {
    color: #667085;
  }

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-o-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }
`;

export const DropdownList = styled(Div)`
  transition-duration: 0.3s;
  &:hover {
    background: #ececec;
  }
`;
export const Iframe = styled.iframe`
  display: none;
  @media print {
    display: block;
  }
`;

export const LinkA = styled.a`
  display: none;
  @media print {
    display: block;
  }
`;

export const ModalDialog = styled(Div)`
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
`;
