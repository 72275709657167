import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Div, Text, Button, Span } from "../StyledComponents";
import {
  HeartRate,
  BloodPressure,
  RespiratoryRate,
  Glucometer,
  Oxygenmeter,
  Weightmeter,
  Arrow,
  TextVitals,
  VitalsDiv1,
} from "./Styles";

const VitalsDashboard = () => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const vitalsData1 = useSelector((state) => state.Dashboard.vitalsData);

  const parsedPulse = parseInt(vitalsData1.pulse);
  const pulse = !isNaN(parsedPulse) ? parsedPulse : null;

  const parsedBps1 = parseInt(vitalsData1.bps_1);
  const parsedBps2 = parseInt(vitalsData1.bps_2);

  const bps1 = !isNaN(parsedBps1) ? parsedBps1 : null;
  const bps2 = !isNaN(parsedBps2) ? parsedBps2 : null;

  const showBp = bps1 !== null && bps2 !== null;

  const parsedRespiratoryRate = parseInt(vitalsData1.respiration);

  const respiratoryRate = !isNaN(parsedRespiratoryRate)
    ? parsedRespiratoryRate
    : null;

  const parsedOxygenSaturation = parseFloat(vitalsData1.oxygen_saturation);
  const oxygenSaturation = !isNaN(parsedOxygenSaturation)
    ? parsedOxygenSaturation
    : null;
  const parsedWeight = parseFloat(vitalsData1.weight);
  const weight = !isNaN(parsedWeight) ? parsedWeight : null;

  const parsebloodGlucoseLevel = parseInt(vitalsData1.BloodGlucoseLevel);
  const bloodGlucoseLevel = !isNaN(parsebloodGlucoseLevel)
    ? parsebloodGlucoseLevel
    : null;

  // ----------------------------State and Variables ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//
  const navigate = useNavigate();
  const pageToVitals = () => {
    navigate(`/vitals`);
  };
  // -------------------------- Functions End-------------------------------------------------------------//

  return (
    <Div>
      <Div display="flex" justifyContent="space-between">
        <Text
          fontFamily="'Open Sans', sans-serif"
          fontWeight="600"
          fontSize="18px"
          color=" #2E2E2E"
          lineHeight="25px"
          fontStyle="normal"
          marginBottom="-16px"
          marginTop="20px"
        >
          Vitals
        </Text>

        <Button
          border="none"
          width="109px"
          height="33px"
          borderRadius="90px"
          background="#F1F5F8"
          fontFamily="'Open Sans', sans-serif"
          fontWeight="400"
          fontSize="14px"
          color=" #2C7BE5"
          lineHeight="normal"
          cursor="pointer"
          marginTop="4px"
          marginBottom="24px"
          onClick={pageToVitals}
        >
          <Div display="flex" marginLeft="20px" marginTop="5px">
            <Span> View All</Span>

            <Arrow cursor="pointer"></Arrow>
          </Div>
        </Button>
      </Div>
      <Div display="flex" paddingBottom="24px" justifyContent="space-between">
        <Div
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="16%"
          height="122px"
          borderRadius="8px"
          padding="0px 15px"
          position="relative"
          className="hg_alt_ipad"
        >
          <Div display="flex" padding="16px 0px 20px 0px">
            <Div>
              <HeartRate />
            </Div>
            <Text
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
            >
              Heart Rate
            </Text>
          </Div>
          {pulse !== null && (
            <Div
              padding="15px 0px 0px 0px"
              display="flex"
              alignItems="baseline"
              position="absolute"
              bottom="18px"
            >
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={pulse < 60 || pulse > 100 ? "#F24822" : "#2C7BE5"}
                lineHeight="33px"
                fontStyle="normal"
              >
                {pulse}
              </Text>

              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={pulse < 60 || pulse > 100 ? "#F24822" : "#2C7BE5"}
                fontStyle="normal"
                paddingLeft="10px"
              >
                BPM
              </Text>
            </Div>
          )}
        </Div>
        <VitalsDiv1
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          className="fontbloodpressure"
          width="17.5%"
          height="122px"
          borderRadius="8px"
          position="relative"
        >
          <Div display="flex" padding="16px 0px 16px 0px">
            <Div>
              <BloodPressure />
            </Div>
            <Text
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              marginLeft="-2px"
              paddingLeft="8px"
            >
              Blood Pressure
              <span style={{ visibility: "hidden" }}> level </span>
            </Text>
          </Div>
          {showBp && (
            <Div
              display="flex"
              alignItems="baseline"
              overflow="hidden"
              position="absolute"
              bottom="18px"
            >
              <TextVitals
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                className="fontvitals"
                color={
                  bps1 >= 90 && bps1 <= 120 && bps2 >= 60 && bps2 <= 80
                    ? "#2C7BE5" // Normal
                    : "#F24822" // All other conditions
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {bps1}/{bps2}
              </TextVitals>
              <TextVitals
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                className="fontvitalsmg"
                color={
                  bps1 >= 90 && bps1 <= 120 && bps2 >= 60 && bps2 <= 80
                    ? "#2C7BE5" // Normal
                    : "#F24822" // All other conditions
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                mm/Hg
              </TextVitals>
            </Div>
          )}
        </VitalsDiv1>
        <VitalsDiv1
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="17.5%"
          height="122px"
          borderRadius="8px"
          position="relative"
          className="fontbloodpressure "
        >
          <Div display="flex" padding="16px 0px 16px 0px">
            <Div>
              <RespiratoryRate />
            </Div>
            <TextVitals
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
              className="fontoxygen"
            >
              Respiratory Rate
              <span style={{ visibility: "hidden" }}> level </span>
            </TextVitals>
          </Div>
          {respiratoryRate !== null && (
            <Div
              display="flex"
              alignItems="baseline"
              position="absolute"
              bottom="18px"
            >
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={
                  respiratoryRate >= 12 && respiratoryRate <= 20
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {respiratoryRate}
              </Text>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  respiratoryRate >= 12 && respiratoryRate <= 20
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                RPM
              </Text>
            </Div>
          )}
        </VitalsDiv1>
        <VitalsDiv1
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="16.5%"
          height="122px"
          borderRadius="8px"
          className="fontbloodpressure hg_alt_ipad"
          position="relative"
        >
          <Div display="flex" padding="16px 0px 16px 0px">
            <Div>
              <Glucometer />
            </Div>
            <Text
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
            >
              Glucose level
            </Text>
          </Div>
          {bloodGlucoseLevel !== null && (
            <Div
              display="flex"
              alignItems="baseline"
              position="absolute"
              bottom="18px"
            >
              <TextVitals
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                className="fontglucose"
                color={
                  bloodGlucoseLevel >= 120 && bloodGlucoseLevel <= 140
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {bloodGlucoseLevel}
              </TextVitals>
              <TextVitals
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  bloodGlucoseLevel >= 120 && bloodGlucoseLevel <= 140
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Elevated and High
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                mg/dL
              </TextVitals>
            </Div>
          )}
        </VitalsDiv1>

        <VitalsDiv1
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="13.15%"
          height="122px"
          borderRadius="8px"
          position="relative"
          className="fontbloodpressure"
        >
          <Div display="flex" padding="16px 0px 0px 0px">
            <Div>
              <Oxygenmeter />
            </Div>
            <TextVitals
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              color=" #2E2E2E"
              fontStyle="normal"
              paddingLeft="8px"
              className="fontoxygen"
            >
              Oxygen
              <span style={{ visibility: "hidden" }}>level</span>
            </TextVitals>
          </Div>
          {oxygenSaturation !== null && (
            <Div
              display="flex"
              alignItems="baseline"
              padding="16px 0px 0px 0px"
              position="absolute"
              bottom="18px"
            >
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                color={
                  oxygenSaturation >= 95 && oxygenSaturation <= 100
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                lineHeight="33px"
                fontStyle="normal"
              >
                {oxygenSaturation}
              </Text>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color={
                  oxygenSaturation >= 95 && oxygenSaturation <= 100
                    ? "#2C7BE5" // Normal
                    : "#F24822" // Abnormal
                }
                fontStyle="normal"
                paddingLeft="4px"
              >
                %
              </Text>
            </Div>
          )}
        </VitalsDiv1>
        <Div
          border=" 1px solid rgba(46, 46, 46, 0.25)"
          background=" #FFFFFF"
          width="15.5%"
          height="122px"
          borderRadius="8px"
          position="relative"
        >
          <Div padding="0px 15px">
            <Div display="flex" padding="16px 0px 20px 0px">
              <Div>
                <Weightmeter />
              </Div>
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                color=" #2E2E2E"
                fontStyle="normal"
                paddingLeft="8px"
              >
                Weight
              </Text>
            </Div>
            {weight !== null && (
              <Div
                padding="15px 0px 0px 0px"
                display="flex"
                alignItems="baseline"
                position="absolute"
                bottom="18px"
              >
                <Text
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="700"
                  fontSize="24px"
                  color="  #2C7BE5"
                  lineHeight="33px"
                  fontStyle="normal"
                >
                  {weight}
                </Text>
                <Text
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  fontSize="14px"
                  color=" #2C7BE5"
                  fontStyle="normal"
                  paddingLeft="4px"
                >
                  Kg
                </Text>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default VitalsDashboard;
