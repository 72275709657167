import { createSlice } from "@reduxjs/toolkit";

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    Loading: true,
    LoadingVitals: true,
    SinglePatient: {},
    vitalsData: {},
    DeviceList: [],
    CareTeamDetails: [],
    Health: {
      Score: 0,
      vitalCount: 0,
    },
  },
  reducers: {
    GetSinglePatDetail: (state) => {
      state.Loading = true;
    },
    SetSinglePatDetail: (state, action) => {
      state.SinglePatient = action.payload;
      state.Loading = false;
    },
    GetVitals: (state, action) => {
      state.vitalsData = action.payload;
      state.LoadingVitals = false;
    },

    SetVitals: (Data) => {
      Data.LoadingVitals = true;
      return Data;
    },
    getDeviceList: (data) => {
      return data;
    },
    setDeviceList: (state, action) => {
      state.DeviceList = action.payload;
    },
    setHealthScore: (state, action) => {
      state.Health = action.payload;
    },
    GetCareTeamDetails: (state) => {
      state.Loading = true;
    },
    setCareTeamDetails: (state, action) => {
      state.CareTeamDetails = action.payload;
      state.Loading = false;
    },
  },
});

export const {
  GetSinglePatDetail,
  SetSinglePatDetail,
  GetVitals,
  SetVitals,
  getDeviceList,
  setDeviceList,
  setHealthScore,
  GetCareTeamDetails,
  setCareTeamDetails,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
