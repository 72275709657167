import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

export const UserDetails_Api = async () =>
  await axiosInst.get(`/rpmportal/PatientData?pid=${Pat_ID}`);
export const LoginUpdateApi = async () =>
  await axiosInst.get(`/rpmportal/loginUpdate?pid=${Pat_ID}`);
