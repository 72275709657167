import React, { useState, useEffect } from "react";
import {
  BodyContainer,
  PageTitle,
  Div,
  Span,
  Image,
} from "../../StyledComponents/index";
import { useDispatch, useSelector } from "react-redux";

import { getDeviceList } from "../../../StateManagement/Reducers/DashboardState";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const DevicesSheet = () => {
  // ------------------------------ State and Variables Start---------------------------------------------------//

  const pid = Decrypt_Value(localStorage.getItem("PID"), "rpmportal");
  const dispatch = useDispatch();

  const devicesList = useSelector((state) => state.Dashboard.DeviceList);

  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ UseEffect Start-------------------------------------------------------------//

  useEffect(() => {
    dispatch(getDeviceList());
  }, [pid]);

  // ------------------------------ UseEffect End------------------------------------------------------------- //

  return (
    <>
      <BodyContainer>
        <Div display="flex">
          <PageTitle
            lineHeight="25px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="18px"
            fontWeight="600"
          >
            Device Assigned
          </PageTitle>
        </Div>
        <Div
          marginTop="13px"
          style={{
            display:
              devicesList["Device_model"] !== undefined &&
              devicesList["Device_model"].length !== 0 &&
              "flex",
          }}
          flexWrap="wrap"
        >
          {devicesList !== undefined &&
          devicesList &&
          devicesList.length !== 0 &&
          devicesList["Device_model"] !== undefined &&
          devicesList["Device_model"].length !== 0 ? (
            devicesList["Device_model"].map((device, index) => (
              <Div key={index} marginRight="40px">
                <Div
                  height="123px"
                  width="116px"
                  backgroundColor=" rgba(46, 46, 46, 0.1)"
                  borderRadius="8px"
                  marginBottom="8px"
                ></Div>
                <Span
                  color="#2E2E2E"
                  lineHeight="22px"
                  fontFamily="'Open Sans',sans-serif"
                  fontSize="14px"
                  fontWeight="400"
                  style={{ width: "116px", display: "block" }}
                  width="116px"
                  textAlign="center"
                >
                  {device.device_model}
                </Span>
              </Div>
            ))
          ) : (
            <Div
              style={{
                display: "grid",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Image
                height="150px"
                width="150px"
                src={Emp_data_pic}
                style={{ margin: "auto" }}
              ></Image>
              <Span
                fontSize="14px"
                color="#2E2E2E"
                fontFamily="'Open Sans',sans-serif"
                lineHeight="20px"
                fontWeight="400"
                textAlign="center"
              >
                No Devices to be shown,Please add some.
              </Span>
            </Div>
          )}
        </Div>
      </BodyContainer>
    </>
  );
};
export default DevicesSheet;
