import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import {
  setOtp,
  setEmailRes,
  setReEmailRes,
  setPassRes,
  setSignUp,
  SetLogo,
  SetPatientUrl,
} from "../../StateManagement/Reducers/PreLoginState";

import {
  LoginEmailCheck_Api,
  LoginEmailCheckNew_Api,
  PasswordResetEmailCheck_Api,
  LoginPasswordSet_Api,
  SignUp_Api,
  Extension_Api,
} from "../Apis/PreLoginApi";

export function* LoginEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "rpmportal");
  let mode = payload.mode;
  try {
    const res = yield call(
      mode === "old" ? LoginEmailCheck_Api : LoginEmailCheckNew_Api,
      email
    );
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "rpmportal");

      yield put(setOtp(Data.output));
      localStorage.setItem("OTP", Encrypt_Value(Data.output, "rpmportal"));
      yield put(setEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* LoginPasswordSetApi({ payload }) {
  const data = Encrypt_Value(payload.type, "rpmportal");

  try {
    const res = yield call(LoginPasswordSet_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "rpmportal");
      yield put(setPassRes(Data.status));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PasswordResetEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "rpmportal");

  try {
    const res = yield call(PasswordResetEmailCheck_Api, email);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "rpmportal");
      yield put(setOtp(Data.output));

      yield put(setReEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SignUpApi({ payload }) {
  const data = Encrypt_Value(payload, "rpmportal");
  try {
    const res = yield call(SignUp_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "rpmportal");
      yield put(setSignUp(Data.status));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* LogoApi() {
  try {
    const res = yield call(Extension_Api);
    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozorpm");

      yield put(SetLogo(Decrypt_Response[5]["gl_value"]));
      yield put(SetPatientUrl(Decrypt_Response[11]["gl_value"]));
    }
  } catch (e) {
    console.log(e.message);
  }
}
