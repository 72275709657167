import React from "react";
// import { Div } from "./Styles";
import LoginRight from "./LoginRight";
import LoginLeft from "./LoginLeft";
import { Div } from "../StyledComponents";

const Login = () => {
  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF">
        <LoginRight />
      </Div>
    </Div>
  );
};

export default Login;
