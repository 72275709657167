import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SetImageInd } from "../../../StateManagement/Reducers/LoginState";
import { GetLogo } from "../../../StateManagement/Reducers/PreLoginState";

import SlideImage from "./SlideImage";

import Slider_1 from "../../../Assets/images/login_health_score.png";
import Slider_2 from "../../../Assets/images/login_provider.png";
import Slider_3 from "../../../Assets/images/login_care_team.png";
import { Div } from "../../StyledComponents";

const LoginLeft = () => {
  const dispatch = useDispatch();
  const ImageInd = useSelector((state) => state.Login.ImageInd);

  const ImageVal = [Slider_1, Slider_2, Slider_3];

  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    dispatch(GetLogo());
  }, [dispatch]);

  useEffect(() => {
    const Timer = setTimeout(() => {
      dispatch(SetImageInd(ImageInd));
    }, 3000);
    return () => clearTimeout(Timer);
  });
  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <>
      <Div>
        <Div
          borderRadius="50%"
          position="absolute"
          left="50%"
          top="50%"
          width="60%"
          height="calc(100vw / 3.25)"
          minWidth="228px"
          min-height="228px"
          background="#407bff"
          transform
        ></Div>
        <Div
          borderRadius="50%"
          position="absolute"
          left="50%"
          top="50%"
          width="80%"
          height="calc(100vw / 2.5)"
          min-width="368px"
          min-height="368px"
          background="rgba(64, 123, 255, 0.1)"
          transform
        ></Div>
        <Div
          borderRadius="50%"
          position="absolute"
          left="50%"
          top="50%"
          width="100%"
          height="100vh"
          min-width="512px"
          min-height="512px"
          transform
          background="rgba(64, 123, 255, 0.05)"
        ></Div>
      </Div>
      <SlideImage ImageUrl={ImageVal[ImageInd]} />
      <Div
        display="flex"
        gap="12px"
        position="absolute"
        bottom="30px"
        justifyContent="center"
        width="100%"
      >
        {ImageVal.map((dot, ind) => (
          <Div
            width="138px"
            height="6px"
            borderRadius="3px"
            cursor="pointer"
            backgroundColor={ind === ImageInd ? "#407bff" : "#CCD6E1"}
            transition="1s"
            onClick={() => dispatch(SetImageInd(ind - 1))}
            active={ind === ImageInd}
            key={ind}
          ></Div>
        ))}
      </Div>
    </>
  );
};

export default LoginLeft;
