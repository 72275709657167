import React, { useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Div,
  Image,
  FormLabel,
  Input,
  DropDown,
  Button,
  Span,
  ErrorMessage,
  RedStar,
  DropdownImage,
  DropdownOptions,
  DropDownDiv,
} from "../StyledComponents";
import { Hrline, Imagecalendar } from "./styles";
import Myprofileediticon from "../../Assets/images/myprofileediticon.png";
import UploadProfilePicture from "./UploadProfilePicture/index";
import calendar_icon_custom from "../../Assets/images/calendar_icon_custom.png";
import CustomDatePicker from "../StyledComponents/datepicker/CustomDatePicker";
import moment from "moment";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import {
  GetMailVerify,
  GetPatEnrollment,
  GetPatGenderDetails,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPhoneVerify,
  GetSSNVerify,
  setEmptyPatUpdateResponse,
} from "../../StateManagement/Reducers/SettingState";
import { GetSinglePatDetail } from "../../StateManagement/Reducers/DashboardState";
import DropdownDownicon from "../../Assets/images/dropdownDownicon.png";
import { validCountryCodes } from "./CountryCodes";
import { Spinner, SpinnerWrapper } from "../Vitals/styles";
import Logo from "../../Assets/images/Logo.png";

const Myprofile = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [previewimg1, setpreviewimg1] = useState("");
  const [profilepic, setProfilepic] = useState("");
  const [isupload, setIsUpload] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [imageText, setImageText] = useState("add file");

  let imageUrl =
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documenting/";

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [ssnNum, setSsnNum] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [language, setLanguage] = useState("");
  const [bloodGr, setBloodGr] = useState("");
  const [dob, setDOB] = useState("");
  const [c_code, setCCode] = useState("+91");
  const [validC_codeErr, setValidC_codeErr] = useState(false);

  const [FirstNameErr, setFirstNameErr] = useState("");
  const [LastNameErr, setLastNameErr] = useState("");
  const [EmailErr, setEmailErr] = useState("");
  const [MobileErr, setMobileErr] = useState("");
  const [StateErr, setStateErr] = useState("");
  const [AddressErr, setAddressErr] = useState("");
  const [ZipcodeErr, setZipCodeErr] = useState("");
  const [CityErr, setCityErr] = useState("");
  const [dateerr1, setDateerr1] = useState(false);
  const [gendererr, setGenderErr] = useState("");
  const [raceerr, setRaceErr] = useState("");
  const [ethnicityerr, setEthnicityErr] = useState("");
  const [validMailErr, setValidMailErr] = useState(false);
  const [validPhoneErr, setValidPhoneErr] = useState(false);
  const [validSSNErr, setValidSSNErr] = useState(false);
  const [ssnErr, setSsnerr] = useState(false);
  const [ssnErr1, setSsnerr1] = useState(false);

  const [initialSSN, setInitialSSN] = useState(false);
  const [initialMail, setInitialMail] = useState(false);
  const [initialPhone, setInitialPhone] = useState(false);

  const [showpicker1, setShowPicker1] = useState(false);
  const [genderDrop, setGenderDrop] = useState(false);
  const [temp, setTemp] = useState(false);
  const [bloodDrop, setBloodDrop] = useState(false);
  const [raceDrop, setRaceDrop] = useState(false);
  const [ethnicityDrop, setEthnicityDrop] = useState(false);
  const [languageDrop, setLanguageDrop] = useState(false);
  const [disabledsave, setdisabledsave] = useState(false);

  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");

  const [validDate1, setValidDate1] = useState(
    moment(new Date(), "DD/MM/YYYY")
  );

  const pid =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

  const Loading = useSelector((state) => state.Dashboard.Loading);

  const PatEnrollmentDetails = useSelector(
    (state) => state.Dashboard.SinglePatient
  );

  const GenderDetails = useSelector((state) => state.Setting.PatGenderDetails);
  const LanguageStatusList = useSelector(
    (state) => state.Setting.PatLangDetails
  );
  const RaceStatusList = useSelector((state) => state.Setting.PatRaceDetails);
  const updateResponse = useSelector(
    (state) => state.Setting.PatientUpdateResponse
  );
  const EmailVerify = useSelector((state) => state.Setting.MailVerify);
  const PhoneVerify = useSelector((state) => state.Setting.PhoneVerify);
  const SSNVerify = useSelector((state) => state.Setting.SSNVerify);

  const BloodGrList = [
    { id: 1, title: "O possitive", val: "O +" },
    { id: 2, title: "O negative", val: "O -" },
    { id: 3, title: "A possitive", val: "A +" },
    { id: 4, title: "A negative", val: "A -" },
    { id: 5, title: "B possitive", val: "B +" },
    { id: 6, title: "B negative", val: "B -" },
    { id: 7, title: "AB possitive", val: "AB +" },
    { id: 8, title: "AB negative", val: "AB -" },
  ];
  const EthnicityList = [
    { id: 1, title: "Hispanic or Latino" },
    { id: 2, title: "Not Hispanic or Latino" },
    { id: 3, title: "Declined to specify" },
  ];

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker1();
    }
  };

  const handlepicker1 = (e) => {
    e.preventDefault();
    setShowPicker1(true);
  };

  const closePicker1 = () => {
    setShowPicker1(false);
  };

  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setDOB(moment(value).format("YYYY-MM-DD"));
      setDateerr1(false);
      setValidDate1(new Date(value));
    }
    closePicker1();
  };

  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    const inputValue = inp.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const caretPosition = inp.selectionStart;

    if (key !== 8 && key !== 46) {
      if (numericValue.length === 4 && caretPosition === 4) {
        inp.value = numericValue + "-";
      } else if (numericValue.length === 6 && caretPosition === 7) {
        inp.value =
          numericValue.slice(0, 4) + "-" + numericValue.slice(4, 6) + "-";
      } else if (numericValue.length > 6 && caretPosition === 10) {
        inp.value =
          numericValue.slice(0, 4) +
          "-" +
          numericValue.slice(4, 6) +
          "-" +
          numericValue.slice(6, 8);
      }
    }
  };
  const inputChange = (e) => {
    if (e.target.id === "date") {
      setDOB(e.target.value);

      let d = moment(e.target.value, "YYYY-MM-DD", true).isValid();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      let today = `${year}-${month}-${day}`;
      if (new Date(e.target.value) > new Date(today) || !d) {
        setDateerr1(true);
      } else {
        setDateerr1(false);
        setValidDate1(e.target.value);
      }
    } else {
      setDOB(e.target.value);
    }
  };

  const setFirstNameChange = (e) => {
    let a = /^[a-zA-Z]+$/.test(e.target.value);
    let capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    if (a || e.target.value === "") {
      if (e.target.value === "") {
        setFirstNameErr(true);
      } else {
        setFirstNameErr(false);
      }
      setFirstName(capitalizedValue);
    }
  };
  const setMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };

  const setLastNameChange = (e) => {
    let a = /^[a-zA-Z]+$/.test(e.target.value);
    let capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    if (a || e.target.value === "") {
      if (e.target.value === "") {
        setLastNameErr(true);
      } else {
        setLastNameErr(false);
      }
      setLastName(capitalizedValue);
    }
  };

  const setSsnNumValChange = (e) => {
    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
    const inputValue = e.target.value.replace(/\D/g, "");
    const formattedSSN = formatSSN(inputValue.slice(0, 9));
    setInitialSSN(true);
    setSsnNum(formattedSSN);

    if (e.target.value.length === 0) {
      setSsnerr1(true);
      setSsnerr(false);
      setValidSSNErr(false);
    } else if (ssnRegex.test(formattedSSN)) {
      setSsnerr(false);
      dispatch(
        GetSSNVerify({
          ssn: formattedSSN,
          val: "ssn",
          pid: pid,
        })
      );
    } else {
      setSsnerr(true);
      setSsnerr1(false);
      setValidSSNErr(false);
    }
  };

  const inputNineDig = (e) => {
    var pattern = /^\d{9}$/;
    if (pattern.test(e.target.value)) {
      setSsnerr(false);
      e.preventDefault();
    } else {
      setSsnerr(true);
      setValidSSNErr(false);
      setSsnerr1(false);
    }
  };

  const handleKeyPress = (e) => {
    const regex = /^[a-zA-Z]+$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const setEmailChange = (e) => {
    let result = e.target.value.replace(/\s/g, "");
    if (e.target.name === "email") {
      setInitialMail(true);
      dispatch(GetMailVerify({ email: result, val: "mail", pid: pid }));
    }
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setEmail(result);
    if (regex.test(result)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };

  const setMobileChange = (e) => {
    if (
      !isNaN(e.target.value) &&
      !e.target.value.includes(".") &&
      !e.target.value.includes(" ")
    ) {
      setPhone(e.target.value);
      if (e.target.value.length == 0) {
        document.getElementById("ccode_inp").focus();
      }
      if (e.target.name === "phone") {
        setInitialPhone(true);
        setPhone(e.target.value);
        setMobileErr(false);
        setValidPhoneErr(false);
      }
      if (e.target.value === "") {
        setMobileErr(true);
        setValidPhoneErr(false);
        setInitialPhone(false);
      }
    }
  };

  const setCCodeChange = (e) => {
    setValidC_codeErr(false);
    const countryCode = e.target.value.replace(/[()]/g, "");
    let a = /^[0-9+]+$/.test(countryCode);
    if (a && countryCode.length >= 1) {
      setCCode(countryCode);
    }
    if (countryCode.length == 5) {
      document.getElementById("phone_inp").focus();
    }
  };

  const setAddressChange = (e) => {
    setAddress(e.target.value);
    if (e.target.value !== "") {
      setAddressErr(false);
    } else {
      setAddressErr(true);
    }
  };

  const setCityChange = (e) => {
    let a = /^[a-zA-Z]+$/.test(e.target.value);
    let capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    if (a || e.target.value === "") {
      if (e.target.value !== "") {
        setCityErr(false);
      } else {
        setCityErr(true);
      }
      setCity(capitalizedValue);
    }
  };

  const setStateChange = (e) => {
    let a = /^[a-zA-Z]+$/.test(e.target.value);
    let capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    if (a || e.target.value === "") {
      if (e.target.value !== "") {
        setStateErr(false);
      } else {
        setStateErr(true);
      }
      setState(capitalizedValue);
    }
  };

  const setZipChange = (e) => {
    if (!isNaN(e.target.value)) setZip(e.target.value);
    if (e.target.value !== "") {
      setZipCodeErr(false);
    } else {
      setZipCodeErr(true);
    }
  };

  const formatSSN = (ssn) => {
    const cleanedSSN = ssn.replace(/\D/g, "");

    let formatted = cleanedSSN.slice(0, 3);

    if (cleanedSSN.length > 3) {
      formatted += "-" + cleanedSSN.slice(3, 5);
    }

    if (cleanedSSN.length > 5) {
      formatted += "-" + cleanedSSN.slice(5, 9);
    }

    return formatted;
  };

  const SaveProfile = () => {
    const dataArray = new FormData();
    var err = 0;
    if (firstName === "" || firstName === null) {
      setFirstNameErr(true);
      err = 2;
    }
    if (lastName === "" || lastName === null) {
      setLastNameErr(true);
      err = 2;
    }
    if (email === "" || email === null) {
      setEmailErr(true);
      setInitialMail(false);
      err = 2;
    }
    if (phone === "" || phone === null) {
      setMobileErr(true);
      setValidPhoneErr(false);
      setInitialPhone(false);

      err = 2;
    }
    if (address === "" || address === null) {
      setAddressErr(true);
      err = 2;
    }
    if (city === "" || city === null) {
      setCityErr(true);
      err = 2;
    }
    if (state === "" || state === null) {
      setStateErr(true);
      err = 2;
    }
    if (zip === "" || zip === null) {
      setZipCodeErr(true);
      err = 2;
    }
    if (dob === "" || dob === null) {
      setDateerr1(true);
      err = 2;
    }
    if (dateerr1) {
      err = 2;
    }
    if (ssnErr) {
      setSsnerr(true);
      setSsnerr1(false);
      setValidSSNErr(false);
      err = 2;
    }
    if (validSSNErr) {
      setValidSSNErr(true);
      setSsnerr1(false);
      setSsnerr(false);
      err = 2;
    }
    if (validPhoneErr) {
      setValidPhoneErr(true);
      setMobileErr(false);
      err = 2;
    }
    if (validMailErr) {
      setValidMailErr(true);
      setEmailErr(false);
      err = 2;
    }

    if (EmailErr) {
      setValidMailErr(false);
      setEmailErr(true);
      err = 2;
    }
    if (dob < moment("1900-01-01").format("YYYY-MM-DD")) {
      setDateerr1(true);
      err = 2;
    }
    if (gender === "" || gender === null) {
      setGenderErr(true);
      err = 2;
    }
    if (race === "" || race === null) {
      setRaceErr(true);
      err = 2;
    }
    if (ethnicity === "" || ethnicity === null) {
      setEthnicityErr(true);
      err = 2;
    }
    if (ssnNum === "" || ssnNum === null) {
      setSsnerr1(true);
      setSsnerr(false);
      setValidSSNErr(false);
      err = 2;
    }
    if (validMailErr) {
      setValidMailErr(true);
      setEmailErr(false);
      err = 2;
    }

    if (!validCountryCodes.includes(c_code)) {
      setValidC_codeErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    } else {
      setdisabledsave(true);
      let article = {
        id: pid,
        fname: firstName,
        mname: middleName,
        lname: lastName,
        DOB: dob,
        sex: gender,
        ss: ssnNum,
        email: email,
        phone_cell: c_code + "-" + phone,
        blood_group: bloodGr,
        race: race,
        ethnicity: ethnicity,
        language: language,
        street: address,
        city: city,
        state: state,
        postal_code: zip,
        enrollment_status: PatEnrollmentDetails?.enrollment_status,
        portal: "yes",
      };

      if (imageFile) {
        dataArray.append("imgfile", imageFile);
      }

      let data = {
        article,
      };
      if (imageFile && imageFile !== "" && imageFile !== null) {
        data.file = dataArray;
      }

      Promise.resolve()
        .then(() => {
          dispatch(GetPatEnrollment([data, imageFile, pid]));
        })
        .then(() => {
          setImageFile(null);
        });
    }
  };

  const handleGender = () => {
    setGenderDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setLanguageDrop(false);
    setBloodDrop(false);
    setRaceDrop(false);
    setTemp(true);
  };

  const genderValChange = (list) => {
    setGender(list.title);
    setGenderErr(false);
    setGenderDrop(false);
  };

  const handleBloodGroup = () => {
    setBloodDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setLanguageDrop(false);
    setRaceDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const bloodGrValChange = (list, group) => {
    setBloodGr(list.title);
    setBloodGr(group);
    setBloodDrop(false);
  };

  const handleRace = () => {
    setRaceDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setLanguageDrop(false);
    setBloodDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const raceValChange = (list, value) => {
    setRace(list.title);
    setRaceErr(false);
    setRaceDrop(false);
  };

  const handleEthnicity = () => {
    setEthnicityDrop((prevState) => !prevState);
    setRaceDrop(false);
    setLanguageDrop(false);
    setBloodDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const ethnicityValChange = (list) => {
    setEthnicity(list.title);
    setEthnicityErr(false);
    setEthnicityDrop(false);
  };

  const handleLanguage = () => {
    setLanguageDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setRaceDrop(false);
    setBloodDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const languageValChange = (list) => {
    setLanguage(list.title);
    setLanguageDrop(false);
  };

  window.onclick = function (e) {
    if (genderDrop && !temp) {
      setGenderDrop(false);
    } else if (languageDrop && !temp) {
      setLanguageDrop(false);
    } else if (ethnicityDrop && !temp) {
      setEthnicityDrop(false);
    } else if (bloodDrop && !temp) {
      setBloodDrop(false);
    } else if (raceDrop && !temp) {
      setRaceDrop(false);
    }
  };
  const HandleClose = () => {
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setLanguage("");
    setBloodGr("");
    setGender("");
    setDOB("");
    setSsnNum("");
    setState("");
    setAddress("");
    setEthnicity("");
    setRace("");
    setCity("");
    setEmail("");
    setPhone("");
    setValidDate1("");
    setFirstNameErr(false);
    setLastNameErr(false);
    setDateerr1(false);
    setGenderErr(false);
    setSsnerr(false);
    setEmailErr(false);
    setMobileErr(false);
    setEthnicityErr(false);
    setRaceErr(false);
    setAddressErr(false);
    setCityErr(false);
    setStateErr(false);
    setZipCodeErr(false);
    setSsnerr1(false);
    setValidDate1(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    navigate("/dashboard/" + pid);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  // ------------------------------------------ Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    dispatch(GetSinglePatDetail(pid));
    dispatch(GetPatGenderDetails("sex"));
    dispatch(GetPatLangDetails("language"));
    dispatch(GetPatRaceDetails("ethrace"));
  }, []);

  useEffect(() => {
    let phonenumSplit = PatEnrollmentDetails.phone_cell
      ? PatEnrollmentDetails.phone_cell.split("-")
      : "";
    setCCode(phonenumSplit["1"] === undefined ? "+91" : phonenumSplit["0"]);
    if (PatEnrollmentDetails !== undefined || null) {
      setFirstName(PatEnrollmentDetails.fname);
      setMiddleName(PatEnrollmentDetails.mname);
      setLastName(PatEnrollmentDetails.lname);
      setDOB(PatEnrollmentDetails.DOB);
      setValidDate1(PatEnrollmentDetails.DOB);
      setGender(PatEnrollmentDetails.sex);
      setSsnNum(PatEnrollmentDetails.ss);
      setEmail(PatEnrollmentDetails.email);
      setPhone(phonenumSplit["1"] || "");
      setBloodGr(PatEnrollmentDetails.blood_group);
      setRace(PatEnrollmentDetails.race);
      setEthnicity(PatEnrollmentDetails.ethnicity);
      setLanguage(PatEnrollmentDetails.language);
      setAddress(PatEnrollmentDetails.street);
      setCity(PatEnrollmentDetails.city);
      setState(PatEnrollmentDetails.state);
      setZip(PatEnrollmentDetails.postal_code);
      setProfilepic(PatEnrollmentDetails.img_url);
    } else {
      setFirstName("");
      setLastName("");
      setMiddleName("");
      setLanguage("");
      setBloodGr("");
      setGender("");
      setDOB("");
      setSsnNum("");
      setState("");
      setAddress("");
      setEthnicity("");
      setRace("");
      setCity("");
      setEmail("");
      setPhone("");
      setValidDate1("");
      setFirstNameErr(false);
      setLastNameErr(false);
      setDateerr1(false);
      setGenderErr(false);
      setSsnerr(false);
      setEmailErr(false);
      setMobileErr(false);
      setEthnicityErr(false);
      setRaceErr(false);
      setAddressErr(false);
      setCityErr(false);
      setStateErr(false);
      setZipCodeErr(false);
      setSsnerr1(false);
      setValidDate1(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    }
  }, [PatEnrollmentDetails]);

  useEffect(() => {
    if (updateResponse !== "") {
      setdisabledsave(false);
      if (updateResponse === "1") {
        setModalMsg("Patient information has been updated!");
        setModalAlerShow(true);
        setStatus("success");
        setTimeout(() => {
          setModalAlerShow(false);
          setStatus("");
          setModalMsg("");
        }, 2000);
      } else {
        setModalMsg("Patient information has not been updated!");
        setModalAlerShow(true);
        setStatus("fail");
        setTimeout(() => {
          setModalAlerShow(false);
          setStatus("");
          setModalMsg("");
        }, 3000);
      }
      dispatch(setEmptyPatUpdateResponse());
    }
  }, [updateResponse]);

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    if (initialMail) {
      if (EmailVerify === "Email not found" || pid === EmailVerify.pid) {
        setValidMailErr(false);
      } else {
        setValidMailErr(true);
      }
    }
    if (email === "") {
      setValidMailErr(false);
    }
  }, [EmailVerify]);

  useEffect(() => {
    if (initialPhone) {
      if (PhoneVerify === "Phone Number not found" || pid === PhoneVerify.pid) {
        setValidPhoneErr(false);
      } else {
        setValidPhoneErr(true);
        setMobileErr(false);
      }
    }
    if (phone === "") {
      setValidPhoneErr(false);
    }
  }, [PhoneVerify]);

  useEffect(() => {
    if (initialSSN) {
      if (SSNVerify === "SSN not found" || pid === SSNVerify.pid) {
        setValidSSNErr(false);
      } else {
        setValidSSNErr(true);
      }
    }
  }, [SSNVerify]);

  useEffect(() => {
    let isCancelled = false;

    if (phone !== "") {
      const handlechange = async () => {
        await timeout(700);
        if (!isCancelled) {
          dispatch(GetPhoneVerify({ phone: phone, val: "phone", pid: pid }));
        }
      };

      handlechange();
      return () => {
        isCancelled = true;
      };
    }
  }, [phone]);

  // ----------------------------------------- UseEffect End ------------------------------------------------------- //
  return Loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div backgroundColor="#ffffff" marginTop="24px" height="665px">
        <Div display="flex" marginLeft="40px">
          <Div
            height="70px"
            width="70px"
            borderRadius="50%"
            display="flex"
            marginRight="17px"
            fontSize="60px"
            fontWeight="600"
          >
            {(profilepic !== undefined &&
              profilepic !== null &&
              profilepic !== "") ||
            previewimg1 !== "" ? (
              <Image
                height="70px"
                width="70px"
                borderRadius="50%"
                src={previewimg1 === "" ? imageUrl + profilepic : previewimg1}
              />
            ) : (
              <Div
                height="70px"
                width="70px"
                borderRadius="50%"
                background="rgb(168, 174, 190)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                fontFamily="Open Sans, sans-serif"
                color="#FFFFFF"
                position="relative"
              >
                <Span top="18px" fontSize="40px">
                  {firstName !== undefined && firstName.charAt(0).toUpperCase()}
                </Span>
              </Div>
            )}

            <Image
              marginTop="45px"
              position="absolute"
              height="17px"
              width="17px"
              cursor="pointer"
              marginLeft="52px"
              style={{ zIndex: 1 }}
              onClick={() => {
                setIsUpload(true);
              }}
              src={Myprofileediticon}
            ></Image>
          </Div>

          <Div display="grid">
            <FormLabel
              fontSize="24px"
              color="#2E2E2E"
              lineHeight="33px"
              fontWeight="600"
              marginTop="7px"
            >
              {firstName} {middleName && middleName} {lastName}
            </FormLabel>
            <FormLabel
              fontSize="14px"
              color="#2E2E2E"
              fontWeight="400"
              marginTop="3px"
            >
              Set up your Patient Portal
            </FormLabel>
          </Div>
        </Div>

        <Div display="flex" padding="24px 0 0 40px">
          <Div display="inline-grid" padding="0px 48px 0 0">
            <FormLabel
              margin="0px 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              First Name<RedStar>*</RedStar>
            </FormLabel>
            <Div>
              <Input
                padding="0 0 0 10px"
                width="222px"
                height="34px"
                fontWeight="400"
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="4px"
                type="text"
                name="firstName"
                value={firstName}
                style={
                  FirstNameErr
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={(e) => setFirstNameChange(e)}
              />
            </Div>
          </Div>

          <Div display="inline-grid" padding="0px 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Middle Name
            </FormLabel>
            <Div>
              <Input
                padding="0 0 0 10px"
                width="222px"
                height="34px"
                fontWeight="400"
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="4px"
                type="text"
                name="middleName"
                value={middleName}
                onKeyPress={handleKeyPress}
                onChange={(e) => setMiddleNameChange(e)}
              />
            </Div>
          </Div>
          <Div display="inline-grid" padding="0px 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Last Name<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              fontWeight="400"
              width="222px"
              height="34px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              type="text"
              name="lastName"
              value={lastName}
              style={
                LastNameErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : { marginTop: FirstNameErr ? "0px" : "0px" }
              }
              onChange={(e) => setLastNameChange(e)}
            />
          </Div>
        </Div>
        <Div display="flex" padding="5px 0px 0px 40px">
          <Div width="220px" marginRight="48px">
            {FirstNameErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter First Name
              </ErrorMessage>
            )}
          </Div>

          <Div width="220px" marginRight="50px"></Div>

          <Div width="220px">
            {LastNameErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter Last Name
              </ErrorMessage>
            )}
          </Div>
        </Div>

        <Div display="flex" padding="22px 0 0 40px">
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Date of Birth<RedStar>*</RedStar>
            </FormLabel>
            <Div position="relative">
              <CustomDatePicker
                padding="0 0 0 10px"
                id="CustomDatePicker"
                fontWeight="400"
                width="222px"
                height="34px"
                backgroundColour="#f7f7f7 !important"
                border={
                  dateerr1 ? "1px solid red" : "1px solid rgba(34,36,38,.15)"
                }
                borderRadius="4px"
                placeholder="YYYY-MM-DD"
                value={dob}
                onChange={(e) => inputChange(e)}
                onClick={(e) => handlepicker1(e)}
                onKeyPress={(event) => {
                  if (
                    !/[0-9]/.test(event.key) ||
                    event.target.value.length > 9
                  ) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(e) => keyDownExpiry(e)}
                onResultSelect={dateSelect1}
                showPicker={showpicker1}
                validDate={validDate1}
                closePicker={closePicker1}
                max={moment(new Date(), "DD/MM/YYYY")}
              />
              <Imagecalendar
                top="6px"
                right="20px"
                width="20px"
                height="19px"
                id="CustomDatePicker"
                onClick={(e) => handlepicker1(e)}
                src={calendar_icon_custom}
              ></Imagecalendar>
            </Div>
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Gender<RedStar>*</RedStar>
            </FormLabel>
            <Div>
              <DropDown
                fontWeight="500"
                marginBottom="0px"
                fontSize="20px"
                lineHeight="28px"
              >
                <Div
                  width="222px"
                  height="34px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  fontSize="14px"
                  paddingLeft="10px"
                  paddingTop="2px"
                  fontWeight="400"
                  onClick={handleGender}
                  onMouseLeave={() => setTemp(false)}
                  style={
                    gendererr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                >
                  {gender}
                </Div>
                <DropdownImage
                  height="8px"
                  width="12px"
                  position="absolute"
                  marginTop="-18px"
                  marginLeft="192px"
                  src={DropdownDownicon}
                ></DropdownImage>
                {genderDrop && (
                  <DropDownDiv
                    margin="0 0 0 0"
                    width="220px"
                    background="#FFFFFF"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    maxHeight="146px"
                    overflowY="auto"
                    overflowX="hidden"
                  >
                    {GenderDetails.map((list, ind) => {
                      return (
                        <DropdownOptions
                          key={ind}
                          value={list.title}
                          padding="9px 12px 9px 12px"
                          onClick={() => genderValChange(list)}
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {list.title}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                )}
              </DropDown>
            </Div>
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              SSN Number<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              width="222px"
              height="34px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              name="ssnNum"
              fontWeight="400"
              min="0"
              value={ssnNum}
              onKeyPress={inputNineDig}
              style={
                ssnErr || ssnErr1 || validSSNErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : {}
              }
              onChange={(e) => setSsnNumValChange(e)}
            />
          </Div>
        </Div>

        <Div display="flex" padding="5px 0px 0px 40px">
          <Div width="220px" marginRight="48px">
            {dateerr1 && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please enter a valid Date
              </ErrorMessage>
            )}
          </Div>
          <Div width="220px" marginRight="52px">
            {" "}
            {gendererr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Select Valid Gender
              </ErrorMessage>
            )}
          </Div>

          <Div width="220px">
            {ssnErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                SSN Must be 9 digit
              </ErrorMessage>
            )}
            {ssnErr1 && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter SSN Number
              </ErrorMessage>
            )}
            {validSSNErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                SSN Already Exist
              </ErrorMessage>
            )}
          </Div>
        </Div>

        <Div display="flex" padding="22px 0 0 40px">
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Email ID<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              width="222px"
              height="34px"
              borderRadius="4px"
              type="text"
              border="1px solid rgba(46, 46, 46, 0.25)"
              name="email"
              fontWeight="400"
              value={email}
              style={
                EmailErr || validMailErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : {}
              }
              onChange={(e) => setEmailChange(e)}
            />
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Phone Number<RedStar>*</RedStar>
            </FormLabel>
            <Div flexCenterAll>
              <Input
                id="ccode_inp"
                padding="0 0 0 10px"
                width={`${36 + (c_code?.length - 1) * 6}px`}
                height="34px"
                border="1px solid rgba(46, 46, 46, 0.25)"
                btrr="0px"
                bbrr="0px"
                bblr="4px"
                btlr="4px"
                brw="0px"
                borderRadius="4px"
                type="text"
                fontWeight="400"
                min="0"
                name="ccode"
                value={`(${c_code})`}
                style={
                  MobileErr || validPhoneErr || validC_codeErr
                    ? { borderColor: "red" }
                    : {}
                }
                onChange={(e) => setCCodeChange(e)}
                maxLength={7}
              />
              <Input
                id="phone_inp"
                padding="0 0 0 10px"
                width={`${186 - (c_code?.length - 1) * 6}px`}
                height="34px"
                border="1px solid rgba(46, 46, 46, 0.25)"
                btrr="4px"
                bbrr="4px"
                bblr="0px"
                btlr="0px"
                blw="0px"
                borderRadius="4px"
                type="text"
                fontWeight="400"
                min="0"
                name="phone"
                value={phone}
                style={
                  MobileErr || validPhoneErr || validC_codeErr
                    ? { borderColor: "red" }
                    : {}
                }
                onChange={(e) => setMobileChange(e)}
              />
            </Div>
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Blood Group
            </FormLabel>
            <Div>
              <DropDown
                fontWeight="500"
                marginBottom="0px"
                fontSize="20px"
                lineHeight="28px"
              >
                <Div
                  width="222px"
                  height="34px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  fontSize="14px"
                  fontWeight="400"
                  paddingLeft="10px"
                  onClick={handleBloodGroup}
                  onMouseLeave={() => setTemp(false)}
                >
                  {bloodGr}
                </Div>
                <DropdownImage
                  height="8px"
                  width="12px"
                  position="absolute"
                  marginTop="-18px"
                  marginLeft="192px"
                  src={DropdownDownicon}
                ></DropdownImage>
                {bloodDrop && (
                  <DropDownDiv
                    width="222px"
                    margin="0 0 0 0"
                    background="#FFFFFF"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    height="300px"
                    overflowY="auto"
                    overflowX="hidden"
                  >
                    {BloodGrList.map((list, ind) => {
                      return (
                        <DropdownOptions
                          key={ind}
                          onClick={() => bloodGrValChange(list.title, list.val)}
                          value={list.title}
                          padding="9px 12px 9px 12px"
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {list.val}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                )}
              </DropDown>
            </Div>
          </Div>
        </Div>
        <Div display="flex" padding="5px 0px 0px 40px">
          <Div width="220px" marginRight="48px">
            {EmailErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter Valid Email
              </ErrorMessage>
            )}
            {validMailErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Email Id Already Exist
              </ErrorMessage>
            )}
          </Div>

          <Div width="220px" marginRight="48px">
            {MobileErr ? (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter Valid Mobile
              </ErrorMessage>
            ) : validC_codeErr ? (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter Valid Country Code
              </ErrorMessage>
            ) : (
              ""
            )}

            {validPhoneErr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Phone Number Already Exist
              </ErrorMessage>
            )}
          </Div>

          <Div width="222px"></Div>
        </Div>

        <Div display="flex" padding="22px 0 0 40px">
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Race<RedStar>*</RedStar>
            </FormLabel>
            <Div>
              <DropDown
                fontWeight="500"
                marginBottom="0px"
                fontSize="20px"
                lineHeight="28px"
              >
                <Div
                  width="222px"
                  height="34px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  fontSize="14px"
                  fontWeight="400"
                  paddingLeft="10px"
                  onClick={handleRace}
                  onMouseLeave={() => setTemp(false)}
                  style={
                    raceerr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                >
                  <div
                    style={{
                      maxWidth: "187px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                    }}
                  >
                    {race}
                  </div>
                </Div>
                <DropdownImage
                  height="8px"
                  width="12px"
                  position="absolute"
                  marginTop="-18px"
                  marginLeft="192px"
                  src={DropdownDownicon}
                ></DropdownImage>
                {raceDrop && (
                  <DropDownDiv
                    width="222px"
                    margin="0 0 0 0"
                    background="#FFFFFF"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    height="300px"
                    overflowY="auto"
                    overflowX="hidden"
                  >
                    {RaceStatusList.map((list, ind) => {
                      return (
                        <DropdownOptions
                          key={ind}
                          onClick={(e) => raceValChange(list)}
                          value={list.title}
                          padding="9px 12px 9px 12px"
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {list.title}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                )}
              </DropDown>
            </Div>
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Ethnicity<RedStar>*</RedStar>
            </FormLabel>
            <Div>
              <DropDown
                fontWeight="500"
                marginBottom="0px"
                fontSize="20px"
                lineHeight="28px"
              >
                <Div
                  width="222px"
                  height="34px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  fontSize="14px"
                  fontWeight="400"
                  paddingLeft="10px"
                  onClick={handleEthnicity}
                  onMouseLeave={() => setTemp(false)}
                  style={
                    ethnicityerr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                >
                  {ethnicity}
                </Div>
                <DropdownImage
                  height="8px"
                  width="12px"
                  position="absolute"
                  marginTop="-18px"
                  marginLeft="192px"
                  src={DropdownDownicon}
                ></DropdownImage>
                {ethnicityDrop && (
                  <DropDownDiv
                    width="222px"
                    margin="0 0 0 0"
                    background="#FFFFFF"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    height="110px"
                    overflowY="auto"
                    overflowX="hidden"
                  >
                    {EthnicityList.map((list, ind) => {
                      return (
                        <DropdownOptions
                          key={ind}
                          onClick={() => ethnicityValChange(list)}
                          value={list.title}
                          padding="7px 12px 7px 12px"
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {list.title}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                )}
              </DropDown>
            </Div>
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Language
            </FormLabel>
            <Div>
              <DropDown
                fontWeight="500"
                marginBottom="0px"
                fontSize="20px"
                lineHeight="28px"
              >
                <Div
                  width="222px"
                  height="34px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  fontSize="14px"
                  fontWeight="400"
                  paddingLeft="10px"
                  onClick={handleLanguage}
                  onMouseLeave={() => setTemp(false)}
                >
                  <div
                    style={{
                      maxWidth: "182px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                    }}
                  >
                    {language ? language : "Select One"}
                  </div>
                </Div>
                <DropdownImage
                  height="8px"
                  width="12px"
                  position="absolute"
                  marginTop="-18px"
                  marginLeft="192px"
                  src={DropdownDownicon}
                ></DropdownImage>
                {languageDrop && (
                  <DropDownDiv
                    width="220px"
                    margin="0 0 0 0"
                    background="#FFFFFF"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    height="300px"
                    overflowY="auto"
                    overflowX="hidden"
                  >
                    {LanguageStatusList.map((list, ind) => {
                      return (
                        <DropdownOptions
                          key={ind}
                          onClick={() => languageValChange(list)}
                          value={list.title}
                          padding="9px 12px 9px 12px"
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {list.title}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                )}
              </DropDown>
            </Div>
          </Div>
        </Div>

        <Div display="flex" padding="5px 0px 0px 40px">
          <Div width="222px" marginRight="48px">
            {raceerr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Select Valid Race
              </ErrorMessage>
            )}
          </Div>

          <Div width="222px" marginRight="48px">
            {" "}
            {ethnicityerr && (
              <ErrorMessage
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Select Valid Ethnicity
              </ErrorMessage>
            )}
          </Div>

          <Div width="222px"></Div>
        </Div>

        <Div display="flex" padding="22px 0 0 40px">
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Address<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              height="34px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              fontWeight="400"
              type="text"
              width="400px !important"
              name="address"
              value={address}
              style={
                AddressErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : {}
              }
              onChange={(e) => setAddressChange(e)}
            />
            {AddressErr && (
              <ErrorMessage
                margin="5px 0px 5px 0px"
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter Valid Address
              </ErrorMessage>
            )}
          </Div>
        </Div>
        <Div display="flex" padding="21px 0 0 40px">
          <Div display="inline-grid" padding="0 24px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="402"
              fontSize="14px"
              color="#2E2E2E"
            >
              City<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              width="122px"
              height="34px"
              fontWeight="400"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              type="text"
              name="city"
              value={city}
              style={
                CityErr ? { border: "1px solid red", borderRadius: "5px" } : {}
              }
              onChange={(e) => setCityChange(e)}
            />
          </Div>
          <Div display="inline-grid" padding="0 24px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              State<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              width="120px"
              height="32px"
              fontWeight="400"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              type="text"
              name="state"
              value={state}
              style={
                StateErr ? { border: "1px solid red", borderRadius: "5px" } : {}
              }
              onChange={(e) => setStateChange(e)}
            />
          </Div>
          <Div display="inline-grid" padding="0 48px 0 0">
            <FormLabel
              margin="0 0 8px 0"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Zipcode<RedStar>*</RedStar>
            </FormLabel>
            <Input
              padding="0 0 0 10px"
              width="120px"
              height="32px"
              fontWeight="400"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              type="text"
              name="zip"
              value={zip}
              min="0"
              style={
                ZipcodeErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : {}
              }
              onChange={(e) => setZipChange(e)}
            />
          </Div>
        </Div>

        <Div display="flex" padding="5px 0px 0px 40px">
          <Div width="120px" marginRight="24px">
            {CityErr && (
              <ErrorMessage
                margin="0px 0px 5px 0px"
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter City
              </ErrorMessage>
            )}
          </Div>

          <Div width="120px" marginRight="24px">
            {StateErr && (
              <ErrorMessage
                margin="0px 0px 5px 0px"
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter State
              </ErrorMessage>
            )}
          </Div>

          <Div width="127px">
            {ZipcodeErr && (
              <ErrorMessage
                margin="0px 0px 5px 0px"
                width="100%"
                fontSize="80%"
                color="red"
                textAlign="left"
              >
                Please Enter ZipCode
              </ErrorMessage>
            )}
          </Div>
        </Div>

        <Hrline ml="-40px" mb="23px" mt="24px" />

        <Div display="flex" marginLeft="45px">
          <Button
            width="79px"
            height="32px"
            background="#ffffff"
            borderRadius="4px"
            fontFamily="Open Sans, sans-serif"
            fontWeight="400"
            cursor="pointer"
            fontSize="14px"
            color="#2C7BE5"
            marginRight="24px"
            border="1px solid #2C7BE5"
            onClick={HandleClose}
            className="close_active"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
          >
            Cancel
          </Button>
          <Button
            width="97px"
            height="32px"
            cursor="pointer"
            background="#2C7BE5"
            borderRadius="4px"
            fontFamily="Open Sans, sans-serif"
            fontWeight="400"
            fontSize="14px"
            color="#ffffff"
            border="none"
            id="edPr_btn-sv"
            onClick={SaveProfile}
            hoverBackColor="#005FB2"
            className="save_active"
            disabled={disabledsave}
          >
            Save
          </Button>
        </Div>
      </Div>

      <UploadProfilePicture
        show={isupload}
        close={() => setIsUpload(false)}
        setImageText={setImageText}
        setpreviewimg1={setpreviewimg1}
        setImageFile={setImageFile}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(Myprofile);
