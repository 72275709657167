import React from "react";
import styled from "styled-components";

import { AiOutlineSearch } from "react-icons/ai";

import { Div, Image, Text } from "../StyledComponents";
import { SearchInputText, ThumbImage, ThumbDoc, ThumbAudio } from "./Styles";

const SearchProvider = ({ searchVal }) => {
  const Onsearch = (e) => {
    searchVal(e.target.value);
  };
  return (
    <Div
      width="94%"
      display="flex"
      padding="0px 0px 0px 10px"
      height="36px"
      borderRadius="8px"
      border="1px solid #e4e4e4"
    >
      <Div padding="3px 0px 0px 0px">
        <AiOutlineSearch
          font-size="22px"
          fill="#666666"
          font-weight="100"
          viewBox="0 -200 1024 1024"
        />
      </Div>
      <SearchInputText
        id="msg_inp-srch"
        onChange={Onsearch}
        placeholder="Search Provider"
      />
    </Div>
  );
};

const TnumbComponent = ({ message, variant }) => {
  return (
    <Div display="flex">
      {variant === "image" && (
        <>
          <ThumbImage />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
      {variant === "doc" && (
        <>
          <ThumbDoc />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
      {variant === "audio" && (
        <>
          <ThumbAudio />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="4px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
    </Div>
  );
};
export { SearchProvider, TnumbComponent };

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  border: ${(p) => p.border && p.border};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const AvatarLetter = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const AvatarImage = styled(Image)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  min-width: ${(p) => p.minWidth && p.minWidth};
  border: ${(p) => p.border && p.border};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  margin-top: 3px;
`;
export const Images = styled.img`
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  cursor: ${({ cursor }) => cursor && cursor};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;
