import { call, put } from "redux-saga/effects";
import { Decrypt_Value } from "../EncryptDecrypt";
import {
  SetSinglePatDetail,
  GetVitals,
  setDeviceList,
  setHealthScore,
  setCareTeamDetails,
} from "../../StateManagement/Reducers/DashboardState";
import {
  GetSinglePatientDetail_Api,
  GetVitals_Api,
  DeviceListApi,
  CardTeamdetails_Api,
} from "../Apis/DashBoardApi";
import { HealthScoreCalculator } from "../../Modules/DashBoard/HealthScoreCalculator";
export function* VitalsApi({}) {
  try {
    const res = yield call(GetVitals_Api);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(GetVitals(Data));
      const calculatedHealthScore = HealthScoreCalculator(Data);
      yield put(
        setHealthScore({
          Score: calculatedHealthScore.score,
          vitalCount: calculatedHealthScore.vitalCount,
        })
      );
    }
  } catch (e) {
    yield put(GetVitals("CAN'T FETCH VITALS"));
  }
}
export function* getDevices({}) {
  try {
    const res = yield call(DeviceListApi);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setDeviceList(Data));
    }
  } catch (e) {
    yield put(setDeviceList([]));
    console.log(e.message);
  }
}
export function* getSinglePatientList({}) {
  try {
    const res = yield call(GetSinglePatientDetail_Api);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSinglePatDetail(Data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetSinglePatDetail([]));
  }
}

export function* GetCardTeamDetailsworker({}) {
  try {
    const res = yield call(CardTeamdetails_Api);

    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setCareTeamDetails(Data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setCareTeamDetails([]));
  }
}
