import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

export const ProviderListApi = async () =>
  await axiosInst.get(`/rpmportal/Providerlist?pid=${Pat_ID}`);

export const MessageListApi = async (credential) =>
  await axiosInst.post(`/rpmportal/patient/getMessagelist`, credential);

export const MessageSendApi = async (credential) =>
  await axiosInst.post(`/rpmportal/patient/sendMessage`, credential);

export const PreviewDownloadApi_1 = async (credential) =>
  await axiosInst.put(`/vozorpm/PreviewDownload`, credential);

export const UnreadCountUpdateApi = async (credential) =>
  await axiosInst.put(`/rpmportal/patient/unreadCountUpdate`, credential);

export const StatusApi = async (data) =>
  await axiosInst.get(`/rpmportal/status?uid=${data.uid}&mode=${data.mode}`);
