import React from "react";
import { useDispatch } from "react-redux";

import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";

import { Image, Div } from "../../StyledComponents";
import { Title, SubTitle, ClientButton, Status } from "./Styles";

import Login from "../../../Assets/images/login.png";
import Sign from "../../../Assets/images/SignUp.png";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { useEffect } from "react";

const WelcomeRight = () => {
  const dispatch = useDispatch();

  const handleMode = (a) => {
    if (a === "1") {
      dispatch(setMode("new"));
      let mode = "new";
      localStorage.setItem("Mode", Encrypt_Value(mode, "rpmportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    } else {
      dispatch(setMode("old"));
      let mode = "old";
      localStorage.setItem("Mode", Encrypt_Value(mode, "rpmportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };
  useEffect(() => {
    localStorage.removeItem("Mode");
  }, []);

  return (
    <Div width="100%">
      <Div width="50%" marginTop="130px" margin="auto" textAlign="center">
        <Div display="inline" alignItems="center">
          <Title color="#1F28EB">Welcome back!&nbsp;</Title>
          <Title color="#000000">Nice to see you again</Title>
        </Div>
        <SubTitle>
          Use the email your practitioner has on file to sign in—no password
          necessary.
        </SubTitle>
        <Div display=" flex" justifyContent="center" marginTop=" 44px">
          <ClientButton
            marginright="40px"
            to="/InstantSignIn"
            exact="true"
            onClick={() => handleMode("1")}
          >
            <Image
              loading="lazy"
              src={Sign}
              alt="Sign-Up Image"
              width="186px"
              height="138px"
            ></Image>
            <Status>I’M A NEW CLIENT</Status>
          </ClientButton>
          <ClientButton
            to="/InstantSignIn"
            exact="true"
            onClick={() => handleMode("2")}
          >
            <Image
              loading="lazy"
              src={Login}
              alt="Login-Image"
              width="186px"
              height="138px"
              id="wel_img-ext"
            />
            <Status>EXISTING CLIENT</Status>
          </ClientButton>
        </Div>
      </Div>
    </Div>
  );
};

export default WelcomeRight;
