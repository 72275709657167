import { takeLatest } from "redux-saga/effects";
import {
  GetSingleInsDetail,
  postSingleInsDetail,
  userImageUpload,
} from "../../StateManagement/Reducers/InsuranceState";
import {
  GetInsurancedata,
  UserImageUploadApi,
  postInsurancedata,
} from "./InsuranceSagaWorkers";

export function* SingleInsuranceDetail() {
  yield takeLatest(postSingleInsDetail.type, postInsurancedata);
}
export function* GetInsuranceDetail() {
  yield takeLatest(GetSingleInsDetail.type, GetInsurancedata);
}

export function* userImageUploadSaga() {
  yield takeLatest(userImageUpload.type, UserImageUploadApi);
}
